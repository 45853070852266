//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name:'BlogPostSocialShare',
  props: {
    shareUrl: {
      type: String,
      default: 'https://franchise123.com/blog/',
    }
  }
}
