import { render, staticRenderFns } from "./LandingSectionVideo.vue?vue&type=template&id=a3fa991e&scoped=true&"
import script from "./LandingSectionVideo.vue?vue&type=script&lang=js&"
export * from "./LandingSectionVideo.vue?vue&type=script&lang=js&"
import style0 from "./LandingSectionVideo.vue?vue&type=style&index=0&id=a3fa991e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3fa991e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingText: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingText.vue').default,LandingButton: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingButton.vue').default,LandingVideo: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingVideo.vue').default})
