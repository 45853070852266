//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThreeDotButton from "@/components/buttons/ThreeDotButton";
import vClickOutside from 'v-click-outside';
import { STATUS_DONE } from '@/store/constants';

export default {
  components: {ThreeDotButton},
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    chosenContact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showMenuList: false,
      STATUS_DONE
    };
  },
  computed: {
    /**
     * Check business client is approved
     * @returns {boolean}
     */
    isApproved () {
      return this.chosenContact.status === STATUS_DONE
    },
    /**
     * Approve hover title
     * @returns {String}
     */
    approveTitle () {
      return this.isApproved ? 'approved' : 'approve';
    }
  },
  methods: {
    /**
     * handle click outside
     */
    onClickOutside() {
      this.showMenuList = false;
    },
  },
};
