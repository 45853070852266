//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BeginTodayButton',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click (e) {
      this.$emit('click', e)
    }
  }
};
