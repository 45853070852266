//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlankBlock from "@/components/landing/blank/BlankBlock";
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import EditPencil from "@/assets/icons/landing/editor/EditPencil.svg?inline";
import landingActions from "@/components/mixins/landingActions";

export default {
  name: "LandingImage",
  components: {
    BlankBlock,
    Cropper,
    EditPencil,
  },
  mixins: [
    landingActions
  ],
  model: {
    prop: 'html',
    event: 'input',
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    html: {
      type: Object,
      default: () => {
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 1.49
    }
  },
  data: () => {
    return {
      content: {
        url: ''
      },
      preview: {
        type: '',
        canvas: '',
        width: 0,
        height: 0,
      },
      fileUrl: '',
    }
  },
  computed: {
    imgUrl() {
      return `${process.env.BACKEND_BASE_URL}api/v2/landing/${this.content.url}/image`;
    }
  },
  created() {
    this.content = this.html;
  },
  methods: {
    cancelCropper() {

      this.preview = {
        canvas: '',
        type: '',
      }
    },
    async saveCropper() {
      const resultSave = await this.saveFile(this.preview.canvas, this.preview.type)

      if (!resultSave)
        return
      this.content.url = resultSave
      this.cancelCropper()
      this.$emit('input',this.content)
    },
    changeCrop({image, canvas}) {
      this.preview.canvas = canvas.toDataURL(this.preview.type)
      this.preview.height = image.height
      this.preview.width = image.width
    },
    loadFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        const file = files[0]
        this.preview.type = file.type
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fileUrl = event.target.result;
        }
        reader.readAsDataURL(file)
      }
    },
  },
}
