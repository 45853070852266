//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import DownloadButton from "@/components/buttons/DownloadButton";

export default {
  components: {
    DownloadButton
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    businessStepId: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.getDocuments()
  },
  methods: {
    /**
     *
     * @param document
     * @returns {Promise<void>}
     */
    async download(document) {
      const {data} = await this.$axios.get(
        `/api/v1/business-step-docs/download/${document.step_doc_id}`,
        { responseType: 'arraybuffer' }
      );
      const file = new Blob([data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL)

    },
    async getDocuments() {
      try {
        const url = `/api/v1/business-step-docs/${this.businessStepId}`;
        const { data } = await this.$axios.get(url);
        this.documents = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
