//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AutoComplete',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    endPointUrl: {
      type: String,
      default: () => ''
    },
    bordered: {
      type: Boolean,
      default: () => false
    },
    searchFranchises: {
      type: Boolean,
      default: false,
    },
    searchFranchisesByName: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isActive: false,
      result: [
        {
          id: 1,
          title: 'Result 1'
        },
        {
          id: 2,
          title: 'Result 2'
        }
      ]
    }
  },
  created () {
    if(process.client) {
      document.addEventListener('click', this.documentClick)
    }
  },
  destroyed () {
    if(process.client) {
      document.removeEventListener('click', this.documentClick)
    }
  },
  methods: {
    documentClick (e) {
      const el = this.$refs.autocomplete
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.isActive = false
      }
    },
    handleInputFocus () {
      this.isActive = true
    },
    handleClickResult (idx) {
      this.isActive = true
    },
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
