//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex'
import Counter from "@/components/badges/Counter"
import RingIcon from "@/components/icons/RingIcon"
import vClickOutside from 'v-click-outside'
import storeMixin from "@/components/mixins/storeMixin"

export default {
  components: {
    Counter,
    RingIcon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [
    storeMixin,
  ],
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/notifications',
      notificationsUnreadCount: 'notifications/notificationsUnreadCount',
      notificationsLoading: 'notifications/notificationsLoading',
      notificationsPagination: 'notifications/notificationsPagination',
      user: 'auth/user',
      authenticated: 'auth/check',
      paymentPermissions: 'payment/permissions',
    }),
  },
  async created() {
    if (this.authenticated && this.user.isFranchisor && !this.paymentUser) {
      await this.$store.dispatch('payment/fetchPayment', this.user.id)
    }
  },
  async updated() {
    await this.fetchBusinessSteps()
  },
  methods: {
    ...mapActions({
      fetchBusinessSteps: 'business-steps/fetchBusinessSteps',
    }),
    getNotifyTitleClass(notification) {
      const status = notification?.data?.status

      if (status === 'imported')
        return 'ring-notify-success'

      if (status === 'imported_partially')
        return 'ring-notify-warning'

      if (status === 'failed')
        return 'ring-notify-error'

      return 'ring-notify-default'
    },
    /**
     * Lazy loading items
     * @param {Event} e scroll event
     */
    async handleScrollItems(e) {
      // eslint-disable-next-line camelcase
      const {current_page, last_page} = this.notificationsPagination

      // if page end
      // eslint-disable-next-line camelcase
      if (current_page === last_page) return

      const target = e.target
      if (
        !this.notificationsLoading &&
        target.scrollTop + target.clientHeight >= target.scrollHeight
      ) {
        await this.$store.commit('notifications/SET_LOADING_NOTIFICATIONS', true)
        await this.$store.commit('notifications/SET_NOTIFICATIONS_PAGINATION', {
          // eslint-disable-next-line camelcase
          current_page: current_page + 1,
        })
        await this.$store.dispatch('notifications/fetchNotifications')
      }
    },
    /**
     * Outside this element click event
     */
    handleClickOutside() {
      this.active = false
    },
    async handleClickItem(notification) {
      if (this.user.isFranchisor && !this.paymentPermissions.chatDialog.access) {
        await this.fireModalMessage({message: this.paymentPermissions.chatDialog.message})
        return
      }

      if (!notification.read_at) {
        await this.$store.dispatch('notifications/readNotification', notification)
      }

      if (notification.data.url) {
        this.active = false
        const url = this.user.isMasterAdmin &&
        notification.type === 'App\\Events\\AdminActionBeginTodayNotificationEvent' &&
        typeof notification.data.adminUrl !== "undefined"
          ? notification.data.adminUrl
          : notification.data.url

        window.open(url, '_blank')
      }
    },
  },
}
