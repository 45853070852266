//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VCheckBoxV2 from "@/components/form/VCheckBoxV2";
import VButton from "@/components/VButton";
import Form from 'vform';
import {mapGetters} from 'vuex';
import GoogleSignUpButton from '../../buttons/GoogleSignUpButton';
import LinkedInSignUpButton from '../../buttons/LinkedInSignUpButton';
import VInputV2 from '~/components/form/VInputV2';
import {
  FRANCHISOR_FLOW_OPTIONS,
  REGISTRATION_STAGE_PREFERENCES,
  USER_TYPE_FRANCHISEE,
  USER_TYPE_FRANCHISOR,
} from '~/store/constants';

export default {
  components: {
    VCheckBoxV2,
    VButton,
    GoogleSignUpButton,
    LinkedInSignUpButton,
    VInputV2,
  },
  data() {
    return {
      form: new Form({
        email: '',
        password: '',
        remember: false,
        busy: false,
        onKeydown: () => {
          this.login();
        },
      }),
      status: '',
      message: '',
      stagePreferences: REGISTRATION_STAGE_PREFERENCES,
      userTypeFranchise: USER_TYPE_FRANCHISEE,
      userTypeFranchisor: USER_TYPE_FRANCHISOR,
    };
  },
  computed: mapGetters({
    user: 'auth/user',
    isPrivatePage: 'auth/isPrivatePage',
    authenticated: 'auth/check',
    redirectToComparisons: 'auth/redirectToComparisons',
    sessionExpired: 'login/sessionExpired',
  }),
  watch: {
    async user(value) {
      let to = '/';
      if (this.$route.query.redirect) {
        to = this.$route.query.redirect
      } else if (value?.isFranchisee) {
        to = this.redirectToComparisons ? '/account/comparisons' : '/account/my-matches'
      } else if (value?.isFranchisor && value.active_step!==null) {
        await this.$store.dispatch('registration/setOption', FRANCHISOR_FLOW_OPTIONS.claim)
        await this.$store.dispatch('registration/openModal')
        await this.$store.dispatch('registration/setUserType', USER_TYPE_FRANCHISOR)
        await this.$store.dispatch('registration/setStage', 'stepper')
        to ={path: this.$route.path, query: {step_register_franchisor: '2'}}
      }else{
        to = '/account-franchisor'
      }

      if (!this.sessionExpired)
        await this.$router.push(to)
      this.$emit('close')
    },
  },
  mounted() {
    if (this.$route.query.session === 'expired' || this.sessionExpired) {
      const message = 'Your session timed out. Please sign in.'
      this.setAlertFields(message, 'fail')
    }
  },
  methods: {
    async login() {
      // Submit the form.
      try {
        this.setAlertFields();
        const {data} = await this.form.post('api/login');

        // Save the token.
        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires
        });
        await this.$store.dispatch('auth/fetchUser');
      } catch (e) {

        if (e.hasOwnProperty('response')) {
          if (
            e.response.status === 400 &&
            e.response.data.status === 'fail' &&
            e.response.data.message
          ) {
            this.form.reset();
            this.$refs.loginForm.reset();
            this.setAlertFields(e.response.data.message, e.response.data.status);
          } else if (e.response.status !== 422) {
            this.$emit('close');
          }
        }
      }
    },
    setAlertFields(message = '', status = '') {
      this.message = message;
      this.status = status;

      if (!this.form.errors.any()) {
        this.form.errors.set({error: message});
      }
    },
    onAuthenticated(isNewUser) {
      if (isNewUser) {
        this.$store.dispatch('registration/openModal', {
          userType: this.userTypeFranchise,
          stage: this.stagePreferences,
        });
      }
    },
  },
};
