//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    title: {
      type: String,
      default: () => 'Button'
    },
    isActive: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
