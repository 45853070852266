//
//
//
//
//
//
//
//
//
//
//
//
//

import zenscroll from "zenscroll";
import {mapGetters} from "vuex";

export default {
  name: "LandingMenu",
  computed: {
    ...mapGetters({
      mainStyles: 'landing/main_styles',
      modalIds: 'landing/modal_ids',
      menu: 'landing/menu',
    }),
  },
  methods: {
    handlerButton(id) {
      const section = document.getElementById(id)
      this.closeModal()
      zenscroll.to(section, 2000)
    },
    closeModal() {
      this.$bvModal.hide(this.modalIds.mobileMenu)
    },
  }
}
