//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from "vuex";
import cloneDeep from "lodash/cloneDeep";
import {WORK_BOOK, WORK_BOOK_QUESTION_TYPES} from '@/store/constants'

export default {
  name: "WorkBookQuestionsWithChoice",
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    answerTypes: WORK_BOOK_QUESTION_TYPES,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlockName() {
      return this.questionsBlock.name ?? `Questions`
    },
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    checkDisabled(questionId) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId).value
      const countSelected = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.filter(item => item.value.selected).length
      if (savedValue.selected)
        return false
      return countSelected >= 3
    },
    selectValue(questionId) {
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)).value
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)

      savedValue.selected = !savedValue.selected

      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue})
    },
    changeValue(questionId, value) {
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)).value
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)

      savedValue.text = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue})
    },
    getValue(questionId) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
      return savedValue.value.text
    },
    checkValueActive(questionId) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
      return savedValue.value.selected
    },
  }
}
