//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {cloneDeep} from "lodash/fp"
import {
  mapActions,
  mapGetters,
} from "vuex"
import Form from "vform"
import DefaultButton from "@/components/buttons/DefaultButton"
import ClearIcon from "@/components/icons/ClearIcon"
import VInputDefault from "@/components/form/defaultField/VInputDefault"
import VButton from "@/components/VButton"
import Confetti from "@/components/Confetti"

import storeMixin from "@/components/mixins/storeMixin"
import googleAnalyticsMixin from "~/components/mixins/googleAnalyticsMixin"

import {
  REGISTRATION_DEFAULT_PASSWORD,
  SIGN_UP_FRANCHISE_FAST_STEP_TWO,
  SIGN_UP_FRANCHISE_FAST_STEP_THREE,
  REGISTRATION_DEFAULT_FIRST_NAME,
  REGISTRATION_DEFAULT_LAST_NAME,
  USER_TYPE_FRANCHISEE,
  MODAL_SIGN_UP_FRANCHISE_ID,
} from "~/store/constants"

export default {
  name: "PendingList",
  mixins: [
    storeMixin,
    googleAnalyticsMixin,
  ],
  components: {
    Confetti,
    VButton,
    VInputDefault,
    ClearIcon,
    DefaultButton,
  },
  data: () => ({
    items: [],
    showModal: false,
    showCongrats: false,
    form: new Form({
      email: null,
      first_name: REGISTRATION_DEFAULT_FIRST_NAME,
      last_name: REGISTRATION_DEFAULT_LAST_NAME,
      password: REGISTRATION_DEFAULT_PASSWORD,
      password_confirmation: REGISTRATION_DEFAULT_PASSWORD,
      user_type: USER_TYPE_FRANCHISEE,
      list: [],
    })
  }),
  computed: {
    ...mapGetters({
      pendingListArr: 'account/pendingListArr',
    }),
    countList() {
      return this.pendingListArr.length
    },
    titleForm() {
      return this.showCongrats ? 'Email sent!' : 'Enter your e-mail to get requested info'
    },
  },
  methods: {
    ...mapActions({
      updatePendingList: 'account/updatePendingList',
      associateUserWithRating: 'rating/associateUserWithRating',
      savePendingList: 'registration/savePendingList',
      pendingListEmail: 'registration/pendingListEmail',
      pendingListSendArr: 'registration/pendingListSendArr',
    }),
    async registerFranchisee() {
      try {
        const {data} = await this.form.post('/api/register')

        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires,
        })

        this.submitted = true

        await this.gaSendFranchiseSignUpForm()

        if (this.pendingListSendArr){
          this.savePendingList()
          this.updatePendingList([])
        }
        await this.$store.dispatch('auth/fetchUser')

        this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
        this.$store.commit('registration/SET_REGISTRATION_FRANCHISEE_STAGE', SIGN_UP_FRANCHISE_FAST_STEP_TWO)

        await this.associateUserWithRating()
      } catch (e) {
        console.log(e)
      }
    },
    handlerClickSignUp() {
      this.showModal = false
      this.showFranchiseePendingListRegistrationWithSave(
        this.form.email === ''
          ? 'default'
          : this.form.email)
    },
    handlerClickRequest() {
      this.showModal = true
    },
    async handlerClickSend() {
      await this.registerFranchisee()
    },
  }
}
