//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputCustom from "@/components/form/customField/InputCustom";
import TextAreaCustom from "@/components/form/customField/TextAreaCustom";
import Form from 'vform';
import {mapActions, mapGetters} from "vuex";
import VButton from "@/components/VButton";
import {MASK_PHONE} from "@/store/constants"

export default {
  name: "EditAddress",
  components: {VButton, TextAreaCustom},
  data: () => ({
    address: '',
    showButton: false,
    form: new Form({
      certified_by_fa: '',
      company_twitter_username: '',
      company_stage: '',
      company_short_description: '',
      company_description: '',
      location: '',
      number_of_locations: '',
      founders: [],
      ratings: [],
      tags: [],
      delete_avatar_ids: [],
    }),
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      franchise: 'business-v2/franchise',
    }),
  },
  mounted() {
    this.updateUser()
  },
  methods: {
    ...mapActions({
      fetchUser:'auth/fetchUser',
      fetchFranchise: 'business-v2/fetchFranchise'
    }),
    updateUser() {
      this.form.certified_by_fa = this.franchise.certified_by_fa
      this.form.company_twitter_username= this.franchise.company_twitter_username
      this.form.company_stage=this.franchise.company_stage
      this.form.company_short_description=this.franchise.company_short_description
      this.form.company_description=this.franchise.company_description
      this.form.location=this.franchise.location
      this.form.umber_of_locations=this.franchise.number_of_locations
      this.form.founders = this.franchise.founders
      this.form.ratings = this.franchise.ratings
      this.form.tags=this.franchise.tags
      this.form.delete_avatar_ids= this.franchise.delete_avatar_ids
      this.address= this.franchise.location
    },

    handlerInputAddress() {
      this.showButton = this.address !== this.user.address
    },
    async handlerSaveAddress() {
      try {
        this.form.location = this.address
        const url = `/api/v2/businesses/edit/${this.franchise.id}/${this.franchise.version_id}/main`
        await this.form.post(url, {
          headers: {'Content-Type': 'multipart/form-data'},
          transformRequest: [function (data) {
            const formData = new FormData
            Object.keys(data).forEach(key => {
              if (
                key === 'founders' ||
                key === 'ratings' ||
                key === 'delete_avatar_ids' ||
                key === 'tags'
              )
                return;

              formData.append(key, data[key] ?? '')
            })
            return formData;
          }],
        })
        this.$emit('sendSuccess')
        await this.fetchFranchise({
          franchiseId:this.franchise.id,
          versionId:this.franchise.version_id,
        })
      } catch (e) {
        console.log(e)
        this.$emit('sendError')
      }
    },
  }
}
