//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import DefaultLogo from '@/assets/icons/landing/DefaultLogo.svg?inline'
import LandingShare from "@/components/landing/LandingShare";
import {mapGetters} from "vuex";

export default {
  name: "LandingFooter",
  components: {
    LandingShare,
    DefaultLogo,
  },
  mixins: [
    landingStyles,
  ],
  computed: {
    ...mapGetters({
      franchise: 'landing/franchise',
      mainStyles: 'landing/main_styles',
      modal_ids: 'landing/modal_ids',
      logo: 'landing/logo',
    }),
    currentYear() {
      return new Date().getFullYear()
    },
    imgUrl() {
      return `${process.env.BACKEND_BASE_URL}api/v2/landing/${this.logo.url}/image`;
    }
  },
  mounted() {

  },
  methods: {
    handlerBack() {
      this.$router.push('/')
    },
    openModal() {
      this.$bvModal.show(this.modal_ids.contact)
    },
  }
}
