//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EyeIcon',
  props: {
    color: {
      type: String,
      default: '#212a4b'
    },
    title: {
      type: String,
      default: ''
    }
  }
}
