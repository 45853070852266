import { render, staticRenderFns } from "./FormEditFranchiseMain.vue?vue&type=template&id=596ee893&scoped=true&"
import script from "./FormEditFranchiseMain.vue?vue&type=script&lang=js&"
export * from "./FormEditFranchiseMain.vue?vue&type=script&lang=js&"
import style0 from "./FormEditFranchiseMain.vue?vue&type=style&index=0&id=596ee893&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596ee893",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RatingStars: require('E:/all-projects/franchise-digital-platform-spa/components/RatingStars.vue').default,CompanyInnovationCurve: require('E:/all-projects/franchise-digital-platform-spa/components/company/CompanyInnovationCurve.vue').default})
