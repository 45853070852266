//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import closeWithWrapperIcon from '@/assets/images/svg/close-with-wrap.svg?inline';

export default {
  components: {
    closeWithWrapperIcon,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].indexOf(value) !== -1;
      },
    },
    dialogClass: {
      type: [String, Array],
      default: '',
    },
    contentClass: {
      type: [String, Array],
      default: '',
    },
  },
  computed: {
    dialogClasses() {
      const dialogClass = ['base-modal-v2-modal-dialog'];

      if (Array.isArray(this.dialogClass)) {
        dialogClass.concat(this.dialogClass);
      } else if (typeof this.dialogClass === 'string') {
        dialogClass.push(this.dialogClass)
      }
      return dialogClass;
    },
    contentClasses() {
      const contentClass = ['base-modal-v2-modal-content'];

      if (Array.isArray(this.contentClass)) {
        contentClass.concat(this.contentClass);
      } else if (typeof this.contentClass === 'string') {
        contentClass.push(this.contentClass)
      }
      return contentClass;
    },
  },
};
