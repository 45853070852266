//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {USER_PREFERENCES_TOTAL_AMOUNT_INVEST} from "@/store/constants";
import VSymbolIcon from "@/components/icons/VSymbolIcon";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CardInvestmentCobalt",
  components: {VSymbolIcon},
  props: {
    maxChecked: {
      type: Number,
      default: 0
    },
    isBlocked: {
      type: Boolean,
      default: false
    },
    hideCounter:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      activeItem: {},
      investmentDirectory: USER_PREFERENCES_TOTAL_AMOUNT_INVEST,
    }
  },
  computed: {
    ...mapGetters({
      preferencesInvestment: 'account/preferencesInvestment',
    }),
    countSelected() {
      return this.investmentDirectory.filter(item => item.value === this.preferencesInvestment).length
    },
  },
  methods: {
    ...mapActions({
      updatePreferencesIndustries: 'account/updatePreferencesInvestment',
    }),
    changeSelected(investId) {
      if (this.isBlocked)
        return

      let value = investId

      if (value === this.preferencesInvestment)
        value = null

      this.updatePreferencesIndustries(value)
    },
    checkIsActive(investId) {
      return this.preferencesInvestment === investId
    },
    checkIsDisabled(investId) {
      return this.preferencesInvestment !== investId && this.countSelected >= this.maxChecked
    },
  }
}
