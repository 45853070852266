//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VSelectRangeInputV2 from './form/VSelectRangeInputV2'
import VSelect from './form/VSelect'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'SearchBar',
  components: {
    VSelect,
    VSelectRangeInputV2
  },
  data () {
    return {
      inputs: {
        industry: [],
        locations: [
          { title: 'Alabama, AL', value: 'Alabama' },
          { title: 'Alaska, AK', value: 'Alaska' },
          { title: 'Arizona, AZ', value: 'Arizona' },
          { title: 'Arkansas, AR', value: 'Arkansas' },
          { title: 'California, CA', value: 'California' },
          { title: 'Colorado, CO', value: 'Colorado' },
          { title: 'Connecticut, CT', value: 'Connecticut' },
          { title: 'Delaware, DE', value: 'Delaware' },
          { title: 'Florida, FL', value: 'Florida' },
          { title: 'Georgia, GA', value: 'Georgia' },
          { title: 'Hawaii, HI', value: 'Hawaii' },
          { title: 'Idaho, ID', value: 'Idaho' },
          { title: 'Illinois, IL', value: 'Illinois' },
          { title: 'Indiana, IN', value: 'Indiana' },
          { title: 'Iowa, IA', value: 'Iowa' },
          { title: 'Kansas, KS', value: 'Kansas' },
          { title: 'Kentucky, KY', value: 'Kentucky' },
          { title: 'Louisiana, LA', value: 'Louisiana' },
          { title: 'Maine, ME', value: 'Maine' },
          { title: 'Maryland, MD', value: 'Maryland' },
          { title: 'Massachusetts, MA', value: 'Massachusetts' },
          { title: 'Michigan, MI', value: 'Michigan' },
          { title: 'Minnesota, MN', value: 'Minnesota' },
          { title: 'Mississippi, MS', value: 'Mississippi' },
          { title: 'Missouri, MO', value: 'Missouri' },
          { title: 'Montana, MT', value: 'Montana' },
          { title: 'Nebraska, NE', value: 'Nebraska' },
          { title: 'Nevada, NV', value: 'Nevada' },
          { title: 'New Hampshire, NH', value: 'New Hampshire' },
          { title: 'New Jersey, NJ', value: 'New Jersey' },
          { title: 'New Mexico, NM', value: 'New Mexico' },
          { title: 'New York, NY', value: 'New York' },
          { title: 'North Carolina, NC', value: 'North Carolina' },
          { title: 'North Dakota, ND', value: 'North Dakota' },
          { title: 'Ohio, OH', value: 'Ohio' },
          { title: 'Oklahoma, OK', value: 'Oklahoma' },
          { title: 'Oregon, OR', value: 'Oregon' },
          { title: 'Pennsylvania, PA', value: 'Pennsylvania' },
          { title: 'Rhode Island, RI', value: 'Rhode Island' },
          { title: 'South Carolina, SC', value: 'South Carolina' },
          { title: 'South Dakota, SD', value: 'South Dakota' },
          { title: 'Tennessee, TN', value: 'Tennessee' },
          { title: 'Texas, TX', value: 'Texas' },
          { title: 'Utah, UT', value: 'Utah' },
          { title: 'Vermont, VT', value: 'Vermont' },
          { title: 'Virginia, VA', value: 'Virginia' },
          { title: 'Washington, WA', value: 'Washington' },
          { title: 'West Virginia, WV', value: 'West Virginia' },
          { title: 'Wisconsin, WI', value: 'Wisconsin' },
          { title: 'Wyoming, WY', value: 'Wyoming' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      filters: state => state.business.filter
    })
  },
  async mounted () {
    const { data } = await axios.get('/api/v1/industry/get')
    this.inputs.industry = data
    this.modalStateFDP = this.$refs.modalStateFDP
    this.pdfLink = this.$refs.pdfLink
  },
  methods: {
    handleSearch () {
      this.$router.push({
        name: 'franchising_directory'
      })
    }
  }
}
