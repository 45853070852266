//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  model:{
    prop:'switchState',
    event:'change',
  },
  props: {
    switchState: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSwitch (switchState) {
      this.$emit('change', switchState)
    }
  }

}
