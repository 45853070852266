//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapGetters
} from "vuex"
import Form from "vform"
import LinkedInSignUpButton from "@/components/buttons/LinkedInSignUpButton"
import GoogleSignUpButton from "@/components/buttons/GoogleSignUpButton"
import VButton from "@/components/VButton"
import LogoWithTradeMark from "~/components/LogoWithTradeMark"
import VInputDefault from "@/components/form/defaultField/VInputDefault"

import hubspotMixin from "~/components/mixins/hubspotMixin"

import {
  USER_TYPE_FRANCHISOR,
  REGISTRATION_DEFAULT_PASSWORD,
  FRANCHISOR_FLOW_OPTIONS,
  CLOSED_FORM_TYPES,
} from '@/store/constants'

export default {
  name: "SignUpFranchisorStepOne",
  components: {
    VInputDefault,
    LogoWithTradeMark,
    VButton,
    GoogleSignUpButton,
    LinkedInSignUpButton,
  },
  mixins: [
    hubspotMixin,
  ],
  data: () => {
    return {
      submitted: false,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: REGISTRATION_DEFAULT_PASSWORD,
        password_confirmation: REGISTRATION_DEFAULT_PASSWORD,
        busy: false,
        user_type: USER_TYPE_FRANCHISOR
      }),
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    userType() {
      return USER_TYPE_FRANCHISOR
    }
  },
  async destroyed () {
    if (!this.submitted && this.form.email) {
      await this.hsClosedForm(
        this.form.email,
        CLOSED_FORM_TYPES.signUpFranchisor,
        this.form.first_name,
        this.form.last_name
      )
    }
  },
  methods: {
    async registerFranchisor() {
      try {
        const {data} = await this.form.post('/api/register')

        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires,
        })

        this.submitted = true
        await this.$store.dispatch('auth/fetchUser')
        await this.$store.dispatch('registration/fetchLeads')

        await this.$emit('next-step')
      } catch (e) {
        console.log(e)
      }
    },
    async onAuthenticated(isNewUser) {
      if (isNewUser) {
        await this.$store.dispatch('registration/fetchLeads')
        await this.$emit('next-step')
      } else {
        await this.$router.push('/account-franchisor')
      }
    },
    onError(message) {
      this.errorMessage = message

      if (!this.form.errors.any()) {
        this.form.errors.set({error: message})
      }
    }
  }
}
