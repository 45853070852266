//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Arrow from '../icons/Arrow'
import Deselect from '../icons/Deselect'
import VRangeInputV2 from './VRangeInputV2'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    Deselect,
    Arrow,
    VRangeInputV2
  },
  directives: {
    ClickOutside
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    placeholder: {
      type: String,
      default: () => 'Select...'
    },
    options: {
      type: Array,
      default: () => []
    },
    optionsLabel: {
      type: String,
      default: () => 'title'
    },
    value: {
      type: Object,
      default: () => ''
    },
    bordered: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: () => ''
    },
    searchFranchises: {
      type: Boolean,
      default: false,
    },
    isMoney: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      OpenIndicator: {
        render: createElement => createElement(Arrow)
      },
      Deselect: {
        render: createElement => createElement(Deselect)
      },
      placement: 'bottom',
      active: false
    }
  },
  computed: {
    title () {
      if (this.value.min === null && this.value.max === null) {
        return ''
      }
      return `${this.value.min || ''} - ${this.value.max || ''}`
    }
  },
  created () {
    // document.addEventListener('click', this.documentClick)
  },
  destroyed () {
    // document.removeEventListener('click', this.documentClick)
  },
  mounted () {
    // prevent click outside event with popupItem.
    this.popupItem = this.$refs['range-input-v2-menu'].$el
  },
  methods: {
    toggleSelectMenu () {
      this.active = !this.active
    },
    hideSelectMenu () {
      this.active = false
    },
    clear () {
      this.active = false
      this.$emit('input', { min: null, max: null })
    }
  }
}
