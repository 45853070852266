//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import DashboardFranchiseeComparisons from '@/components/dashboards/viewFranchisee/DashboardFranchiseeComparisons';
import DashboardFranchiseeFavorite from '@/components/dashboards/viewFranchisee/DashboardFranchiseeFavorite';
import DashboardFranchiseeMatches from '@/components/dashboards/viewFranchisee/DashboardFranchiseeMatches';
import DashboardFranchiseeProfile from '@/components/dashboards/viewFranchisee/DashboardFranchiseeProfile';
import Loader from '@/components/Loader';
import ClearIcon from '@/assets/icons/cross/CloseIcon.svg?inline';
import SilentMode from '@/components/account-franchisor/SilentMode';

import { MODAL_VIEW_FRANCHISEE_INFO } from '@/store/constants';

export default {
  name: 'ModalViewFranchisee',
  components: {
    SilentMode,
    DashboardFranchiseeComparisons,
    DashboardFranchiseeFavorite,
    DashboardFranchiseeProfile,
    Loader,
    DashboardFranchiseeMatches,
    ClearIcon,
  },
  data: () => ({
    modalId: MODAL_VIEW_FRANCHISEE_INFO,
    tabs: [
      {
        id: 'matches',
        title: 'My matches',
        component: 'dashboard-franchisee-matches',
        show: true,
        loaded: false,
      },
      {
        id: 'profile',
        title: 'Profile',
        component: 'dashboard-franchisee-profile',
        show: false,
        loaded: false,
      },
      {
        id: 'favorite_franchises',
        title: 'Favorite Franchises',
        component: 'dashboard-franchisee-favorite',
        show: false,
        loaded: false,
      },
      {
        id: 'comparisons',
        title: 'Comparisons',
        component: 'dashboard-franchisee-comparisons',
        show: false,
        loaded: false,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      contact: 'account-franchisor/franchiseeViewed',
      showPreloader: 'account/show_preloader',
      listFranchise: 'account/listFranchise',
      answersAll: 'account/answers/answers',
      listFranchiseLike: 'account/listFranchiseLike',
      listFranchiseCompare: 'account/listFranchiseCompare',
    }),
  },
  mounted() {
    this.$root.$on('bv::collapse::state', this.handlerChangeStateCollapse);
  },
  methods: {
    ...mapActions({
      setFranchiseViewId: 'account/setFranchiseViewId',
      setShowPreloader: 'account/setShowPreloader',
      fetchComparisonsList: 'account/fetchComparisonsList',
      fetchLikeList: 'account/fetchLikeList',
      fetchPreferences: 'account/fetchPreferences',
      fetchFranchises: 'account/fetchFranchises',
      fetchAnswers: 'account/answers/fetchAnswers',
    }),
    async handlerShow() {
      await this.setFranchiseViewId(this.contact.franchisee_id);
    },
    handlerHidden() {
      this.tabs.forEach((tab, tabIndex) => {
        this.tabs[tabIndex].loaded = false;
        this.tabs[tabIndex].show = tab.id === 'matches';
      });
    },
    async handlerChangeStateCollapse(collapseId, isJustShown) {
      const activeTabIndex = this.tabs.findIndex((item) => item.id === collapseId);

      if (collapseId === 'matches' && isJustShown && !this.tabs[activeTabIndex].loaded) {
        await this.fetchPreferences();
        await this.fetchFranchises();
        this.tabs[activeTabIndex].loaded = true;
      }

      if (collapseId === 'profile' && isJustShown && !this.tabs[activeTabIndex].loaded) {
        await this.fetchAnswers();
        this.tabs[activeTabIndex].loaded = true;
      }

      if (
        collapseId === 'favorite_franchises' &&
        isJustShown &&
        !this.tabs[activeTabIndex].loaded
      ) {
        await this.fetchLikeList();
        this.tabs[activeTabIndex].loaded = true;
      }

      if (collapseId === 'comparisons' && isJustShown && !this.tabs[activeTabIndex].loaded) {
        await this.fetchComparisonsList();
        this.tabs[activeTabIndex].loaded = true;
      }

      this.tabs.forEach((item) => {
        if (item.id === collapseId) item.show = isJustShown;
      });
    },
    changeTab(tabId) {
      const tab = this.tabs.find((item) => item.id === tabId);

      if (tab.show) return;

      this.$root.$emit('bv::toggle::collapse', tabId);
    },
  },
};
