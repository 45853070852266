import { render, staticRenderFns } from "./LandingSectionCollapse.vue?vue&type=template&id=400aa057&scoped=true&"
import script from "./LandingSectionCollapse.vue?vue&type=script&lang=js&"
export * from "./LandingSectionCollapse.vue?vue&type=script&lang=js&"
import style0 from "./LandingSectionCollapse.vue?vue&type=style&index=0&id=400aa057&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400aa057",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingText: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingText.vue').default,LandingButton: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingButton.vue').default,LandingImage: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingImage.vue').default})
