//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import SectionTools from "@/components/landing/elements/SectionTools";
import {directive, Swiper, SwiperSlide} from 'vue-awesome-swiper'
import LandingText from "@/components/landing/elements/LandingText";

export default {
  name: "LandingSectionFacts",
  components: {
    LandingText,
    SectionTools,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive
  },
  mixins: [
    landingStyles,
  ],
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      showButtonNext: false,
      showButtonPrev: false,
      swiperOption: {
        init: false,
        updateOnWindowResize: true,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        breakpoints: {
          '768': {
            slidesPerView: 2,
          },
          '1200': {
            slidesPerView: 3,
          },
        }
      },
      showTools: false,
      containerSize: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    backgroundImage() {
      const imageId = this.section.styles.backgroundImage ?? ''

      return (imageId === '') ? '' : `${process.env.BACKEND_BASE_URL}api/v2/landing/${imageId}/image`
    },
  },
  mounted() {
    this.$refs.factsSlider.$swiper.init()
    if (this.editable)
      this.updateSectionSizes()
  },
  methods: {
    updateSectionSizes() {
      this.containerSize.width = this.$refs[`container_${this.section.id}`].clientWidth
      this.containerSize.height = this.$refs[`section_${this.section.id}`].clientHeight
    },
    onSliderInit(swiper) {
      if (!this.editable) {
        this.showButtonNext = true
      }
      this.onSlideChange(swiper)
    },
    onSlideChange(swiper) {
      this.showButtonNext = !swiper.isEnd
      this.showButtonPrev = !swiper.isBeginning
    },
    getIcon(icon) {
      try {
        return require(`~/assets/icons/landing/facts/icon-${icon}.svg?raw`)
      } catch (e) {
        console.log(e);
        return ''
      }
    },
    changeSlide(prev = false) {
      if (prev)
        this.$refs.factsSlider.$swiper.slidePrev()
      else
        this.$refs.factsSlider.$swiper.slideNext()
    },
    updateSection() {
      this.updateSectionSizes()
      this.$store.dispatch('landing/updateSection', {section: this.section, index: this.currentIndex})
    },
  },
}
