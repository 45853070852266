//
//
//
//
//
//

export default {
  name: "VSymbolIcon"
}
