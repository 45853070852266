//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from "@/components/buttons/BaseButton"
export default {
  components: {BaseButton},
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tasks: [
        { name: 'Task 1', description: 'Task 1 description' },
        { name: 'Task 2', description: 'Task 2 description' },
      ],
      tasksBackUp: [],
    };
  },
  created() {
    this.tasksBackUp = [...this.tasks]
  },
  methods: {
    addNewTask() {
      this.tasks.unshift({ name: '', description: '' });
    },
    handleTaskRemove(key) {
      this.tasks.splice(key, 1);
    },
    resetAllChanges () {
      this.tasks = [...this.tasksBackUp]
    }
  },
};
