//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CompanyFounder",
  props: {
    founder: {
      type: Object,
      default: () => ({
        avatar: {},
        first_name: '',
        last_name:'',
        full_name: '',
        description: ''
      })
    }
  },
  computed:{
    getInitials(){
      const initial = this.founder.first_name.charAt(0)+this.founder.last_name.charAt(0)
     return initial.toUpperCase()
    }
  }
}
