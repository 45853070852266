//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModal from '../modals/BaseModal'
import ContactPreRegister from '~/pages/contact-pre-register'

export default {
  components: {
    ContactPreRegister,
    BaseModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
}
