//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
name: "InputCustom",
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    topLabel: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    value: {
      type: [Number, String],
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    inputNativeType: {
      type: String,
      default: () => 'text',
    },
    inputName: {
      type: String,
      default: () => '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    compacted: {
      type: Boolean,
      default: false,
    },
    mask:{
      type: String,
      default:'',
    }
  },
}
