//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform';
import { mapState } from 'vuex';
import { REGISTRATION_STAGE_STEPPER_CONGRATULATIONS } from '~/store/constants';
import FileUploader from '~/components/form/FileUploader';

export default {
  components: {
    FileUploader,
  },
  props: {
    stepIndex: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    compacted: false,
    windowWidth: 0,
    mobileWindowWidth: 769,
    form: new Form({
      fdd: '',
      item_7: '',
      item_19: '',
      franchisees: '',
      logo: '',
      logo_landing: '',
      presentation: '',
      promo_video_link: '',
      landing_phone: '',
    }),
  }),
  watch: {
    windowWidth(newVal, oldVal) {
      if (process.client) {
        if (newVal > this.mobileWindowWidth) {
          this.compacted = false;
        } else {
          this.compacted = true;
        }
      }
    },
    option(newVal, oldVal) {
      if (newVal !== null && newVal !== oldVal) {
        this.form.reset();
      }
    },
  },
  computed: {
    ...mapState({
      franchiseId: (state) => state.registration.franchiseId,
      option: (state) => state.registration.option,
    }),
  },
  created() {
    if (process.client) {
      this.windowWidth = window.outerWidth;
      window.addEventListener('resize', this.resizeEventHandler);
    }
  },
  methods: {
    resizeEventHandler(e) {
      this.windowWidth = e.target.outerWidth;
    },
    async submit() {
      try {
        await this.form.submit('post', `/api/v1/business/flow/upload`, {
          headers: { 'Content-Type': 'multipart/form-data' },
          transformRequest: [
            function (data, headers) {
              const formData = new FormData();
              formData.append('fdd', data.fdd);
              formData.append('item_7', data.item_7);
              formData.append('item_19', data.item_19);
              formData.append('franchisees', data.franchisees);
              formData.append('logo', data.logo);

              return formData;
            },
          ],
        });

        await this.$store.dispatch(
          'registration/setStage',
          REGISTRATION_STAGE_STEPPER_CONGRATULATIONS
        );
      } catch (e) {
        setTimeout(() => {
          this.form.errors.set({})
        },10000)

        console.log(e)
        return false;
      }
    },
  },
};
