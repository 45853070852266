//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ClearIcon from '@/components/icons/ClearIcon';
import SignUpFranchisorStepOne from '@/components/form/SignUpFranchisor/SignUpFranchisorStepOne';
import SignUpFranchisorStepTwo from '@/components/form/SignUpFranchisor/SignUpFranchisorStepTwo';

import { MODAL_SIGN_UP_FRANCHISOR_ID } from '@/store/constants';

import hubspotMixin from '@/components/mixins/hubspotMixin';

export default {
  name: 'ModalSignUpFranchisor',
  components: {
    ClearIcon,
    SignUpFranchisorStepOne,
    SignUpFranchisorStepTwo,
  },
  mixins: [hubspotMixin],
  data: () => ({
    listSteps: [
      {
        component: 'sign-up-franchisor-step-one',
        modalSize: 'sm',
      },
      {
        component: 'sign-up-franchisor-step-two',
        modalSize: 'sm',
      },
    ],
    currentStep: {},
    currentStepIndex: 0,
    pageLink: '',
    modalId: MODAL_SIGN_UP_FRANCHISOR_ID,
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/check',
      franchiseStage: 'registration/franchise_stage',
      selectedFranchise: 'account/selectedFranchise',
      showFdd: 'account/showFdd',
      company_url: 'registration/company_url',
      enableFlowBeginToday: 'registration/enableFlowBeginToday',
      enableFlowPendingList: 'registration/enableFlowPendingList',
    }),
  },
  watch: {
    currentStepIndex() {
      this.currentStep = this.listSteps[this.currentStepIndex];
    },
  },
  mounted() {
    this.currentStep = this.listSteps[this.currentStepIndex];
  },
  methods: {
    nextStep() {
      this.currentStepIndex++;
    },
  },
};
