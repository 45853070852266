import { render, staticRenderFns } from "./CompanyPageLandingNew.vue?vue&type=template&id=9a59f5aa&scoped=true&"
import script from "./CompanyPageLandingNew.vue?vue&type=script&lang=js&"
export * from "./CompanyPageLandingNew.vue?vue&type=script&lang=js&"
import style0 from "./CompanyPageLandingNew.vue?vue&type=style&index=0&id=9a59f5aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a59f5aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingHeader: require('E:/all-projects/franchise-digital-platform-spa/components/landing/LandingHeader.vue').default,LandingSectionHero: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionHero.vue').default,LandingSectionVideo: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionVideo.vue').default,LandingSectionImage: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionImage.vue').default,LandingSectionCollapse: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionCollapse.vue').default,LandingSectionGallery: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionGallery.vue').default,LandingSectionComments: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionComments.vue').default,LandingSectionStats: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionStats.vue').default,LandingSectionFacts: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionFacts.vue').default,LandingSectionPlayList: require('E:/all-projects/franchise-digital-platform-spa/components/landing/sections/LandingSectionPlayList.vue').default,LandingFooter: require('E:/all-projects/franchise-digital-platform-spa/components/landing/LandingFooter.vue').default,LandingControl: require('E:/all-projects/franchise-digital-platform-spa/components/landing/LandingControl.vue').default,LandingStickyButton: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingStickyButton.vue').default,LandingMenu: require('E:/all-projects/franchise-digital-platform-spa/components/landing/LandingMenu.vue').default})
