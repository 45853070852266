//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import LandingText from "@/components/landing/elements/LandingText";
import LandingImage from "@/components/landing/elements/LandingImage";
import showReadMore from "@/components/mixins/showReadMore";
import SectionTools from "@/components/landing/elements/SectionTools";
import LandingButton from "@/components/landing/elements/LandingButton";
import {mapGetters} from "vuex";

export default {
  name: "LandingSectionImage",
  components: {
    SectionTools,
    LandingText,
    LandingImage,
    LandingButton,
  },
  mixins: [
    landingStyles,
    showReadMore,
  ],
  model: {
    prop: 'section',
  },
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    editable: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => {
    return {
      showTools: false,
      collapseHeight: 0,
      containerSize: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    ...mapGetters({
      modalIds: 'landing/modal_ids',
    }),
    isBtnLink() {
      return this.section.block.button.type !== 'file';
    },
    isBtnFile() {
      return this.section.block.button.type === 'file';
    },
    backgroundImage() {
      const imageId = this.section.styles.backgroundImage ?? ''

      return (imageId === '') ? '' : `${process.env.BACKEND_BASE_URL}api/v2/landing/${imageId}/image`
    },
  },
  mounted() {
    if (this.editable)
      this.updateSectionSizes()
  },
  methods: {
    updateSectionSizes() {
      this.containerSize.width = this.$refs[`container_${this.section.id}`].clientWidth
      this.containerSize.height = this.$refs[`section_${this.section.id}`].clientHeight
    },
    checkHeight() {
      const textBlockHeight = this.$refs[`textBlock_${this.section.id}`].clientHeight
      const imageBlockHeight = this.$refs[`imageBlock_${this.section.id}`].clientHeight

      this.collapseHeight = textBlockHeight > imageBlockHeight ? imageBlockHeight - 146 : 0
    },
    handlerClickButton(button) {
      if (button.showModal) {
        this.$bvModal.show(this.modalIds.contact)
        return
      }
      window.open(button.url, 'blank')
    },
    updateSection() {
      this.updateSectionSizes()
      this.$store.dispatch('landing/updateSection', {section: this.section, index: this.currentIndex})
    },
  }
}
