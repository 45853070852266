//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep';
import {WORK_BOOK, WORK_BOOK_TOP_LIST_KEYS} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";
import ArrowCodeIcon from '~/assets/icons/arrows/ArrowCodeIcon.svg?inline'

export default {
  name: "WorkBookQuestionsClosedTopEntry",
  components: {
    ArrowCodeIcon,
  },
  props: {
    numberTop: {
      type: Number,
      default: 0
    },
    blockIndex: {
      type: Number,
      default: 0
    },
    customTabName: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    totalCounter: [],
    currentFranchiseIndex: 0,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    getFranchiseName() {
      if (this.customTabName !== '')
        return `${this.customTabName} #${this.currentFranchiseIndex + 1}`

      if (typeof this.topList[this.getListIndex] === "undefined")
        return ''

      const currentFranchise = this.franchise.find(item => item.id === this.topList[this.getListIndex][this.currentFranchiseIndex])

      if (typeof currentFranchise?.franchise === "undefined" || currentFranchise?.franchise === '')
        return `Franchise #${this.currentFranchiseIndex + 1}`

      return currentFranchise.franchise
    },
    getListIndex() {
      let listIndex = WORK_BOOK_TOP_LIST_KEYS.TEN

      if (this.numberTop === 5)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.FIVE

      if (this.numberTop === 3)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.THREE

      if (this.numberTop === 2)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.TWO

      return listIndex
    },
    franchiseDisabled(){
      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(this.topList[this.getListIndex][this.currentFranchiseIndex]))
      if (typeof currentFranchise?.franchise === "undefined" || currentFranchise?.franchise === '')
        return true

      return false
    },
  },
  created() {
    this.updateTotalCounter()
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    getTotal() {
      const answersList = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers

      let counter = 0
      answersList.forEach((answer) => {
        counter += Number(answer.value[this.currentFranchiseIndex]) ?? 0
      })

      return counter
    },
    getValue(questionId, answerIndex) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)

      return savedValue.value[answerIndex - 1]
    },
    handlerUpdateValue(questionId, answerIndex, value) {
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)

      savedValue.value[answerIndex - 1] = value

      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
      this.updateTotalCounter()
    },
    updateTotalCounter() {
      const answersList = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers

      answersList.forEach((answer, answerIndex) => {

        answer.value.forEach((item, index) => {

          if (answerIndex === 0) {
            this.totalCounter[index] = Number(item) ?? 0
            return
          }

          this.totalCounter[index] += Number(item)

        })
      })

    },
  }
}
