//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from "vuex"
import ActionsDateRangePicker from "~/components/account-franchisor/range/ActionsDateRangePicker"
import ContactActionNotesItem from "~/components/account-franchisor/contacts/actions/notes/ContactActionNotesItem"
import ContactActionNotesItemEdit from "~/components/account-franchisor/contacts/actions/notes/ContactActionNotesItemEdit"
import PaginationDefault from "~/components/paginations/PaginationDefault"

export default {
  name: 'ContactActionNotes',
  components: {
    ActionsDateRangePicker,
    PaginationDefault,
  },
  props: {
    franchiseeId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'value',
          label: 'Step',
        },
        {
          key: 'updated_at_formatted',
          label: 'Date',
        },
      ],
      dateRange: {
        startDate: null,
        endDate: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      historyList: 'account-franchisor/actions/history/historyList',
      historyListPaginated: 'account-franchisor/actions/history/historyListPaginated',
      busy: 'account-franchisor/actions/history/busy',
    }),
  },
  watch: {
    dateRange() {
      this.fetchHistory()
    },
  },
  async mounted() {
    await this.fetchHistory()
  },
  methods: {
    ...mapActions({
      $fetchHistoryList: 'account-franchisor/actions/history/fetchHistoryList',
    }),
    handleChangePage(page) {
      this.fetchHistory(page)
    },
    async fetchHistory(page = 1) {
      await this.$fetchHistoryList({
        franchisee_id: this.franchiseeId,
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        page,
      })
    },
  }
}
