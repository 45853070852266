//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseIcon from '@/components/icons/CloseIcon';

export default {
  name: `PromoVideo`,
  components: {
    CloseIcon,
  },
  props: {
    src: {
      type: String,
      default: `https://www.youtube.com/embed/pLnyw_1f9aI?controls=0&rel=0`,
    },
    text: {
      type: String,
      default: `Application presentation`,
    },
  },
  data() {
    return {
      showVideo: false,
    };
  },
  methods: {
    openVideo() {
      this.showVideo = true;
    },
    closeVideo() {
      this.showVideo = false;
    },
  },
};
