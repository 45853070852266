//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowCodeIcon from "@/assets/icons/arrows/ArrowCodeIcon.svg?inline"
import {ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS, ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS_ITEMS_LIST} from "@/store/constants"
import DateRangePicker from "vue2-daterange-picker"
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"

export default {
  name: "VSelectDateDefault",
  components: {
    ArrowCodeIcon,
    DateRangePicker,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    toggleShowDropdown: false,
    toggleShowDatePicker: false,
    dateRange: {
      startDate: '',
      endDate: '',
    }
  }),
  computed: {
    itemsArr() {
      const items = ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS_ITEMS_LIST.filter(item => {
        const findIndex = this.items.findIndex(dataItem => dataItem === item.value)
        return findIndex >= 0
      })

      return items
    },
    selectedText() {
      if (this.value === '')
        return 'None'

      const dateTime = new Date(this.value)
      return dateTime.toDateString().substring(4,16)
    },
    showDropdown() {
      return this.toggleShowDropdown || this.toggleShowDatePicker
    },
    localeConfig() {
      return {
        direction: 'ltr',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 0
      }
    },
  },
  methods: {
    generateByPresetRules(item, day) {
      if (item.value === ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.TOMORROW)
        day.setDate(day.getDate() + 1)

      if (item.value === ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_ONE_WEEK)
        day.setDate(day.getDate() + 7)

      if (item.value === ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_TWO_WEEK)
        day.setDate(day.getDate() + 7 * 2)

      if (item.value === ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_ONE_MONTH)
        day.setMonth(day.getMonth() + 1)

      if (item.value === ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_THREE_MONTHS)
        day.setMonth(day.getMonth() + 3)

      if (item.value === ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_SIX_MONTHS)
        day.setMonth(day.getMonth() + 6)

      return day
    },
    getText(item) {
      let day = this.value === '' ? new Date() : new Date(this.value)
      day = this.generateByPresetRules(item, day)

      if (item.showDayOfWeek)
        return `${item.text} (${day.getDay()})`

      if (item.showDayAndMonth)
        return `${item.text} (${day.toLocaleString('en', {month: 'long', day: 'numeric'})})`

      return item.text
    },
    handlerToggleOpen(hover = false) {
      if (this.disabled)
        return

      if (this.toggleShowDropdown) {
        this.toggleShowDropdown = false
        return
      }

      if (this.toggleShowDatePicker) {
        this.toggleShowDatePicker = false
        return
      }

      if (hover && !this.toggleShowDropdown)
        return

      this.toggleShowDropdown = true
    },
    handlerSelectValue(item) {
      if (item.value === ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.CUSTOM_DATE) {
        this.toggleShowDropdown = false
        this.toggleShowDatePicker = true
        return
      }

      let day = new Date(Date.now())
      day = this.generateByPresetRules(item, day)
      this.$emit('change', this.mutateDateValue(day))
      this.toggleShowDropdown = false
    },
    handlerUpdateDatePicker(time) {
      if (time.startDate === null)
        return

      this.toggleShowDatePicker = false
      this.$emit('change', this.mutateDateValue(time.startDate))
    },
    mutateDateValue(value) {
      const mm = value.getMonth() + 1
      const dd = value.getDate()
      return `${value.getFullYear()}-${(mm > 9 ? '' : '0')}${mm}-${(dd > 9 ? '' : '0')}${dd}`
    },
  }
}
