//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WorkBookInput from "@/components/workbook/elements/WorkBookInput";
import {WORK_BOOK, WORK_BOOK_FIELD_TYPES, WORK_BOOK_QUESTION_TYPES} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";
import ArrowCodeIcon from '~/assets/icons/arrows/ArrowCodeBolderIcon.svg?inline'

export default {
  name: "WorkBookPersonalPreferences",
  components: {
    WorkBookInput,
    ArrowCodeIcon,
  },
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    answerTypes: WORK_BOOK_QUESTION_TYPES,
    fieldTypes: WORK_BOOK_FIELD_TYPES,
    currentTabIndex: 0,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlockName() {
      return this.questionsBlock.name ?? `Questions`
    },
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    answersBlock() {
      return this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
    },
    scoreQuestionBlock() {
      if (!this.questionsBlock.calculateTotal)
        return false

      let count = 0
      this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.forEach(answer => {
        count += Number(answer.value)
      })
      return count
    },
    currentTab() {
      return this.questionsBlock.tabs[this.currentTabIndex]
    },
    currentFields() {
      return this.questionsBlock.fields.filter(item => item.tabIndex === this.currentTabIndex)
    },
    currentQuestions() {
      return this.questionsBlock.questions.filter(item => item.tabIndex === this.currentTabIndex)
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    changeValue(questionId, value) {
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value})
    },
    getValue(questionId) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
      return savedValue ? savedValue.value : null
    },
  }
}
