//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform";
import {mapActions, mapGetters} from "vuex";
import InputCustom from "@/components/form/customField/InputCustom";
import TextAreaCustom from "@/components/form/customField/TextAreaCustom";
import tabEditFranchise from "@/components/mixins/tabEditFranchise";

export default {
  name: "FormEditFranchiseSeo",
  components: {TextAreaCustom, InputCustom},
  mixins:[
      tabEditFranchise
  ],
  data() {
    return {
      form: new Form({
        seo_meta_title: '',
        seo_meta_keywords: '',
        seo_meta_description: '',
      }),
    };
  },
  computed: {
    ...mapGetters({
      franchise: 'business-v2/franchise',
    }),
    formAttributes() {
      return {
        seo_meta_title: this.franchise.seo_meta_title,
        seo_meta_keywords: this.franchise.seo_meta_keywords,
        seo_meta_description: this.franchise.seo_meta_description,
      }
    },
  },
  watch: {
    async franchise() {
      this.updateAttributes()
    },
  },
  created() {
    this.updateAttributes()
  },
  methods:{
    ...mapActions({
      fetchFranchise: 'business-v2/fetchFranchise',
    }),
    updateAttributes() {

      const franchise = this.formAttributes;

      Object.keys(franchise).forEach(key => {
        this.form[key] = franchise[key] ?? ''
      })
    },
    async submit() {
      const url = `/api/v2/businesses/edit/${this.franchise.id}/${this.franchise.version_id}/seo`

      try {
        await this.form.put(url)
        this.$emit('sendSuccess')
        await this.fetchFranchise({
          franchiseId:this.franchise.id,
          versionId:this.franchise.version_id,
        })
      } catch (e) {
        this.$emit('sendError')
        console.log(e);
      }
    },
  }
}
