//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import storeMixin from '@/components/mixins/storeMixin';
import ModalFranchiseNotifications from '@/components/modals/ModalFranchiseNotifications';
import RingNotification from '@/components/nav/RingNotification';
import LikeIcon from '~/components/icons/LikeIcon';
import ScalesIcon from '~/components/icons/ScalesIcon';
import { USER_TYPE_FRANCHISEE, USER_TYPE_FRANCHISOR } from '~/store/constants';

export default {
  components: { RingNotification, ModalFranchiseNotifications, LikeIcon, ScalesIcon },
  mixins: [storeMixin],
  props: {
    iconSize: {
      type: [String, Number],
      default: () => '18',
    },
  },
  data() {
    return {
      busy: true,
      userTypes: {
        franchisee: USER_TYPE_FRANCHISEE,
        franchisor: USER_TYPE_FRANCHISOR,
      },
      notificationKind: '',
      showNot: false,
      defaultText: {
        delete: 'Franchise has been deleted',
        add: 'Franchise has been added',
      },
      notifications: {
        compare: {
          text: '',
          show: false,
          count: 0,
        },
        progress: {
          text: '',
          show: false,
          count: 0,
        },
        like: {
          text: '',
          show: false,
          count: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      clicked: 'business/clicked',
      paginationLike: 'account/paginationLike',
      paginationCompare: 'account/paginationCompare',
      paginationProgress: 'account/paginationProgress',
      userClick: 'account/userClick',
    }),
  },
  watch: {
    paginationProgress(newVal, oldValue) {
      this.notifications.progress.count = newVal.total;
      if (newVal.total === oldValue.total) return;
      if (!this.userClick) return;
      this.updateTextMessage(newVal.total > oldValue.total, 'progress');
    },
    paginationLike(newVal, oldValue) {
      this.notifications.like.count = newVal.total;
      if (newVal.total === oldValue.total) return;
      if (!this.userClick) return;
      this.updateTextMessage(newVal.total > oldValue.total, 'like');
    },
    paginationCompare(newVal, oldValue) {
      this.notifications.compare.count = newVal.total;
      if (newVal.total === oldValue.total) return;
      if (!this.userClick) return;
      this.updateTextMessage(newVal.total > oldValue.total, 'compare');
    },
    async authenticated() {
      await this.getDataFromStorage();
    },
    user() {
      this.checkUser();
    },
  },
  async mounted() {
    await this.getDataFromStorage();
    this.busy = false;
    await this.checkUser();
  },
  methods: {
    ...mapActions({
      fetchComparisonsList: 'account/fetchComparisonsList',
      fetchLikeList: 'account/fetchLikeList',
      fetchProgressList: 'account/fetchProgressList',
      setUserClick: 'account/setUserClick',
    }),
    updateTextMessage(increase, notify) {
      Object.keys(this.notifications).forEach((key) => {
        this.notifications[key].show = false;
      });

      this.notifications[notify].text = increase ? this.defaultText.add : this.defaultText.delete;
      this.notifications[notify].show = true;
      this.setUserClick(false);
      setTimeout(() => {
        this.notifications[notify].show = false;
      }, 5000);
    },
    async getDataFromStorage() {
      if (!this.authenticated) return;

      await this.$store.dispatch('notifications/fetchNotifications');

      if (!this.user.isFranchisee) return;

      await this.fetchLikeList();
      await this.fetchComparisonsList();
      await this.fetchProgressList();
    },
    async checkUser() {
      if (this.authenticated) {
        this.showNot = !!this.user.isFranchisee;
      }
      if (!this.authenticated) this.showNot = true;
    },

    showModal(className) {
      this.notificationKind = className;
      setTimeout(() => {
        this.notificationKind = '';
      }, 5000);
    },

    /**
     *
     * @param link
     */
    async goToPage(link) {
      if (this.authenticated) {
        if (this.user.isFranchisee) {
          await this.$router.push(link);
        }
      } else {
        await this.showFranchiseeFastRegistration();
      }
    },
  },
};
