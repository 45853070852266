//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform";
import {mapActions, mapGetters} from "vuex";
import FileInputCustom from "@/components/form/customField/FileInputCustom";
import FileInputImageCustom from "@/components/form/customField/FileInputImageCustom";
import tabEditFranchise from "@/components/mixins/tabEditFranchise";

export default {
  name: "FormEditFranchiseFiles",
  components: {
    FileInputImageCustom,
    FileInputCustom,
  },
  mixins:[
    tabEditFranchise
  ],
  data: () => ({
    form: new Form({
      fdd: '',
      item_7: '',
      item_19: '',
      franchisees: '',
      logo: '',
      delete_file_ids: [],
    }),
    files: {
      fdd: null,
      item_7: null,
      item_19: null,
      franchisees: null,
      logo: null,
    },
  }),
  computed: {
    ...mapGetters({
      franchise: 'business-v2/franchise',
    }),
    formAttributes() {
      return {
        logo: this.franchise.logo ?? {},
        fdd: this.franchise.files.fdd ?? {},
        item_7: this.franchise.files.item_7 ?? {},
        item_19: this.franchise.files.item_19 ?? {},
        franchisees: this.franchise.files.franchisees ?? {},
      }
    },
  },
  watch: {
    async franchise() {
      this.updateAttributes()
    },
  },
  created() {
    this.updateAttributes()
  },
  methods: {
    ...mapActions({
      fetchFranchise: 'business-v2/fetchFranchise',
    }),
    updateAttributes() {

      const franchise = this.formAttributes;

      Object.keys(franchise).forEach(key => {
        if (this.files.hasOwnProperty(key)) {
          this.files[key] = franchise[key] ?? {}
        }
      })
    },
    async submit() {
      try {
        const url = `/api/v2/businesses/edit/${this.franchise.id}/${this.franchise.version_id}/files`

        await this.form.submit('post', url, {
          headers: {'Content-Type': 'multipart/form-data'},
          transformRequest: [(data) => {
            const formData = new FormData

            Object.keys(data).forEach(key => {
              if (key !== 'delete_file_ids') {
                formData.append(key, data[key] ?? '')
              }
            })

            data.delete_file_ids.forEach(
                (el, i) => formData.append(`delete_file_ids[${i}]`, el)
            );

            return formData;
          }],
        })
        this.$emit('sendSuccess')
        await this.fetchFranchise({
          franchiseId: this.franchise.id,
          versionId: this.franchise.version_id,
        })
      } catch (e) {
        this.$emit('sendError')
        console.log(e);
      }
    },
    addFile(key, value) {
      this.$emit('change')
      this.form[key] = value
    },
    deleteFile(key) {
      this.$emit('change')
      if (this.files[key]
          && this.files[key].id
          && !this.form.delete_file_ids.includes(this.files[key].id)) {
        this.form.delete_file_ids.push(this.files[key].id)
        this.form[key] = ''
      }

      this.files[key] = {}
    },
  }
}
