//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import Notifycations from '@/components/nav/Notifications.vue'
import Avatar from '~/components/nav/Avatar.vue'

export default {
  components: {
    Avatar,
    Notifycations
  },
  computed: mapGetters({
    authenticated: 'auth/check'
  }),
  methods: {
    logout () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/')
      })
    },
    showRegisterModal () {
      this.$store.dispatch('registration/openModal')
    },
    showLoginModal () {
      this.$store.dispatch('login/closeModal')
    }
  }
}
