//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform';
import { mapGetters } from 'vuex';
import ModalContact from '~/components/modals/ModalContact';
import MobileAppStoreButton from '~/components/buttons/mobileAppStoreButton';
import BlogPostsSubscribe from '~/components/blog/BlogPostsSubscribe';
import MenuListTopFranchise from '@/components/menu/MenuListTopFranchise';
import StringMask from 'string-mask';
import { COMPANY_PHONE_NUMBER, MASK_PHONE } from '@/store/constants';

export default {
  name: 'Footer',
  components: {
    MenuListTopFranchise,
    BlogPostsSubscribe,
    ModalContact,
    MobileAppStoreButton,
  },
  data() {
    return {
      formSubscribe: new Form({
        email: null,
      }),
      subscribeEmail: null,
      subscribeEmailClass: null,
      modalSubscribeShow: false,
      regEmail:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      expandedAbout: false,
      isParams: false,
    };
  },

  computed: {
    ...mapGetters({
      showOnMobile: 'footer/show',
    }),
    getUrlPhone() {
      return `tel:${COMPANY_PHONE_NUMBER}`;
    },
    getFormatedPhone() {
      return new StringMask(MASK_PHONE).apply(COMPANY_PHONE_NUMBER);
    },
  },
  methods: {
    async subscribe() {
      if (this.subscribeEmail == null || !this.regEmail.test(this.subscribeEmail)) {
        this.subscribeEmailClass = 'error';
        return null;
      }
      this.subscribeEmailClass = null;
      this.formSubscribe.email = this.subscribeEmail;
      this.formSubscribe.hubspotutk = this.$cookies.get('hubspotutk');
      try {
        await this.formSubscribe.post('/api/v1/other/subscribe-form');
        this.modalSubscribeShow = true;

        try {
          const form = new Form({
            email: this.subscribeEmail,
          });
          await form.post('/api/v1/blog-subscribe/subscribe');
          this.subscribeEmail = null;
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        this.subscribeEmailClass = 'error';
        console.log(e);
      }
    },
    onlyCompanyBlock() {
      const params = this.$route.query;
      const utmCampaignList = ['Franchise123'];
      if (utmCampaignList.includes(params.utm_campaign)) {
        this.isParams = true;
      } else {
        this.isParams = false;
      }
    },
  },
  showFooter() {
    this.$store.dispatch('footer/showSelf');
  },
  hideFooter() {
    this.$store.dispatch('footer/hideSelf');
  },
  nowYear() {
    return new Date().getFullYear();
  },
};
