//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VInput from './VInput'
import Dot from '../icons/Dot'
import VueSlider from 'vue-slider-component'
// import 'vue-slider-component/theme/antd.css'
export default {
  components: {
    VInput,
    VueSlider,
    Dot
  },
  props: {
    title: {
      type: String,
      default: () => 'Title'
    }
  },
  data () {
    return {
      value: [1, 55]
    }
  }
}
