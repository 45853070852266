//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
  mapState,
} from "vuex"
import {cloneDeep} from "lodash/fp"
import BaseButton from "@/components/buttons/BaseButton"
import AutoComplete from "@/components/form/AutoComplete"
import VSelectRangeInputV2 from "@/components/form/VSelectRangeInputV2"
import VSelect from '~/components/form/VSelect'

import {
  SEARCH_OPTIONS_INVESTMENT,
  SEARCH_TYPE_FRANCHISEE,
  SEARCH_TYPE_FRANCHISOR,
} from "~/store/constants"

export default {
  name: 'SearchFranchises',
  components: {
    BaseButton,
    VSelectRangeInputV2,
    AutoComplete,
    VSelect,
  },
  props: {
    navbar: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeFranchisee: SEARCH_TYPE_FRANCHISEE,
      typeFranchisor: SEARCH_TYPE_FRANCHISOR,
      type: SEARCH_TYPE_FRANCHISEE,
      filter: {
        business_name: '',
        industry: '',
        allowed_states_id: '',
        investment: {min: null, max: null},
      },
      extraFilter: {
        investment_select: 0,
      },
      inputs: {
        industry: [],
        locations: [],
        investment: SEARCH_OPTIONS_INVESTMENT,
      },
      isHomePage: false,
    }
  },
  computed: {
    ...mapGetters({
      search: "search/type",
    }),
    ...mapState({
      searchType: state => state.search.type,
      businessFilters: state => state.business.filter,
      statesDirectory: state => state.directories.states,
      industriesDirectory: state => state.directories.industries,
    }),
    investmentSelect() {
      return this.extraFilter.investment_select
    }
  },
  watch: {
    searchType(newVal) {
      this.type = newVal
    },
    investmentSelect(newVal) {
      this.filter.investment.min = 0
      this.filter.investment.max = newVal
    },
    'extraFilter.investment_select': function () {
      this.changeModalSearchClass(this.extraFilter.investment_select)
    },
    'filter.allowed_states_id': function () {
      this.changeModalSearchClass(this.filter.allowed_states_id)
    }
  },
  async mounted() {
    // Check that we on home page
    this.isHomePage = this.$route.path.length <= 1

    // set block type
    this.type = this.searchType

    // get filter values from store
    this.filter = cloneDeep(this.businessFilters)

    // collect industries
    await this.fetchIndustries()
    this.inputs.industry = this.industriesDirectory

    // collect states
    await this.fetchStates()
    this.statesDirectory.forEach(el => {
      this.inputs.locations.push({
        title: `${el.name}, ${el.code}`,
        value: el.id
      })
    })

    // update investment field for modal (mobile) input
    if (this.modal) {
      if (this.filter.investment.max) {
        this.extraFilter.investment_select = this.filter.investment.max
      }
    }
  },
  beforeDestroy() {
    if (!this.modal) {
      this.$store.dispatch('search/setType', SEARCH_TYPE_FRANCHISEE)
    }
  },
  methods: {
    ...mapActions({
      fetchStates: "directories/fetchStates",
      fetchIndustries: "directories/fetchIndustries",
      setRestoreIndustryFilter: "directories/setRestoreIndustryFilter",
    }),
    changeModalSearchClass(vModal) {
      const element = document.getElementsByClassName('body-franchises-search')
      if (element[0]) {
        const parent = element[0].classList
        parent.add('add-spaces')
        if (vModal !== null) {
          setTimeout(() => {
            parent.remove('add-spaces')
          }, 300)
        }
      }
    },
    setFilterItem(key, value) {
      this.$store.commit("business/SET_FILTER_ITEM", {
        key,
        value
      })
    },
    setFilter() {
      this.setRestoreIndustryFilter(false)
      this.$store.commit("business/SET_MAIN_FILTER_PAGINATION", {current_page: 1})
      Object.keys(this.filter).forEach((item) => {
        this.setFilterItem(item, this.filter[item])
      })
      if (this.$route.path !== '/franchise-directory') {
        this.$router.push('/franchise-directory')
      } else {
        this.$router.push('/')
        setTimeout(() => {
          this.$router.push('/franchise-directory')
        }, 100)
      }
      this.$store.commit('business/SET_CLOSE_FRANCHISE_NAV_MODAL', true)
    },
  },
}
