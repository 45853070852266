import Form from "vform";
import {mapGetters} from "vuex";

export default {
  computed:{
    ...mapGetters({
      franchise: "landing/franchise"
    }),
  },
  methods: {
    async saveFile( file, fileType) {
      const form = new Form()
      const blobBin = atob(file.split(",")[1]);
      const array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      form.image = new Blob([new Uint8Array(array)], {type: fileType});

      try {
        const url = `api/v2/landing/${this.franchise.franchiseId}/${this.franchise.versionId}/image`;
        this.$toast.info("Upload file");
        const {data} = await form.submit("post", url, {
          headers: {"Content-Type": "multipart/form-data"},
          transformRequest: [(dataArr) => {
            const formData = new FormData;
            formData.append(`image`, dataArr.image);
            return formData;
          }],
        });
        return data.result.id
      } catch (e) {
        console.log(e);
        return false
      }
    },
  }
}
