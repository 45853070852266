//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {BTable} from 'bootstrap-vue'
import scrollMixin from '@/components/mixins/scrollMixin'
import FranchiseeMatchesCard from '@/components/cards/FranchiseeMatchesCard'
import TrashBinIcon from '@/assets/images/svg/trash-bin.svg?inline'
import TrashBinOpenIcon from '@/assets/images/svg/trash-bin-open.svg?inline'
import BaseButtonV2 from '@/components/buttons/BaseButtonV2'
import RemoveFranchiseReasonModal from '@/components/modals/Account/RemoveFranchiseReasonModal'
import VisualSteps from '@/components/steppers/VisualSteps'
import {mapActions} from "vuex"

export default {
  components: {
    FranchiseeMatchesCard,
    VisualSteps,
    RemoveFranchiseReasonModal,
    BaseButtonV2,
    BTable,
    TrashBinIcon,
    TrashBinOpenIcon,
  },
  mixins: [scrollMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    itemsTotal: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    itemsCurrentPage: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showTrashIcons: false,
      removeModalShow: false,
      chosenTableRow: null,
      clientStepsTrashReasons: [],
      stepsItemGap: 0,
      stepsItemWidth: 0,
      confirmedBusy: false,
    }
  },
  computed: {
    removeFranchiseText() {
      if (!this.showTrashIcons) {
        return 'Remove franchise'
      }
      return 'Cancel'
    },
    removeFranchiseButtonVariant() {
      if (!this.showTrashIcons) {
        return 'danger'
      }
      return 'success'
    },
  },
  mounted() {
    const {width, marginRight} = this.getMatchesCardWidthAndGap()
    this.stepsItemWidth = width
    this.stepsItemGap = marginRight
  },
  methods: {
    ...mapActions({
      fetchProgressList: 'account/fetchProgressList',
      setUserClick: 'account/setUserClick',
    }),
    /**
     * Toggle trash bin icons each records in table
     */
    toggleTrashBinIcons() {
      const tableMatchesWrapper = document.querySelector('.table-matches')
      if (!this.showTrashIcons) {
        this.showTrashIcons = !this.showTrashIcons
        tableMatchesWrapper.scroll({
          top: 0,
          left: tableMatchesWrapper.scrollLeft + tableMatchesWrapper.scrollWidth,
          behavior: 'smooth'
        })
      } else {
        tableMatchesWrapper.scroll({
          top: 0,
          left: tableMatchesWrapper.scrollLeft - tableMatchesWrapper.scrollWidth,
          behavior: 'smooth'
        })
        this.showTrashIcons = !this.showTrashIcons
      }
    },
    /**
     * Click trash bin handler
     */
    async handleClickRemove(row) {
      this.chosenTableRow = row

      // fetch reasons
      if (!this.clientStepsTrashReasons.length) {
        try {
          const {data} = await this.$axios.get(`/api/v1/client-steps-trash-reasons`)
          this.clientStepsTrashReasons = data.map((value) => ({...value, checked: false}))
        } catch (e) {
          console.error(e)
          return
        }
      }
      this.removeModalShow = true
    },
    /**
     * Click confirm button in remove reasons modal
     * @param {array} checkedReasonsIds
     * @param {array} customReasonText
     * @returns {boolean} business is remove from steps
     */
    handleClickConfirmReasonModal({checkedReasonsIds, customReasonText}) {
      this.sendToTrashBin(checkedReasonsIds, customReasonText)
    },
    async sendToTrashBin(checkedReasonsIds, customReasonText) {
      const {index, item} = this.chosenTableRow
      this.confirmedBusy = true
      try {
        await this.setUserClick(true)
        await this.$axios.post(`/api/v1/client-steps-trash/${item.franchise_id}`, {
          reason_ids: checkedReasonsIds,
          reason_text: customReasonText,
        })
        this.$emit('removed')
        this.removeModalShow = false
        this.clientStepsTrashReasons = this.clientStepsTrashReasons.map((value) => ({...value, checked: false}))
        await this.$store.dispatch('business/fetchProgress')
        this.toggleTrashBinIcons()
        await this.fetchProgressList()
      } catch (e) {
        console.log(e)
      }
      this.confirmedBusy = false
    },
    /**
     * Get matches-card responsive width & gap
     * @returns {Object}
     */
    getMatchesCardWidthAndGap() {
      const matchesCard = document.querySelector('.franchisee-matches-card')
      const matchesCardStyle = getComputedStyle(matchesCard)
      const width = parseInt(matchesCardStyle.width, 10)
      const marginRight = parseInt(matchesCardStyle.marginRight, 10)
      return {
        width,
        marginRight,
      }
    },
  },
}
