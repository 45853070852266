//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Timeline} from 'vue-tweet-embed';
import Loader from "@/components/Loader";

export default {
  name: "TweetList",
  components: {Loader, Timeline},
  props: {
    twitterAccount: {
      type: String,
      default: 'Franchise1_2_3',
    }
  },
  data: () => ({
    tweetParams: {
      tweetLimit: '4',
      chrome: [
        'noheader',
        'nofooter',
        'noborders',
        'transparent'
      ],
      height: 700
    },
    destroy:false,
  }),
  computed: {
    getUserName() {
      return !this.destroy? this.twitterAccount.replace('@', ''):''
    }
  },
  destroyed() {
      this.destroy = true
  },
}
