//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import helpers from '@/components/mixins/helpers';
import ModalText from '@/components/modals/ModalText';
import { BTable } from 'bootstrap-vue';
import MoneyFormat from 'vue-money-format';
import { mapGetters } from 'vuex';
import routerMixin from '@/components/mixins/routerMixin';
import { EVENT_COMPARE } from '~/store/constants';
import storeMixin from '../mixins/storeMixin';

export default {
  components: {
    ModalText,
    BTable,
    MoneyFormat,
  },
  mixins: [routerMixin, storeMixin, helpers],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: '',
      EVENT_COMPARE,
      modalSignedInMsgShow: false,
      SignedInMsg:
        'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please  use another email to sign up as a Franchisee.',
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      pagination: 'business/pagination',
    }),
  },
  methods: {
    indexRow(index) {
      const newIndex = index + 1;
      if (this.pagination.current_page > 1)
        return newIndex + (this.pagination.current_page - 1) * this.pagination.per_page;

      if (index < 9) return `0${newIndex}`;

      return newIndex;
    },
    sortingChanged(e) {
      this.$emit('sorting-changed', e);
    },
    async showFile(item) {
      if (this.authenticated) {
        try {
          this.user.isFranchisee
            ? await this.$store.dispatch('iframe/openModal', item.files.fdd)
            : (this.modalSignedInMsgShow = true);
        } catch (e) {
          await this.$store.dispatch('iframe/closeModal');
        }
      } else {
        await this.showFranchiseeFastRegistration(item, true);
      }
    },
    async toggleCompare(item) {
      if (this.authenticated) {
        try {
          this.user.isFranchisee
            ? await this.$store.dispatch('business/toggleComparison', item)
            : (this.modalSignedInMsgShow = true);
        } catch (e) {}
      } else {
        await this.showFranchiseeFastRegistration(item);
      }
    },
  },
};
