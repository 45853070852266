//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import AutoComplete from 'vuejs-auto-complete'

export default {
  name: "AutoCompleteCustom",
  components: {AutoComplete},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    placeholder: {
      type: String,
      default: 'Search'
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    topLabel: {
      type: String,
      default: ''
    },
    initialValue: {
      type: String,
      default: null
    },
    initialDisplay: {
      type: String,
      default: null
    },
    source: {
      type: [String, Function],
      required: true
    },
    method: {
      type: String,
      default: 'get'
    },
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  methods: {
    responseFormatter(response) {
      return response.result
    },
    formattedDisplay(result) {
      return result.name
    },
    selectEntity(item) {
      const cityData = {
        id: item.value,
        name: item.display
      }

      this.$emit('change', cityData)
    },
    clearSelect() {
      const data = {
        id: '',
        name: ''
      }

      this.$emit('change', data)
    },
  }
}
