//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PhotoIcon from "../icons/PhotoIcon";

export default {
  components: {
    PhotoIcon
  },
  props: {
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/svg+xml'
    },
    placeholder: {
      type: String,
      default: 'Upload Image'
    },
    deleteButtonText: {
      type: String,
      default: 'Clear'
    },
    preview: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      file: '',
      imagePreview: ''
    }
  },
  mounted () {
    ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function(event) {
      this.$refs.dragAndDropInput.addEventListener(event, function(e) {
        e.preventDefault();
        e.stopPropagation();
      }.bind(this), false);
    }.bind(this));

    this.$refs.dragAndDropInput.addEventListener('drop', function(e) {
      if (this.file === '') {
        this.handleFileUpload(e)
      }
    }.bind(this));

    if (this.preview) {
      this.imagePreview = this.preview
    }
  },
  methods: {
    handleFileUpload (e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        this.file = files[0]
        this.createPreview(files[0])
      } else {
        this.deleteImage()
      }

      this.$emit('change', this.file)
    },
    createPreview (file) {
      let reader = new FileReader();

      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    deleteImage () {
      this.imagePreview = ''
      this.file = ''

      this.$emit('change', this.file)
    },
    reset() {
      this.imagePreview = ''
      this.file = ''
    }
  }
}
