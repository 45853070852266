//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import BlockQuoteIcon from "@/assets/icons/landing/BlockQuoteIcon.svg?inline"
import {directive, Swiper, SwiperSlide} from 'vue-awesome-swiper'
import ArrowCodeIcon from "@/assets/icons/arrows/ArrowCodeIcon.svg?inline";
import SectionTools from "@/components/landing/elements/SectionTools";
import LandingText from "@/components/landing/elements/LandingText";


export default {
  name: "LandingSectionComments",
  components: {
    LandingText,
    SectionTools,
    ArrowCodeIcon,
    BlockQuoteIcon,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive
  },
  mixins: [
    landingStyles
  ],
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    maxIndex: {
      type: Number,
      default: 0
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data:()=>{
    return{
      showButtonNext: false,
      showButtonPrev: false,
      showTools:false,
      containerSize: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    swiperOption() {
      return {
        init:false,
        updateOnWindowResize: true,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        breakpoints: {
          '768': {
            slidesPerView: 2,
          },
          '1200': {
            slidesPerView: 3,
          },
        }
      }
    },
    backgroundImage(){
      const imageId = this.section.styles.backgroundImage ?? ''

      return (imageId === '')? '' :`${process.env.BACKEND_BASE_URL}api/v2/landing/${imageId}/image`
    },
  },
  mounted() {
    this.$refs.commentsSlider.$swiper.init()
    if (this.editable)
      this.updateSectionSizes()
  },
  methods: {
    updateSectionSizes(){
      this.containerSize.width = this.$refs[`container_${this.section.id}`].clientWidth
      this.containerSize.height = this.$refs[`section_${this.section.id}`].clientHeight
    },
    onSliderInit(swiper) {
      if (!this.editable) {
        this.showButtonNext = true
      }
      this.onSlideChange(swiper)
    },
    onSlideChange(swiper) {
      this.showButtonNext = !swiper.isEnd
      this.showButtonPrev = !swiper.isBeginning
    },
    changeSlide(prev = false) {
      if (prev)
        this.$refs.commentsSlider.$swiper.slidePrev()
      else
        this.$refs.commentsSlider.$swiper.slideNext()
    },
    updateSection() {
      this.updateSectionSizes()
      this.$store.dispatch('landing/updateSection', {section: this.section, index: this.currentIndex})
    },
  },
}
