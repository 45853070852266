//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep';
import {WORK_BOOK, WORK_BOOK_TOP_LIST_KEYS} from "@/store/constants";
import {mapGetters} from "vuex";

export default {
  name: "WorkBookChapterScore",
  props: {
    numberTop: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex]
    },
    listCountedBlock() {
      const currentChapter = cloneDeep(WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex])
      return currentChapter.questionsBlocks.filter((block) => block.fields.findIndex(field => typeof field.isScore !== "undefined") !== -1)
    },
  },
  methods: {
    getFranchiseName(franchiseIndex) {
      let listIndex = WORK_BOOK_TOP_LIST_KEYS.TEN

      if (this.numberTop === 5)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.FIVE

      if (this.numberTop === 3)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.THREE

      if (this.numberTop === 2)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.TWO

      if (typeof this.topList[listIndex] === "undefined")
        return ''

      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(this.topList[listIndex][franchiseIndex]))

      if (typeof currentFranchise === "undefined")
        return ``

      return currentFranchise.franchise

    },
    getTotalValue(indexFranchise){
      let sum = 0

      this.listCountedBlock.forEach((block,blockIndex) =>{
        const fieldWithScore = block.fields.find((field) => {
          if (typeof field.isScore !== "undefined")
            return field.slug
          return false
        })

        if (fieldWithScore)
          sum += Number(this.currentChapterAnswers.questionsBlocks[blockIndex].answers[indexFranchise].value[fieldWithScore.slug] ?? 0)
      })

      return sum
    }
  },
}
