//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    backgroundColor: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      showMenuList: false,
    };
  },
  methods: {
    onClickOutside() {
      this.showMenuList = false;
    },
    clickApprove() {
      this.$emit('approve')
    },
    clickOpen() {
      this.$emit('open')
    }
  },
};
