//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import helpers from "@/components/mixins/helpers"
import RatingStars from "@/components/RatingStars"
import MoneyFormat from 'vue-money-format'
import VClamp from 'vue-clamp'
import FddIcon from '@/components/icons/FddIcon'
import LikeIconV4 from '@/components/icons/LikeIconV4'
import routerMixin from '@/components/mixins/routerMixin'
import storeMixin from '@/components/mixins/storeMixin'
import hubspotMixin from '@/components/mixins/hubspotMixin'
import ModalText from "@/components/modals/ModalText"
import AdminButton from "../buttons/AdminButton"

import {
  EVENT_COMPARE,
  EVENT_DOWNLOAD_FDD,
  EVENT_LIKE,
  MODAL_SIGN_UP_FRANCHISE_ID,
  HUBSPOT_EVENT_TYPES,
} from '~/store/constants'

export default {
  name: 'DirectoryCard',
  components: {
    RatingStars,
    ModalText,
    AdminButton,
    LikeIconV4,
    FddIcon,
    VClamp,
    MoneyFormat,
  },
  mixins: [
    routerMixin,
    storeMixin,
    hubspotMixin,
    helpers,
  ],
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    liked: {
      type: Boolean,
      default: () => false,
    },
    isBlocked: {
      type: Boolean,
      default: false,
    },
    forFavoriteList: {
      type: Boolean,
      default: false
    },
    isLight: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    franchise: {},
    EVENT_LIKE,
    EVENT_DOWNLOAD_FDD,
    EVENT_COMPARE,
    busyBeginToday: false,
    likeBusy: false,
    fddPreviewModalState: false,
    SignedInMsg:
      'You are signed in as a franchisor. You need to sign in as a franchisee to buy a franchise. If you wish to buy this franchise please sign out and try again.',
    modalSignedInMsgShow: false,
    to: '',
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      inProgressBusinessClientStep: 'franchisee-steps/inProgressBusinessClientStep',
      paginationCompare: 'account/paginationCompare',
      paginationProgress: 'account/paginationProgress',
      busyUpdateStatusComparisonFranchise: 'account/busyUpdateStatusComparisonFranchise',
      busyUpdateStatusProgressFranchise: 'account/busyUpdateStatusProgressFranchise',
      busyUpdateStatusLikeFranchise: 'account/busyUpdateStatusLikeFranchise',
      pendingListArr: 'account/pendingListArr'
    }),
    checkStatusCompare() {
      return this.franchise.in_comparison
    },
    checkStatusCompareBusy() {
      return this.busyUpdateStatusComparisonFranchise === this.franchise.franchise_id
    },
    checkStatusProgress() {
      if (this.isBlocked)
        return false

      return this.franchise.flow_status !== 'flow_not_started'
    },
    checkStatusProgressBusy() {
      return this.busyUpdateStatusProgressFranchise === this.franchise.franchise_id
    },
    getProgressLabel() {
      let text = 'Request Info'

      if (this.isBlocked)
        return text

      if (this.inPendingList)
        text = 'Request Made'

      if (!this.authenticated)
        return text

      if (!this.user.isFranchisee)
        return 'Begin Today'

      text = 'Begin Today'

      if (this.franchise.flow_status === 'flow_in_progress')
        text = 'In Progress'

      if (this.franchise.flow_status === 'flow_closed')
        text = 'Closed'

      return text
    },
    checkStatusLike() {
      return this.franchise.like
    },
    checkStatusLikeBusy() {
      return this.busyUpdateStatusLikeFranchise === this.franchise.franchise_id
    },
    getLocationsValue() {
      return this.franchise.number_of_locations ? this.franchise.number_of_locations : 'N/A'
    },
    checkMembership() {
      if (!this.franchise.membership)
        return false

      return this.franchise.membership.toUpperCase() === 'GOLD' || this.franchise.membership.toUpperCase() === 'BUSINESS'
    },
    checkValueIframe() {
      return this.franchise.files.fdd === null
    },
    currentRating() {
      return this.franchise.survey_ratings.length > 0 ? this.franchise.survey_ratings : this.franchise.self_ratings
    },
    arithmeticMeanRating() {
      const rating = this.currentRating
      const meanRating = rating.reduce((total, item) => {
        return total + parseFloat(item.rating_value)
      }, 0) / rating.length

      return Math.ceil(meanRating)
    },
    shortDescription() {
      return this.franchise.company_short_description ?? this.franchise.short_descr
    },
    inPendingList(){
      const findIndex = this.pendingListArr.findIndex(item => Number(item) === Number(this.franchise.franchise_id))
      return findIndex >= 0
    }
  },
  watch: {
    item(newVal) {
      this.franchise = cloneDeep(newVal)
    },
  },
  created() {
    this.franchise = cloneDeep(this.item)
  },
  methods: {
    ...mapActions({
      fetchComparisonsList: 'account/fetchComparisonsList',
      fetchLikeList: 'account/fetchLikeList',
      fetchProgressList: 'account/fetchProgressList',
      updateComparisonStatusFranchise: 'account/updateComparisonStatusFranchise',
      updateProgressStatusFranchise: 'account/updateProgressStatusFranchise',
      updateLikeStatusFranchise: 'account/updateLikeStatusFranchise',
      setUserClick: 'account/setUserClick',
      updatePendingList: 'account/updatePendingList'
    }),
    async handlerButtonCompare() {
      if (this.isBlocked)
        return

      if (!this.authenticated) {
        this.updateFranchisePendigList()
        await this.showFranchiseeFastRegistration(this.franchise, false)
        return
      }

      if (!this.user.isFranchisee) {
        this.modalSignedInMsgShow = true
        return
      }
      await this.setUserClick(true)
      this.franchise.in_comparison = !this.franchise.in_comparison
      await this.updateComparisonStatusFranchise(this.franchise.franchise_id)
    },
    updateFranchisePendigList() {
      if (!this.authenticated && !this.inPendingList) {
        const listArr = cloneDeep(this.pendingListArr)
        listArr.push(this.franchise.franchise_id)
        this.updatePendingList(listArr)
        return true
      }

      return false
    },
    async handlerOpenFile() {
      this.handlerButtonProgress(false)
      await this.openIframe()
    },
    async handlerButtonProgress(isRedirect = true) {
      if (this.isBlocked)
        return

      if (!this.authenticated) {
        this.updateFranchisePendigList()
        await this.showFranchiseeFastRegistration(this.franchise, false)
        return
      }

      if (!this.user.isFranchisee) {
        this.modalSignedInMsgShow = true
        return
      }

      if (isRedirect && this.franchise.flow_status !== 'flow_not_started') {
        await this.$router.push(`/account/dashboard`)
      }

      if (this.franchise.flow_status !== 'flow_not_started') {
        return
      }

      await this.setUserClick(true)
      this.franchise.flow_status = 'flow_in_progress'
      await this.updateProgressStatusFranchise(this.franchise)

      await this.hsCustomEvent(this.franchise.franchise_id, 'begin-today')
    },
    async handlerButtonLike() {
      if (this.isBlocked)
        return

      if (!this.authenticated) {
        this.updateFranchisePendigList()
        await this.showFranchiseeFastRegistration(this.franchise, false)
        return
      }

      if (!this.user.isFranchisee) {
        this.modalSignedInMsgShow = true
        return
      }

      this.franchise.like = !this.franchise.like
      await this.setUserClick(true)
      await this.updateLikeStatusFranchise(this.franchise.franchise_id)
    },
    async openIframe() {
      if (this.isBlocked)
        return

      if (!this.authenticated) {
        await this.showFranchiseeFastRegistration(this.franchise, true)
        return
      }

      if (this.user.isFranchisee) {
        await this.hsCustomEvent(this.franchise.franchise_id, HUBSPOT_EVENT_TYPES.openFullFDD)
        await this.$store.dispatch('iframe/openModal', this.franchise.files.fdd.url)
      } else {
        (this.modalSignedInMsgShow = true)
      }
    },
    async showFranchiseeFastRegistration(item = null, showFdd = false) {
      this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
      await this.$store.commit('account/SET_SELECTED_FRANCHISE', item)
      await this.$store.commit('account/SET_SHOW_FDD', showFdd)
    },
  },
}
