//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/Loader"
import {mapActions, mapGetters} from 'vuex'
import VSymbolIcon from "@/components/icons/VSymbolIcon"
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CardIndustriesCobalt',
  components: {
    Loader,
    VSymbolIcon,
  },
  props: {
    isBlocked: {
      type: Boolean,
      default: false
    },
    maxChecked: {
      type: Number,
      default: 0,
    },
    hideCounter: {
      type: Boolean,
      default: false
    },
    overlayColor: {
      type: String,
      default: '#f8f8fa'
    },
  },
  data: () => ({
    industries: [],
    activeIndustries: [],
  }),
  computed: {
    ...mapGetters({
      industriesDirectory: 'directories/industries',
      preferencesIndustries: 'account/preferencesIndustries',
      busy: 'directories/busyFetchIndustry',
    }),
  },
  mounted() {
    this.fetchIndustries()
  },
  methods: {
    ...mapActions({
      fetchIndustries: 'directories/fetchIndustries',
      updatePreferencesIndustries: 'account/updatePreferencesIndustries',
    }),
    selectIndustry(industryTitle) {
      if (this.isBlocked)
        return

      const findIndex = this.preferencesIndustries.findIndex(item => item === industryTitle)
      const industryArr = cloneDeep(this.preferencesIndustries)

      if (findIndex === -1) {
        industryArr.push(industryTitle)
      } else {
        industryArr.splice(findIndex, 1)
      }
      if (industryArr.length > this.maxChecked)
        return

      this.updatePreferencesIndustries(industryArr)
    },
    checkIsActive(industryTitle) {
      return this.preferencesIndustries.findIndex(item => item === industryTitle) !== -1
    },
    checkIsDisabled(industryTitle) {
      return (this.preferencesIndustries.findIndex(item => item === industryTitle) === -1) &&
        this.preferencesIndustries.length >= this.maxChecked
    },
  },
}
