//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClearIcon from "@/components/icons/ClearIcon";
import {mapActions, mapGetters} from "vuex";
import { MODAL_MESSAGE_ID } from "~/store/constants";

export default {
  name: "ModalMessage",
  components: {
    ClearIcon
  },
  data: () => ({
    MODAL_MESSAGE_ID
  }),
  computed: {
    ...mapGetters({
      message: "modal-message/message",
      messageArray: "modal-message/messageArray",
      messageArrayAsNumberList: "modal-message/messageArrayAsNumberList",
    }),
  },
  methods: {
    ...mapActions({
      resetModal: "modal-message/resetModal"
    }),
    async handleClick(e) {
      if (e.target.matches('.logout')) {
        await this.$store.dispatch('auth/logout');
        window.location.href = '/';
      }
    },
    clearModal() {
      this.resetModal()
    }
  }
}
