//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import {mapGetters} from "vuex";

export default {
  name: "LandingShare",
  mixins: [
    landingStyles,
  ],
  data:()=>{
    return{
      socialButtons:[
        {
          icon: 'facebook',
          url: 'https://www.facebook.com/Franchise123-106636864432720',
        },
        {
          icon: 'twitter',
          url: 'https://twitter.com/franchise1_2_3',
        },
        {
          icon: 'linkedin',
          url: 'https://www.linkedin.com/company/franchise123',
        },
        {
          icon: 'instagram',
          url: 'https://www.instagram.com/franchise1_2_3/',
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      franchise: 'landing/franchise',
      mainStyles: 'landing/main_styles',
    }),
    shareUrl() {
      let url = ''
      if (process.client)
        url = document.location.href;
      return url
    }
  }
}
