//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AbstractPagination from "@/components/paginations/AbstractPagination";

export default {
  components: {AbstractPagination},
  props: {
    pagination: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    changePage(pageNumber) {
      this.$emit('changePage', pageNumber);
    },
  },
};
