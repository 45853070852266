//
//
//
//
//
//
//
//

export default {
  name: 'LearnMore',
  props: {
    title: { type: String, default: 'Learn more' },
    url: { type: String, default: '#' }
  }
}
