//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogPostImage from "@/components/blog/BlogPostImage"

export default {
  name: 'BlogPostsListItem',
  components: {
    BlogPostImage,
  },
  props: {
    released: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    externalLink: {
      type: String,
      default: '',
    },
    externalAuthor: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    logo: {
      type: String,
      default: '',
    },
    created: {
      type: String,
      default: '',
    },
    categories: {
      type: Array,
      default: null,
    },
    content: {
      default: '',
    },
    videoEmbedUrl: {
      type: String,
      default: ''
    },
  },
  computed: {
    authorName() {
      if (this.externalLink) {
        return this.externalAuthor ?? ''
      }
      return this.author
    },
  },
  methods: {
    async routeHandler(externalLink) {
      if (externalLink) {
        window.open(externalLink, '_blank')
      }
    },
    getPostEvent(externalLink) {
      return externalLink ? '' : 'click'
    },
  }
}
