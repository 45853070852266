//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import BaseModal from '~/components/modals/BaseModal'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    BaseModal,
    Loading
  },
  data: () => ({
    isLoading: true,
    iframeSrc: null
  }),
  computed: {
    ...mapState({
      src: state => state.iframe.src !== '' ? state.iframe.src : '',
      show: state => state.iframe.show,
      tag: state => state.iframe.tag,
    })
  },
  watch: {
    show (val, newVal) {
      this.isLoading = true
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('iframe/closeModal')
    },
    fileLoad (event) {
      this.isLoading = false

      event.target.contentWindow.document.oncontextmenu = function () {
        return false
      }
    }
  }
}
