import { render, staticRenderFns } from "./LandingSectionStats.vue?vue&type=template&id=2d0c5e4b&scoped=true&"
import script from "./LandingSectionStats.vue?vue&type=script&lang=js&"
export * from "./LandingSectionStats.vue?vue&type=script&lang=js&"
import style0 from "./LandingSectionStats.vue?vue&type=style&index=0&id=2d0c5e4b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0c5e4b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingText: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingText.vue').default})
