//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/Loader";
import {mapActions, mapGetters, mapState} from "vuex";
import VSymbolIcon from "@/components/icons/VSymbolIcon";
import {cloneDeep} from 'lodash/fp'

export default {
  name: "CardUSMapCobalt",
  components: {Loader, VSymbolIcon},
  props: {
    maxChecked: {
      type: Number,
      default: 0
    },
    isBlocked: {
      type: Boolean,
      default: false
    },
    hideCounter:{
      type:Boolean,
      default:false
    },
    overlayColor:{
      type:String,
      default:'#f8f8fa'
    },
  },
  data: () => ({
    mobileMap: window.innerWidth <= 991,
  }),
  computed: {
    ...mapGetters({
      statesDirectory: 'directories/states',
      preferencesStates: 'account/preferencesStates',
      busy: 'directories/busyFetchStates',
    })
  },
  created() {
    window.addEventListener('resize', () => {
      this.mobileMap = window.innerWidth <= 991
    })
  },
  mounted() {
    this.fetchStates()
  },
  methods: {
    ...mapActions({
      fetchStates: 'directories/fetchStates',
      updatePreferencesStates: 'account/updatePreferencesStates',
    }),
    selectState(stateCode) {
      if (this.isBlocked)
        return
      const stateObj = this.statesDirectory.find(item => item.code === stateCode)
      const findIndex = this.preferencesStates.findIndex(item => item === stateObj.id)
      const stateArr = cloneDeep(this.preferencesStates)

      if (findIndex === -1) {
        stateArr.push(stateObj.id)
      } else {
        stateArr.splice(findIndex, 1)
      }
      if (stateArr.length > this.maxChecked)
        return

      this.updatePreferencesStates(stateArr)
    },
    checkIsActive(stateCode) {
      if (!this.statesDirectory.length)
        return false

      const stateObj = this.statesDirectory.find(item => item.code === stateCode)

      return this.preferencesStates.findIndex(item => item === stateObj.id) !== -1
    },
    checkIsDisabled(stateCode) {
      if (!this.statesDirectory.length)
        return false

      const stateObj = this.statesDirectory.find(item => item.code === stateCode)

      return (this.preferencesStates.findIndex(item => item === stateObj.id) === -1) &&
        this.preferencesStates.length >= this.maxChecked
    },
  }
}
