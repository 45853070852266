//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    active: {
      type: [Boolean, Number],
      default: () => false
    }
  }
}
