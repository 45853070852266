//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import VInputV2 from "@/components/form/VInputV2";
import Form from 'vform';
import VButton from "@/components/VButton";
import BaseModalV2 from '~/components/modals/BaseModalV2';
import VFormFileInput from '~/components/form/VFormFileInput';

const DOCUMENT_TYPE_FILE = 'file';
const DOCUMENT_TYPE_LINK = 'link';

export default {
  components: {
    VButton,
    VInputV2,
    BaseModalV2,
    VFormFileInput,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    businessStepId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      form: new Form(),
      DOCUMENT_TYPE_FILE,
      DOCUMENT_TYPE_LINK,
      documentUploadLoading: false,
      chosenType: 'file',
      chosenSubtype: 'none',
      chosenFile: null,
      chosenLink: '',
      chosenLinkShortName: '',
    };
  },
  computed: {
    ...mapGetters({
      subtypes: 'documents/subtypes'
    })
  },
  methods: {
    /**
     * File area change handler
     * @param {File} file
     */
    handleFileChose(file) {
      this.chosenFile = file;
    },
    /**
     * File selection listener
     */
    async saveDocument() {
      try {
        this.$toast.info('The data started to go')

        await this.form.submit(
            'post',
            `/api/v1/business-step-docs/${this.businessStepId}`,
            {
              headers: {'Content-Type': 'multipart/form-data'},
              transformRequest: [() => {
                const formData = new FormData
                formData.append('subtype', this.chosenSubtype);

                if (this.chosenType === this.DOCUMENT_TYPE_LINK) {
                  formData.append('link', this.chosenLink);
                  formData.append('link_short_name', this.chosenLinkShortName);
                }

                if (this.chosenType === this.DOCUMENT_TYPE_FILE)
                  formData.append(`files[]`, this.chosenFile);

                return formData;
              }],
            }
        )

        this.$toast.success('Success');
        this.$emit('add');
        this.chosenSubtype = 'none'
        this.chosenType = 'file'
        this.chosenFile = null
        this.chosenLinkShortName = ''
        this.chosenLink = ''
      } catch (err) {
        this.$toast.error("Something went wrong")
        console.log(err);
      }
    },
  },
};
