//import {loadStripe} from "@stripe/stripe-js";
import Form from "vform";
import {mapGetters} from "vuex";

export default {
  data(){
    return {
      formSend: new Form({
        firstname: null,
        lastname: null,
        email: null,
        franchisor_subscription: null,
        page_uri: null
      }),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
    }),
  },
  methods: {
    async provideDetails() {
      this.formSend.firstname = this.user.first_name;
      this.formSend.lastname = this.user.last_name;
      this.formSend.email = this.user.email;
      this.formSend.franchisor_subscription = '';
      this.formSend.page_uri =  document.location.protocol + '//' + document.location.host + '/account-franchisor';

      try {
        const { data } = await this.formSend.post('/api/v1/payment');
        let checkoutSessionId = data.result.checkoutSessionId;

        const stripe = await loadStripe(process.env.STRIPE_API_KEY);
        /*await stripe.redirectToCheckout({
          sessionId: checkoutSessionId
        })*/

      } catch (e) {
        console.log(e);
      }
    },
  }
}
