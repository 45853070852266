//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DirectoryCard from "@/components/cards/DirectoryCard";
import LikeIconV3 from "@/components/icons/LikeIconV3";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DashboardFranchiseeFavorite",
  components: {LikeIconV3, DirectoryCard},
  props:{
    isBlocked:{
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      pagination: 'account/paginationLike',
      listFranchiseLike: 'account/listFranchiseLike',
    }),
  },
  watch: {
    listFranchiseLike() {
      this.setShowPreloader(false)
    },
  },
  methods: {
    ...mapActions({
      setShowPreloader: 'account/setShowPreloader',
      updateLikeCurrentPage: 'account/updateLikeCurrentPage',
      fetchLikeList: 'account/fetchLikeList',
    }),
    async handleChangePage(pageNumber) {
      const path = this.$route.path
      await this.updateLikeCurrentPage(pageNumber)

      if (!this.isBlocked)
        await this.$router.push(`${path}?page=${pageNumber}`)

      await this.fetchLikeList()
    },
  }
}
