//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WorkBookDisabledMessage from "@/components/workbook/elements/WorkBookDisabledMessage";
import WorkBookAddFranchise from "@/components/workbook/WorkBookAddFranchise";
import WorkBookAddFranchiseCustomer from "@/components/workbook/WorkBookAddFranchiseCustomer";
import WorkBookCultureTraits from "@/components/workbook/WorkBookCultureTraits";
import WorkBookBusinessAttributes from "@/components/workbook/WorkBookBusinessAttributes"
import WorkBookCandidates from "@/components/workbook/WorkBookCandidates"
import WorkBookCompetitiveAnalysis from "@/components/workbook/WorkBookCompetitiveAnalysis"
import WorkBookCongratulations from "@/components/workbook/WorkBookCongratulations"
import WorkBookMarketingToolkit from "@/components/workbook/WorkBookMarketingToolkit"
import WorkBookPersonalPreferences from "@/components/workbook/WorkBookPersonalPreferences"
import WorkBookQuestionsClosedWithSelect from "@/components/workbook/WorkBookQuestionsClosedWithSelect";
import WorkBookQuestionsOpenAdditional from "@/components/workbook/WorkBookQuestionsOpenAdditional";
import WorkBookQuestionsWithChoice from "@/components/workbook/WorkBookQuestionsWithChoice"
import WorkBookTableTotalTopFive from "@/components/workbook/WorkBookTableTotalTopFive";
import {mapActions, mapGetters} from "vuex"
import {
  WORK_BOOK,
  WORK_BOOK_QUESTION_TYPES,
  WORK_BOOK_TOP_LIST_KEYS,
  WORK_BOOK_TAB_TYPE,
  WORK_BOOK_CHAPTER_RULES,
  WORK_BOOK_CHAPTER_RULE_TYPES, WORK_BOOK_CHAPTER_STATUS,
} from '@/store/constants'

import WorkBookAdditionalQuestions from "@/components/workbook/WorkBookAdditionalQuestions"
import WorkBookChapterScore from "@/components/workbook/WorkBookChapterScore"
import WorkBookChapterScoreFull from "@/components/workbook/WorkBookChapterScoreFull"
import WorkBookInfoPerformanceIndicators from "@/components/workbook/WorkBookInfoPerformanceIndicators"
import WorkBookNetWorthCalculator from "@/components/workbook/WorkBookNetWorthCalculator"
import WorkBookProsAndCons from "@/components/workbook/WorkBookProsAndCons"
import WorkBookSectionScore from "@/components/workbook/WorkBookSectionScore"
import WorkBookQuestionsClosedTopEntry from "@/components/workbook/WorkBookQuestionsClosedTopEntry"
import WorkBookQuestionsOpenAltTopEntry from "@/components/workbook/WorkBookQuestionsOpenAltTopEntry"
import WorkBookQuestionsOpenCustomTopEntry from "@/components/workbook/WorkBookQuestionsOpenCustomTopEntry"
import WorkBookQuestionsOpenTopEntry from "@/components/workbook/WorkBookQuestionsOpenTopEntry"
import WorkBookSelectTop from "@/components/workbook/WorkBookSelectTop"
import WorkBookQuestionsList from "@/components/workbook/WorkBookQuestionsList"
import ArrowCodeIcon from '~/assets/icons/arrows/ArrowCodeIcon.svg?inline'
import WorkBookTableTotal from "./WorkBookTableTotal"
import WorkBookMathQuestionList from "~/components/workbook/WorkBookMathQuestionList"
import WorkBookTabLine from '~/components/workbook/elements/WorkBookTabLine'

export default {
  name: "WorkBookQuestionnaire",
  components: {
    WorkBookDisabledMessage,
    WorkBookCultureTraits,
    WorkBookAddFranchiseCustomer,
    WorkBookAddFranchise,
    WorkBookTableTotalTopFive,
    WorkBookQuestionsOpenAdditional,
    WorkBookQuestionsClosedWithSelect,
    WorkBookBusinessAttributes,
    WorkBookPersonalPreferences,
    WorkBookQuestionsWithChoice,
    WorkBookMarketingToolkit,
    WorkBookCandidates,
    WorkBookCongratulations,
    WorkBookMathQuestionList,
    WorkBookCompetitiveAnalysis,
    WorkBookTableTotal,
    WorkBookProsAndCons,
    WorkBookNetWorthCalculator,
    WorkBookAdditionalQuestions,
    WorkBookChapterScoreFull,
    WorkBookInfoPerformanceIndicators,
    WorkBookSelectTop,
    WorkBookChapterScore,
    WorkBookSectionScore,
    WorkBookQuestionsOpenAltTopEntry,
    WorkBookQuestionsOpenCustomTopEntry,
    WorkBookQuestionsOpenTopEntry,
    WorkBookQuestionsClosedTopEntry,
    WorkBookQuestionsList,
    ArrowCodeIcon,
    WorkBookTabLine,
  },
  data: () => ({
    visibleBlockIndex: 0,
    chapterTypes: WORK_BOOK_QUESTION_TYPES,
    allowNext: false,
    WORK_BOOK_TAB_TYPE,
    acceptRisk: false,
    bodyId:'questionnaire-body-id',
  }),
  computed: {
    ...mapGetters({
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
      workBooks: 'account/workbook/workBooks',
      candidates: 'account/workbook/candidates',
      franchiseCustomers: 'account/workbook/franchiseCustomers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      needToSave: 'account/workbook/needToSave',
      busy: 'account/workbook/busy',
      progress: 'account/workbook/progress',
    }),
    currentSectionName() {
      return WORK_BOOK[this.currentSectionIndex]?.name ?? ''
    },
    currentChapter() {
      return WORK_BOOK[this.currentSectionIndex]?.chapters[this.currentChapterIndex]
    },
    chapterNumber() {
      return this.currentChapter.customNumber ?? `${this.currentSectionIndex + 1}.${this.currentChapterIndex + 1}`
    },
    blockComponentName() {
      return this.isTabsMode(this.currentChapter.tabsStatus) ? `b-collapse` : `div`
    },
    textButtonSave() {
      return this.allowNext && !this.currentWorkBookNeedToSave ? 'Next' : 'Save'
    },
    currentWorkBookNeedToSave() {
      return this.needToSave(this.currentWorkBookIndex)
    },
    showMessage() {
      const progress = this.progress(this.currentWorkBookIndex)
      const lastSectionIndex = progress.lastSectionIndex ?? progress.sectionIndex
      const lastChapterIndex = progress.lastChapterIndex ?? progress.chapterIndex

      return (lastSectionIndex > this.currentSectionIndex ||
        lastChapterIndex > this.currentChapterIndex) &&
        !this.acceptRisk
    },
  },
  watch: {
    currentWorkBookIndex() {
      this.handlerChangeChapter()
    },
    currentSectionIndex() {
      this.handlerChangeChapter()
    },
    currentChapterIndex() {
      this.visibleBlockIndex = 0
      this.handlerChangeChapter()
    },
  },
  mounted() {
    this.handlerChangeChapter()
  },
  methods: {
    ...mapActions({
      saveWorkBook: 'account/workbook/saveWorkBook',
      gotoNextChapter: 'account/workbook/gotoNextChapter',
      updateStatusComplete: 'account/workbook/updateStatusComplete',
      updateProgress: 'account/workbook/updateProgress',
    }),
    handlerChangeChapter() {
      this.allowNext = this.checkChapter()
      this.acceptRisk = false
      const bodyElement = document.getElementById(this.bodyId)
      bodyElement.scrollTo(0,0)
    },
    isTabsMode(tabStatus) {
      return tabStatus ?? true
    },
    isUpdateTop(updateTop) {
      return updateTop ?? true
    },
    getTabsType(tabsType) {
      return tabsType ?? WORK_BOOK_TAB_TYPE.ARROW
    },
    getNumberTop(number) {
      return number ?? 10
    },
    getFranchiseName(block, blockIndex) {
      let listIndex = WORK_BOOK_TOP_LIST_KEYS.TEN

      if (block.numberTop === 5)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.FIVE

      if (block.numberTop === 3)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.THREE

      if (block.numberTop === 2)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.TWO

      if (typeof this.topList[listIndex] === "undefined")
        return ''

      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(this.topList[listIndex][blockIndex]))

      if (typeof currentFranchise === "undefined")
        return `Franchise #${blockIndex + 1}`

      return currentFranchise.franchise
    },
    getTabName(block, blockIndex) {
      if (block.isTabNamefranchise) {
        return this.getFranchiseName(block, blockIndex)
      }
      return block.tabName ?? `Table ${blockIndex + 1}`
    },
    handlerChange(index) {
      if (this.visibleBlockIndex === index)
        return

      this.visibleBlockIndex = index

      this.$root.$emit('bv::toggle::collapse', this.getCollapseId(index))
    },
    async handlerButtonSave() {
      if (this.allowNext && !this.currentWorkBookNeedToSave) {
        await this.gotoNextChapter()
        return
      }
      const allowChapter = this.checkChapter()
      const lastSectionIndex = WORK_BOOK.length - 1
      const lastChapterIndex = WORK_BOOK[this.currentSectionIndex].chapters.length - 1
      const progress = this.progress(this.currentWorkBookIndex)

      await this.saveWorkBook()

      if (
        lastSectionIndex === this.currentSectionIndex &&
        lastChapterIndex === this.currentChapterIndex &&
        allowChapter
      ) {
        this.updateStatusComplete(true)
        return
      }

      if (
        (progress.sectionIndex !== this.currentSectionIndex ||
          progress.chapterIndex !== this.currentChapterIndex) &&
        !allowChapter
      )
        this.updateProgress()


      this.allowNext = this.checkChapter()
    },
    getCollapseId(blockIndex) {
      return `question_block_${this.currentChapter.id}_${blockIndex}`
    },
    checkChapter() {
      const sectionIndex = this.currentSectionIndex
      const chapterIndex = this.currentChapterIndex
      const currentQuestions = WORK_BOOK[sectionIndex].chapters[chapterIndex].questionsBlocks

      if (this.currentWorkBookIndex === null)
        return

      const currentAnswers = this.workBooks[this.currentWorkBookIndex].answers[sectionIndex].chapters[chapterIndex].questionsBlocks
      const currentRules = WORK_BOOK_CHAPTER_RULES[sectionIndex][chapterIndex]
      const allow = []

      currentAnswers.forEach((block, blockIndex) => {
        let blockAllow = false
        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL &&
          (block.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION ||
            block.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_SELECT ||
            block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION ||
            block.type === WORK_BOOK_QUESTION_TYPES.NET_WORTH_CALCULATOR)
        )
          blockAllow = block.answers.filter(answer => answer.value === '').length === 0

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL_WITH_HIGHLIGHTED_THREE &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_WITH_CHOICE
        ) {
          const tableAllow = []
          tableAllow.push(block.answers.filter(answer => answer.value.text === '').length === 0)
          tableAllow.push(block.answers.filter(answer => answer.value.selected).length === 3)
          blockAllow = tableAllow.filter(item => !item).length === 0
        }

        if (block.type === WORK_BOOK_QUESTION_TYPES.PERSONAL_PREFERENCES) {
          const tabAllow = []
          currentQuestions[blockIndex].tabs.forEach((tab, tabIndex) => {
            let questionsAllow = false
            const questions = currentQuestions[blockIndex].questions.filter(answer => answer.tabIndex === tabIndex)

            if (currentRules[tabIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE)
              questionsAllow = questions.filter(question => {
                const answerValue = block.answers.find(answer => answer.id === question.id).value
                if (answerValue !== '')
                  return question
              }).length > 0

            if (currentRules[tabIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL)
              questionsAllow = questions.filter(question => {
                const answerValue = block.answers.find(answer => answer.id === question.id).value
                if (answerValue === '')
                  return question
              }).length === 0
            tabAllow.push(questionsAllow)
          })
          blockAllow = tabAllow.filter(item => !item).length === 0
        }

        if (block.type === WORK_BOOK_QUESTION_TYPES.BUSINESS_ATTRIBUTES) {
          const questionAllow = []
          blockAllow = block.answers.forEach(answer => {
            questionAllow.push(answer.value.filter(value => value === '').length === 0)
          })

          blockAllow = questionAllow.filter(item => !item).length === 0
        }

        if (currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW)
          blockAllow = true

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY
        ) {
          blockAllow = block.answers.filter(answer => {
            return Object.keys(answer.value).filter(field => {
              return answer.value[field] === ''
            }).length === 0
          }).length >= 6
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN &&
          block.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_TOP_TEN
        ) {
          const franchisesLength = this.franchise.filter(item => item.franchise !== '').length

          blockAllow = block.answers.filter(answer => answer.value.filter(value => value !== '').length !== franchisesLength).length === 0

          if (franchisesLength === 0)
            blockAllow = false
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_TEN_ENTRY
        ) {
          const franchisesLength = this.franchise.filter(item => item.franchise !== '').length

          blockAllow = block.answers.filter(answer => answer.value.filter(value => {
            const fieldAllow = []
            Object.keys(value).forEach(field => {
              fieldAllow.push(value[field] !== '')
            })
            return fieldAllow.filter(item => !item).length === 0
          }).length !== franchisesLength).length === 0

          if (franchisesLength === 0)
            blockAllow = false
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY
        ) {
          const franchisesLength = this.franchise.filter(item => item.franchise !== '').length

          blockAllow = block.answers.filter(answer => {
            const fieldAllow = []
            Object.keys(answer.value).forEach(field => {
              fieldAllow.push(answer.value[field] !== '')
            })
            return fieldAllow.filter(item => !item).length <= 1
          }).length === franchisesLength


          if (franchisesLength === 0)
            blockAllow = false
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_FIVE &&
          block.type === WORK_BOOK_QUESTION_TYPES.SELECT_TOP_FIVE
        )
          blockAllow = this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE].length === 5

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_FIVE &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ADDITIONAL
        ) {
          const answerAllow = []
          block.answers.forEach(answer => {
            const answerComplete = answer.value.filter(value => {
              const fieldAllow = []
              Object.keys(value).forEach(field => {
                fieldAllow.push(value[field] !== '')
              })
              return fieldAllow.filter(item => !item).length === 0
            })
            answerAllow.push(answerComplete.length === 5)
          })
          blockAllow = answerAllow.filter(item => !item).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE &&
          block.type === WORK_BOOK_QUESTION_TYPES.SELECT_TOP_THREE
        )
          blockAllow = this.topList[WORK_BOOK_TOP_LIST_KEYS.THREE].length === 3

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_MODEL_ASSESSMENT &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_THREE_ENTRY
        ) {
          blockAllow = block.answers.filter(answer => {
            return Object.keys(answer.value).filter(field => {
              return answer.value[field] === ''
            }).length > 4
          }).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.MARKETING_ASSESSMENT &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_THREE_ENTRY
        ) {
          blockAllow = block.answers.filter(answer => {
            return Object.keys(answer.value).filter(field => {
              return answer.value[field] === ''
            }).length > 1
          }).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE &&
          block.type === WORK_BOOK_QUESTION_TYPES.COMPETITIVE_ANALYSIS_TOP_THREE_ENTRY
        ) {
          const answerAllow = []
          block.answers.forEach(answer => {
            answerAllow.push(answer.value.filter(value => value === '').length === 0)
          })
          blockAllow = answerAllow.filter(item => !item).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_THREE_ENTRY
        ) {
          const answerAllow = []
          block.answers.forEach(answer => {
            const answerComplete = answer.value.filter(value => {
              const fieldAllow = []
              Object.keys(value).forEach(field => {
                fieldAllow.push(value[field] !== '')
              })
              return fieldAllow.filter(item => !item).length === 0
            })
            answerAllow.push(answerComplete.length === 3)
          })
          blockAllow = answerAllow.filter(item => !item).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE &&
          block.type === WORK_BOOK_QUESTION_TYPES.PROS_AND_CONS_TOP_THREE_ENTRY
        ) {
          const answerAllow = []
          block.answers.forEach(answer => {
            const answerComplete = answer.value.filter(value => {
              const completeAnswers = value.answers.filter(field => field !== '').length > 0
              const completeSummary = value.summary !== ''
              return completeAnswers && completeSummary
            })
            answerAllow.push(answerComplete.length === 3)
          })
          blockAllow = answerAllow.filter(item => !item).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO &&
          block.type === WORK_BOOK_QUESTION_TYPES.SELECT_TOP_TWO
        )
          blockAllow = this.topList[WORK_BOOK_TOP_LIST_KEYS.TWO].length === 2

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE_FRANCHISEE &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS
        ) {
          const answerAllow = []
          block.answers.forEach(answer => {
            Object.keys(answer.value).forEach(field => {
              answerAllow.push(answer.value[field] !== '')
            })
          })
          blockAllow = answerAllow.filter(item => item).length > 1
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_CUSTOMER_TOP_TEN &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM
        ) {
          const franchiseId = this.topList[WORK_BOOK_TOP_LIST_KEYS.TWO][blockIndex] ?? null
          const countFranchisee = this.franchiseCustomers.filter(item => Number(item.franchiseId) === Number(franchiseId)).length
          const allowAnswers = []
          block.answers.forEach(answer => {
            const allowFranchiseCustomer = []
            answer.value.forEach(customer => {
              const allowCustomer = Object.keys(customer).filter(field => {
                return customer[field] === ''
              }).length === 0

              allowFranchiseCustomer.push(allowCustomer)
            })
            allowAnswers.push(allowFranchiseCustomer.filter(item => item).length === countFranchisee)
          })
          blockAllow = allowAnswers.filter(item => !item).length === 1
        }


        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO_CALCULATE_REVENUE &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowAnswer = []
            Object.keys(answer.value[0]).forEach(field => {
              allowAnswer.push(answer.value[0][field] !== '')
            })
            return allowAnswer.filter(item => !item).length === 0
          }).length > 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO_EXPENSE_CALCULATIONS &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_ONE_ENTRY_CUSTOM
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowAnswer = []

            Object.keys(answer.value[0]).forEach(field => {
              allowAnswer.push(answer.value[0][field] !== '')
            })
            return allowAnswer.filter(item => !item).length > 0
          }).length === 3
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FINANCIAL_GOALS &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_ONE_ENTRY_CUSTOM
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowAnswer = []

            Object.keys(answer.value[0]).forEach(field => {
              allowAnswer.push(answer.value[0][field] !== '')
            })
            return allowAnswer.filter(item => !item).length > 0
          }).length === 2
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL &&
          block.type === WORK_BOOK_QUESTION_TYPES.MATH_QUESTION
        ) {
          const notAcceptedAnswers = block.answers.filter(answer => {
            if (answer.value === '')
              return true

            const allowValue = []
            Object.keys(answer.value).forEach(field => {
              allowValue.push(answer.value[field] !== '')
            })
            return allowValue.filter(item => !item).length !== 0
          })
          blockAllow = notAcceptedAnswers.length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FINANCIAL_WORKSHEET &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM
        ) {
          blockAllow = block.answers.filter(answer => {
            return answer.value
              .filter(value => value.amount !== '')
              .length === 0
          }).length === 3
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.MONTHLY_BUDGET &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowValue = []
            Object.keys(answer.value[0]).forEach(field => {
              allowValue.push(answer.value[0][field] !== '')
            })
            return allowValue.filter(item => !item).length === 0
          }).length > 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISOR_METRICS &&
          block.type === WORK_BOOK_QUESTION_TYPES.TOP_TWO_ENTRY_CUSTOM
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowValue = []
            Object.keys(answer.value[0]).forEach(field => {
              allowValue.push(answer.value[0][field] !== '')
            })
            return allowValue.filter(item => !item).length > 0
          }).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_ONE &&
          block.type === WORK_BOOK_QUESTION_TYPES.SELECT_TOP_ONE
        )
          blockAllow = this.topList[WORK_BOOK_TOP_LIST_KEYS.ONE].length === 1

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_EIGHT_ENTRY
        ) {

          blockAllow = block.answers.filter(answer => {
            const allowValue = []
            Object.keys(answer.value).forEach(field => {
              allowValue.push(answer.value[field] !== '')
            })
            return allowValue.filter(item => !item).length === 0
          }).length > 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL &&
          (block.type === WORK_BOOK_QUESTION_TYPES.CULTURE_TRAITS_TOP_TEN ||
            block.type === WORK_BOOK_QUESTION_TYPES.CULTURE_TRAITS_TOP_THREE)
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowValue = []
            Object.keys(answer.value).forEach(field => {
              allowValue.push(answer.value[field] !== '')
            })
            return allowValue.filter(item => !item).length !== 0
          }).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.MIN_TWO_CANDIDATES &&
          block.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_CANDIDATES
        ) {
          const countCandidates = this.candidates.filter(candidate => candidate !== '').length

          blockAllow = block.answers.filter(answer => {
            return answer.value.filter(value => value !== '').length !== countCandidates
          }).length === 0

          if (countCandidates < 2)
            blockAllow = false
        }


        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL &&
          block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_ONE_ENTRY_CENTERED
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowValue = []
            Object.keys(answer.value[0]).forEach(field => {
              allowValue.push(answer.value[0][field] !== '')
            })
            return allowValue.filter(item => !item).length !== 0
          }).length === 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE &&
          block.type === WORK_BOOK_QUESTION_TYPES.LOCAL_MARKETING_TOOLKIT
        ) {
          blockAllow = block.answers.filter(answer => {
            return answer.value.filter(value => {
              return Object.keys(value).filter(field => value[field] === '').length === 0
            }).length > 0
          }).length > 0
        }

        if (
          currentRules[blockIndex] === WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL &&
          (block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_ONE_ENTRY ||
            block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_THREE_ENTRY ||
            block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TWELVE_ENTRY ||
            block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_NINETY_ENTRY)
        ) {
          blockAllow = block.answers.filter(answer => {
            const allowValue = []
            Object.keys(answer.value).forEach(field => {
              allowValue.push(answer.value[field] !== '')
            })
            return allowValue.filter(item => !item).length !== 0
          }).length === 0
        }
        allow.push(blockAllow)
      })

      return allow.filter(item => !item).length === 0
    },
  },
}
