//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform';
import BaseButton from '@/components/buttons/BaseButton';

export default {
  components: { BaseButton },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    franchise: {
      type: Object,
      required: true,
    },
    accountId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      form: new Form({
        seo_meta_title: null,
        seo_meta_keywords: null,
        seo_meta_description: null,
      }),
      isLoading: false,
    };
  },
  created() {
    const franchise = this.franchise.data[0];

    this.form.seo_meta_title = franchise.seo_meta_title.attribute_value_string;
    this.form.seo_meta_keywords = franchise.seo_meta_keywords.attribute_value_string;
    this.form.seo_meta_description = franchise.seo_meta_description.attribute_value_string;
  },
  methods: {
    async handleSubmit() {
      const url = `/api/v1/business/edit/${this.franchise.data[0].franchise_id}/seo`;

      try {
        await this.form.post(url, this.form);
        this.$emit('change');
      } catch (e) {
        console.log(e);
      }
    },
    closeModal() {
      return this.$emit('close', false);
    },
  },
};
