//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingText from "@/components/landing/elements/LandingText";
import landingStyles from "@/components/mixins/landingStyles";
import LandingVideo from "@/components/landing/elements/LandingVideo";
import showReadMore from "@/components/mixins/showReadMore";
import SectionTools from "@/components/landing/elements/SectionTools";
import LandingButton from "@/components/landing/elements/LandingButton";
import {mapGetters} from "vuex";

export default {
  name: "LandingSectionVideo",
  components: {
    SectionTools,
    LandingVideo,
    LandingText,
    LandingButton,
  },
  mixins: [
    landingStyles,
    showReadMore,
  ],
  model: {
    prop: 'section',
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => {
      }
    },
    currentIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => {
    return {
      showTools:false,
      collapseHeight: 0,
      containerSize: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    backgroundImage(){
      const imageId = this.section.styles.backgroundImage ?? ''

      return (imageId === '')? '' :`${process.env.BACKEND_BASE_URL}api/v2/landing/${imageId}/image`
    },
  },
  mounted() {
    if (this.editable)
      this.updateSectionSizes()
  },
  methods: {
    updateSectionSizes(){
      this.containerSize.width = this.$refs[`container_${this.section.id}`].clientWidth
      this.containerSize.height = this.$refs[`section_${this.section.id}`].clientHeight
    },
    handleScroll() {
      const screenWidth = window.innerWidth
      const elementPosition = this.$refs[`section_${this.section.id}`].getBoundingClientRect().top

      if (screenWidth > elementPosition)
        this.checkHeight()
    },
    checkHeight() {
      const textBlockHeight = this.$refs[`textBlock_${this.section.id}`].clientHeight
      const videoBlockHeight = this.$refs[`videoBlock_${this.section.id}`].clientHeight

      this.collapseHeight = textBlockHeight > videoBlockHeight ? videoBlockHeight - 146 : 0
    },
    handlerClickButton(button) {
      if (button.showModal) {
        this.$bvModal.show(this.modalId)

        return
      }
      window.open(button.url, 'blank')
    },
    updateSection() {
      this.updateSectionSizes()
      this.$store.dispatch('landing/updateSection', {section: this.section, index: this.currentIndex})
    },
  }
}
