//
//
//
//
//
//
//

export default {
name: "LinkIcon"
}
