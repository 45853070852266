//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from "vuex"
import {
  MODAL_WORKBOOK_ID,
  USER_TYPE_FRANCHISEE,
  USER_TYPE_FRANCHISOR,
  ENV_TYPE,
} from '~/store/constants'

export default {
  props: {
    show: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    userTypes: {
      franchisee: USER_TYPE_FRANCHISEE,
      franchisor: USER_TYPE_FRANCHISOR,
    },
    ENV_TYPE,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getInitials() {
      if (this.user.first_name && this.user.last_name)
        return `${this.user.first_name[0]} ${this.user.last_name[0]}`
      return ''
    },
    fullName() {
      if (this.user.first_name || this.user.last_name)
        return `${this.user.first_name} ${this.user.last_name}`
      return ''
    },
    isProdEnv() {
      return process.env.APP_ENV === this.ENV_TYPE.production
    },
  },
  methods: {
    ...mapActions({
      fetchFranchise: 'registration/fetchFranchise',
    }),
    async handlerClickAccountFranchisor() {
      const urlAccount = "/account-franchisor"

      await this.$router.push(urlAccount)
    },
    showWorkBookModal() {
      this.$bvModal.show(MODAL_WORKBOOK_ID)
    }
  }
}
