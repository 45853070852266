import {MODAL_MESSAGE_ID, MODAL_SIGN_UP_FRANCHISE_ID} from "@/store/constants"
import {mapActions, mapGetters} from "vuex"

export default {
  computed: {
    ...mapGetters({
      authenticated: 'auth/check',
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      fireMessage: 'modal-message/fireMessage',
      changeStatusFlowBeginToday: 'registration/changeStatusFlowBeginToday',
      changeStatusFlowPendingList: 'registration/changeStatusFlowPendingList',
      changeStatusFlowPendingListWithSave: 'registration/changeStatusFlowPendingListWithSave',
    }),
    async showFranchiseeFastRegistration(item = null, showFdd = false) {
      this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
      await this.$store.commit('account/SET_SELECTED_FRANCHISE', item)
      await this.$store.commit('account/SET_SHOW_FDD', showFdd)
    },
    async showFranchiseeBeginTodayRegistration() {
      await this.changeStatusFlowBeginToday(true)
      await this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
    },
    async showFranchiseePendingListRegistration(email) {
      await this.changeStatusFlowPendingList(email)
      await this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
    },
    async showFranchiseePendingListRegistrationWithSave(email) {
      await this.changeStatusFlowPendingListWithSave(email)
      await this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
    },
    buildAccountFranchisorUri(path, params = {}) {
      if (!this.authenticated) {
        return
      }

      if (this.user.isMasterAdmin) {
        const franchise = this.$store.getters['business-v2/franchise']
        params.franchise_id = franchise.id
        params.version_id = franchise.version_id
      }

      if (Object.keys(params).length) {
        const searchParams = new URLSearchParams(params)

        return `${path}?${searchParams.toString()}`
      }

      return path
    },
    async fireModalMessage(payload) {
      await this.fireMessage(payload)
      this.$bvModal.show(MODAL_MESSAGE_ID)
    },
  },
}
