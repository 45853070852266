//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterState from "@/components/FilterState";
import EditUserIcon from "@/components/icons/EditUserIcon";
import FilterInvest from "@/components/icons/FilterInvest";
import FilterSearch from "@/components/icons/FilterSearch";
import ModalPreferences from "@/components/modals/ModalAccount/ModalPreferences";
import {MODAL_PREFERENCE_FRANCHISEE_ID, USER_PREFERENCES_TOTAL_AMOUNT_INVEST} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FilterPreferences",
  components: {ModalPreferences, FilterSearch, FilterInvest, EditUserIcon, FilterState},
  props: {
    isBlocked: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    modalPreferencesTab: 0,
    investmentDirectory: USER_PREFERENCES_TOTAL_AMOUNT_INVEST,
  }),
  computed: {
    ...mapGetters({
      preferencesIndustries: 'account/preferencesIndustries',
      preferencesStates: 'account/preferencesStates',
      preferencesInvestment: 'account/preferencesInvestment',
      statesDirectory: 'directories/states',
      focusFilter: 'account/focusFilter',
    }),
    buttonTitleIndustry() {
      if (this.preferencesIndustries.length === 0)
        return 'Industry'

      let title = ''

      this.preferencesIndustries.forEach(item => {
        title += `<span>${item}</span>`
      })

      return title
    },
    buttonTitleStates() {
      if (this.preferencesStates.length === 0 || this.statesDirectory.length === 0)
        return 'State'

      let title = ''

      this.preferencesStates.forEach(item => {
        const stateObj = this.statesDirectory.find(state => item === state.id)
        title += `<span>${stateObj.name}</span>`
      })

      return title
    },
    buttonTitleInvest() {
      if (this.investmentDirectory.filter(item => item.value === this.preferencesInvestment).length === 0)
        return 'Investment'

      const investObj = this.investmentDirectory.find(invest => this.preferencesInvestment === invest.value)

      return `<span>${investObj.title}</span>`
    },
  },
  created() {
    this.fetchStates()
  },
  methods: {
    ...mapActions({
      fetchStates: 'directories/fetchStates',
    }),
    showAccountPreferencesModal(tab) {
      this.modalPreferencesTab = tab
      this.$bvModal.show(MODAL_PREFERENCE_FRANCHISEE_ID)
    },
  }
}
