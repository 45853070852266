//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VideoPlayIcon from "@/assets/icons/landing/VideoPlayIcon.svg?inline"
import VideoEditIcon from "@/assets/icons/landing/VideoEditIcon.svg?inline"

export default {
  name: "BlankBlock",
  components: {
    VideoPlayIcon,
    VideoEditIcon,
  },
  props: {
    widthBlock: {
      type: Number,
      default: 0,
    },
    heightBlock: {
      type: Number,
      default: 0,
    },
    videoType: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getSizeString() {
      return `${this.widthBlock}x${this.heightBlock}`
    }
  },
}
