//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import uploadFileMixin from '@/components/mixins/uploadFileMixin'
import ChatInput from "@/components/chat/ChatInput"
import ChatContactAvatar from "@/components/chat/ChatContactAvatar"
import ChatAreaThreeDotMenu from "@/components/menu/ChatAreaThreeDotMenu"
import Message from "@/components/chat/Message"
import storeMixin from "../mixins/storeMixin"

import {
  MODAL_VIEW_FRANCHISEE_INFO,
  STATUS_IN_PROGRESS,
  USER_TYPE_FRANCHISEE,
  USER_TYPE_FRANCHISOR,
  USER_TYPE_MASTER_ADMIN,
} from '@/store/constants'

export default {
  components: {
    Message,
    ChatAreaThreeDotMenu,
    ChatContactAvatar,
    ChatInput,
  },
  mixins: [
    storeMixin,
    uploadFileMixin,
  ],
  props: {
    messages: {
      type: Array,
      required: true,
    },
    messagesLoading: {
      type: Boolean,
      default: false,
    },
    messagesPagination: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      required: true,
    },
    franchiseName: {
      type: String,
      default: '',
    },
    franchiseLogo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      STATUS_IN_PROGRESS,
      USER_TYPE_FRANCHISEE,
      fileUploadProgressValue: 0,
      inValidSize: 'The file may not be greater than 20 Mb.',
      inValidExtension: 'The file must be a file of type: pdf, jpg, png, docx, xlsx, zip, rar.',
      cancelTokenSource: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      paymentPermissions: 'payment/permissions',
    }),
  },
  watch: {
    messages(newValue) {
      if (newValue.length <= 10) {
        this.scrollLastMessage(50)
      }
    },
    entity: {
      immediate: true,
      handler(newValue, oldValue) {
        if (oldValue) {
          this.leaveChatRoom(oldValue)
        }
        this.listenChatRoom(newValue)
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch('footer/hideSelf')
  },
  beforeDestroy() {
    this.$store.dispatch('footer/showSelf')
  },
  mounted() {
    this.scrollLastMessage()
  },
  destroyed() {
    this.$store.commit('chat/SET_CHAT_CONTACT_MESSAGES_PAGINATION', {
      current_page: 1,
    });
    this.$store.commit('chat/SET_CHAT_CONTACT_MESSAGES', [])
  },
  methods: {
    ...mapActions({
      approve: 'franchisor-steps/approveFranchisee',
      updateFranchiseeViewedId: 'account-franchisor/updateFranchiseeViewedId',
    }),
    handleClickOpenModal(){
      if (!this.paymentPermissions.franchiseeProfile.access) {
        this.fireModalMessage({ message: this.paymentPermissions.franchiseeProfile.message })
        return
      }

      this.updateFranchiseeViewedId(this.entity)
      this.$bvModal.show(MODAL_VIEW_FRANCHISEE_INFO)
    },
    /**
     * Send to interlocutor
     *
     * @param {string} text message text of chat
     * @param {Array} files chosen files of chat
     */
    async sendMessage({ text, files }) {
      const url = `/api/v2/business-client-steps/${this.entity.slug}/send-message`
      let requestData = {}
      const requestConfig = {}

      if (text) {
        requestData.message = text
        this.$refs.chatInputWrapper.clearMessageInput()
      } else {
        const CancelToken = this.$axios.CancelToken
        this.cancelTokenSource = CancelToken.source()
        requestConfig.cancelToken = this.cancelTokenSource.token

        requestData = this.buildFilesFormData(files, 'file')
        requestConfig.headers = {
          'Content-Type': 'multipart/form-data',
        }
        requestConfig.onUploadProgress = this.fileUploadProgress
      }

      try {
        const { data } = await this.$axios.post(url, requestData, requestConfig)
        await this.$store.commit('chat/APPEND_CHAT_CONTACT_MESSAGE', data.result)
        this.fileUploadProgressValue = 0
        this.scrollLastMessage()

        if (!text)
          this.$refs.chatInputWrapper.clearMessageInput()
      } catch (e) {
        if (this.$axios.isCancel(e))
          this.fileUploadProgressValue = 0

        const errors = e.response?.data.errors
        if (typeof errors === "undefined")
          return

        const fileErrors = errors?.file
        if (typeof fileErrors !== 'undefined' && fileErrors.length > 0) {
          await this.fireModalMessage({
            messageArray: fileErrors,
            messageArrayAsNumberList: true
          })

          this.fileUploadProgressValue = 0
          this.$refs.chatInputWrapper.clearMessageInput()
        }
      }
    },
    checkFileType(name, size) {
      if (!name || !size) {
        const messageArray = [
          !name ? this.inValidExtension : '',
          !size ? this.inValidSize : ''
        ].filter(err => err !== '')

        this.fireModalMessage({
          messageArray,
          messageArrayAsNumberList: true
        })

        this.$refs.chatInputWrapper.clearMessageInput()
      }
    },
    /**
     * Listen chat messages
     */
    async listenChatRoom(entity) {
      if (!process.client)
        return

      if(!this.$echo)
        return

      this.$echo
        .private(`business-franchisee-chat.${entity.slug}`)
        .listen('.message.sent', (response) => {
          this.receiveWsMessage(response)
        })
    },
    /**
     * Leave ws channel
     */
    async leaveChatRoom(entity) {
      if (!this.$echo)
        return

      this.$echo.leave(`business-franchisee-chat.${entity.slug}`)
    },
    /**
     * Scroll to last in chat messages
     * @param {Number} timeout
     */
    scrollLastMessage(timeout = 200) {
      setTimeout(() => {
        const messagesArea = document.querySelector('.chat-window-messages')
        messagesArea.scrollTop = messagesArea.scrollHeight
        messagesArea.scrollTo({ top: messagesArea.scrollHeight, behavior: 'smooth' })
      }, timeout)
    },
    /**
     * Check message is my
     * @param {Object} user
     * @param {Object} message
     * @returns {boolean} message is my
     */
    isMy(user, message) {
      if (user.isFranchisor || user.isMasterAdmin) {
        return (
          message.from_user_id === this.user.id ||
          message.user_type === USER_TYPE_MASTER_ADMIN ||
          message.user_type === USER_TYPE_FRANCHISOR
        )
      }

      return message.from_user_id === this.user.id
    },
    /**
     * Check admin message for franchisor chat
     * @param {Object} user
     * @param {Object} message
     * @returns {boolean} message is my
     */
    isAdmin(user, message) {
      return user.isFranchisor || user.isMasterAdmin
        ? message.user_type === USER_TYPE_MASTER_ADMIN
        : false
    },
    /**
     * Receive WS payload
     * @param response
     */
    receiveWsMessage(response) {
      this.receiveChatMessage(response.message)
    },
    async receiveChatMessage(message) {
      if (!this.isMy(this.user, message)) {
        await this.$store.commit(`chat/APPEND_CHAT_CONTACT_MESSAGE`, message)
        this.scrollLastMessage()
      }
    },
    /**
     * Lazy loading items
     * @param {Event} e scroll event
     */
    async handleScrollItems(e) {
      // eslint-disable-next-line camelcase
      const { current_page, last_page } = this.messagesPagination

      // if page end
      // eslint-disable-next-line camelcase
      if (current_page === last_page) return

      const target = e.target
      const businessClientSlug = this.entity.slug

      if (!this.messagesLoading && target.scrollTop <= 20) {
        const scrollHeightBefore = target.scrollHeight
        await this.$store.commit('chat/SET_LOADING_CONTACT_MESSAGES', true)
        await this.$store.commit('chat/SET_CHAT_CONTACT_MESSAGES_PAGINATION', {
          // eslint-disable-next-line camelcase
          current_page: current_page + 1,
        })
        await this.$store.dispatch('chat/fetchContactMessages', businessClientSlug)
        target.scrollTop = target.scrollHeight - scrollHeightBefore
      }
    },
    /**
     * Show chat file
     * @param {object} messageFile - message file object
     */
    openChatFile(messageFile) {
      const messageId = messageFile.message_id
      const clientStepId = this.entity.business_client_step_id
      window.open(`/api/v1/chat/${clientStepId}/${messageId}/file`, '_blank')
    },
    /**
     * File upload progress event
     * @param {ProgressEvent} ev
     */
    fileUploadProgress(ev) {
      this.fileUploadProgressValue = Math.round((ev.loaded * 100) / ev.total)
    },
    /**
     * Click remove file
     */
    handleRemoveFile() {
      // cancel upload file if loading progress exist
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.fileUploadProgressValue = 0
      }
    },
    handleApprove(contact) {
      if (this.user.isFranchisor && !this.paymentPermissions.approveStep.access) {
        this.fireModalMessage({ message: this.paymentPermissions.approveStep.message })
        return
      }

      this.approve(contact)
    }
  },
}
