//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    stepsWidth: {
      type: Number,
      required: true,
    },
    stepsGap: {
      type: Number,
      required: true,
    },
  },
  computed: {
    /**
     * Generate group objects, key is group name value is group steps
     * @returns {Object}
     */
    groupWithSteps() {
      const groupWithSteps = {}
      this.steps.forEach((value) => {
        if (!Array.isArray(groupWithSteps[value.group_name])) {
          groupWithSteps[value.group_name] = []
        }
        groupWithSteps[value.group_name] = groupWithSteps[value.group_name].concat(value)
      })
      return groupWithSteps
    },
  },
  methods: {
    /**
     * Compute step width
     * @param {Array} groupSteps
     * @param {string} groupName
     */
    getStepWidth(groupSteps, groupName) {
      const stepsArray = Object.keys(this.groupWithSteps)
      const lastStepIndex = stepsArray.length - 1
      const lastStepName = stepsArray[lastStepIndex]
      let stepWidthWithGap = 0

      if (groupName === lastStepName) {
        stepWidthWithGap = groupSteps.length * this.stepsWidth + (groupSteps.length - 1) * this.stepsGap
      } else {
        stepWidthWithGap = groupSteps.length * this.stepsWidth + groupSteps.length * this.stepsGap
      }
      return `${stepWidthWithGap}px`
    }
  }
}
