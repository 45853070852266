export default {
  methods: {
    importStyle (url) {
      if (!process.client) {
        return false
      }

      const font = document.createElement(`link`)
      font.rel = `stylesheet`
      font.href = url
      document.body.appendChild(font)
    },
  },
}
