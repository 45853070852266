//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {cloneDeep} from "lodash/fp"
import DateRangePicker from "vue2-daterange-picker"
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
import VButton from "@/components/VButton"

export default {
  name: 'ActionsDateRangePicker',
  components: {
    VButton,
    DateRangePicker,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          startDate: null,
          endDate: null
        }
      },
    },
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null
      }
    }
  },
  computed:{
    showResetButton(){
      return this.dateRange.startDate !== null || this.dateRange.endDate !== null
    },
  },
  watch: {
    value(newVal) {
      this.dateRange = cloneDeep(newVal)
    },
  },
  methods: {
    handlerReset() {
      this.$emit('change', {startDate: null, endDate: null})
    },
  },
}
