//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "VInputV3",
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    darkMode:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    getPlaceholder(){
      return this.required? `${this.placeholder}*`: this.placeholder
    },
  },
}
