import { render, staticRenderFns } from "./LandingSectionComments.vue?vue&type=template&id=6616e5ca&scoped=true&"
import script from "./LandingSectionComments.vue?vue&type=script&lang=js&"
export * from "./LandingSectionComments.vue?vue&type=script&lang=js&"
import style0 from "./LandingSectionComments.vue?vue&type=style&index=0&id=6616e5ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6616e5ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingText: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingText.vue').default})
