//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VCheckBoxV2',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    /**
     * Click checkbox toggle checkbox
     */
    clickCheckbox() {
      this.$refs['v-checkbox-v2'].click();
    },
  },
};
