//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowCodeIcon from '@/assets/icons/arrows/ArrowCodeIcon.svg?inline'
import ThreeDotMenu from "@/components/menu/ThreeDotMenu"

import {
  ACCOUNT_FRANCHISOR_TASK_STATUS_LIST,
} from "@/store/constants"

export default {
  name: "ContactActionTaskCard",
  components: {
    ThreeDotMenu,
    ArrowCodeIcon,
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    showCollapse: false,
    showMenu: false,
    controlItemsIds: {
      edit: 0,
      delete: 1,
    },
    statusList: ACCOUNT_FRANCHISOR_TASK_STATUS_LIST,
  }),
  computed: {
    statusObj() {
      let status = ''
      Object.keys(this.statusList).forEach( id => {
        if (this.task.status === this.statusList[id].value) {
          status = this.statusList[id]
        }
      })
      return status
    },
    timeCreated() {
      return this.dateTimeMutator(this.task.created_at)
    },
    assignedUser() {
      return `Task assigned to ${this.task.assigned.full_name}`
    },
    controlItems() {
      return [
        {
          id: 0,
          title: 'Edit',
          action: 'EDIT',
          active: false,
          checked: false,
        },
        {
          id: 1,
          title: 'Delete',
          action: 'DELETE',
          active: false,
          checked: false,
        }
      ]
    },
    getPropDateArray() {
      const prop = []
      const dueDate = this.task.due_date !== null
        ? this.dateTimeMutator(this.task.due_date)
        : 'None'
      const reminder = this.task.reminder !== null
        ? this.dateTimeMutator(this.task.reminder)
        : 'None'

      prop.push({
        title: 'Due date',
        value: dueDate
      })

      prop.push({
        title: 'Reminder',
        value: reminder
      })

      prop.push({
        title: 'Priority',
        value: this.task.priority ?? 'None'
      })
      return prop
    },
  },
  methods: {
    dateTimeMutator(day) {
      const dateTime = new Date(day)
      const date = dateTime.toDateString().substring(4, 16)
      const time = dateTime.toTimeString().substring(0, 5)
      return `${date}, ${time}`
    },
    handlerOpenFile(file) {
      const iframeTypes = [
        'application/pdf',
      ]
      const imageTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
      ]

      if (iframeTypes.includes(file.mime_type)) {
        this.$store.dispatch('iframe/openModal', file.url)
        return
      }

      if (imageTypes.includes(file.mime_type)) {
        this.$store.dispatch('iframe/setTag', 'img')
        this.$store.dispatch('iframe/openModal', file.url)
        return
      }

      window.open(file.url, '_blank')
    },
    async handleClickMenuItem(id) {
      this.showMenu = false
      if (id === this.controlItemsIds.edit)
        this.$emit('edit', this.task)

      if (id === this.controlItemsIds.delete) {
        if (await this.$confirm(
          `Are you sure?`,
          'Delete note',
          'info',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            animation: true
          }
        )) {
          this.$emit('delete', this.task)
        }
      }
    },
  }
}
