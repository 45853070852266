//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WorkBookActionMenu from "@/components/workbook/elements/WorkBookActionMenu";
import {WORK_BOOK, WORK_BOOK_CHAPTER_STATUS} from '@/store/constants'
import {mapActions, mapGetters} from "vuex";
import WorkBookAdd from 'assets/icons/workbook/WorkbookAdd.svg?inline';
import WorkBook from 'assets/icons/workbook/WorkBook.svg?inline';
import ArrowCodeIcon from 'assets/icons/arrows/ArrowCodeIcon.svg?inline';
import ArrowCodeBolderIcon from 'assets/icons/arrows/ArrowCodeBolderIcon.svg?inline';

export default {
  name: "WorkBookMenu",
  components: {
    WorkBookActionMenu,
    WorkBookAdd,
    WorkBook,
    ArrowCodeIcon,
    ArrowCodeBolderIcon,
  },
  data: () => ({
    workBookObj: WORK_BOOK,
    open: false,
    workBookStatus: WORK_BOOK_CHAPTER_STATUS
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      progress: 'account/workbook/progress',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      editTitleWorkBookIndex: 'account/workbook/editTitleWorkBookIndex',
      isComplete: 'account/workbook/isComplete',
      needToSave: 'account/workbook/needToSave',
    }),
  },
  methods: {
    ...mapActions({
      changeCurrentChapter: 'account/workbook/changeCurrentChapter',
      createWorkBook: 'account/workbook/createWorkBook',
      updateNameWorkBook: 'account/workbook/updateNameWorkBook',
    }),
    checkDisableButton(workBookIndex, sectionIndex, chapterIndex) {
      const status = this.getChapterStatus(workBookIndex, sectionIndex, chapterIndex)
      return status === this.workBookStatus.DEFAULT
    },
    handlerChangeChapter(workBookIndex, sectionIndex, chapterIndex) {
      this.open = false
      this.changeCurrentChapter({workBookIndex, sectionIndex, chapterIndex})
    },
    getChapterNumber(chapter, sectionIndex, chapterIndex) {
      return chapter.customNumber ?? `${sectionIndex + 1}.${chapterIndex + 1}`
    },
    getChapterStatus(workBookIndex, sectionIndex, chapterIndex) {
      const progress = this.progress(workBookIndex)
      const lastSectionIndex = WORK_BOOK.length - 1
      const lastChapterIndex = WORK_BOOK[sectionIndex].chapters.length - 1
      const completeWorkBook = this.isComplete(workBookIndex)

      if (
        sectionIndex === lastSectionIndex &&
        chapterIndex === lastChapterIndex &&
        completeWorkBook
      )
        return WORK_BOOK_CHAPTER_STATUS.FINISHED


      if (
        workBookIndex === this.currentWorkBookIndex &&
        sectionIndex === this.currentSectionIndex &&
        chapterIndex === this.currentChapterIndex
      )
        return WORK_BOOK_CHAPTER_STATUS.CURRENT

      if (
        sectionIndex === progress.sectionIndex &&
        chapterIndex === progress.chapterIndex
      )
        return WORK_BOOK_CHAPTER_STATUS.IN_PROGRESS

      if (
        sectionIndex === progress.sectionIndex &&
        chapterIndex < progress.chapterIndex
      )
        return WORK_BOOK_CHAPTER_STATUS.FINISHED

      if (
        sectionIndex < progress.sectionIndex
      )
        return WORK_BOOK_CHAPTER_STATUS.FINISHED

      return WORK_BOOK_CHAPTER_STATUS.DEFAULT
    },
    getChapterClass(workBookIndex, sectionIndex, chapterIndex) {
      const status = this.getChapterStatus(workBookIndex, sectionIndex, chapterIndex)

      if (status === this.workBookStatus.FINISHED)
        return 'menu__chapter__status--finished'

      if (status === this.workBookStatus.IN_PROGRESS)
        return 'menu__chapter__status--in_progress'

      if (status === this.workBookStatus.CURRENT)
        return 'menu__chapter__status--current'

      return ''
    },
  }
}
