//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import storeMixin from "@/components/mixins/storeMixin"
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [
    storeMixin
  ],
  data: () => ({
    mobile: false,
    active: false,
    busy: true,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      liked: 'account/paginationLike',
      comparisons: 'account/paginationCompare',
      progress: 'account/paginationProgress',
    }),
    comparisonsCount() {
      return this.comparisons?.total ?? 0
    },
    progressCount() {
      return this.progress?.total ?? 0
    },
    likedCount() {
      return this.liked?.total ?? 0
    },
    checkIsFranchisee() {
      if (!this.authenticated)
        return false

      return this.user.isFranchisee ?? false
    },
    checkIsFranchisor() {
      if (!this.authenticated)
        return false

      return this.user.isFranchisor ?? false
    },
    checkIsMasterAdmin() {
      if (!this.authenticated)
        return false

      return this.user.isMasterAdmin ?? false
    },
    menuUser() {
      return [
        {
          title: 'My Account',
          link: this.checkIsFranchisee ? '/account/my-matches' : '/account-franchisor',
          active: this.authenticated
        },
        {
          title: `My Progress (${this.progressCount})`,
          link: '/account/dashboard',
          active: !this.checkIsFranchisor,
          preRegister: true
        },
        {
          title: `Favorites (${this.likedCount})`,
          link: '/account/favorite-franchises',
          active: !this.checkIsFranchisor,
          preRegister: true
        },
        {
          title: `Comparisons (${this.comparisonsCount})`,
          link: '/account/comparisons',
          active: !this.checkIsFranchisor,
          preRegister: true
        },
        {
          title: 'Dashboard',
          link: '/account-franchisor/dashboard',
          active: this.checkIsFranchisor
        },
        {
          title: 'Sign Out',
          link: '',
          active: this.authenticated,
          isLogout: true
        },
      ]
    },
    menu() {
      const menuArr = [
        {
          title: 'Top Franchises',
          link: '/top-franchises',
        },
        {
          title: 'Browse Franchises',
          link: '/franchise-directory',
        },
        {
          title: 'About',
          link: '/about',
        },
        {
          title: 'Blog',
          link: '/blog',
        },
        {
          title: 'University',
          link: '/franchise-university',
        },
      ]

      if (this.checkIsFranchisor)
        menuArr.push({
          title: 'Dashboard',
          link: '/account-franchisor/dashboard',
        })


      return menuArr
    }
  },
  mounted() {
    window.addEventListener('resize', this.changeScreenWidth)
    this.changeScreenWidth()
    this.busy = false
  },
  methods: {
    handlerClickMenu(menuItem) {
      if (menuItem.function)
        this[menuItem.function]()
      else
        this.$router.push(menuItem.link)
    },
    handlerClickUserMenu(item) {
      if (item?.preRegister) {
        if (this.authenticated)
          this.$router.push(item.link)
        else
          this.showFranchiseeFastRegistration()

        return
      }

      if (item?.isLogout) {
        this.$emit('logout')
        return
      }

      this.$router.push(item.link)
    },
    changeScreenWidth() {
      this.mobile = window.innerWidth < 1400
    },
  }
}
