export default {
  methods: {
    /**
     * Generate ForData object for upload to API
     * @param {Array|FileList} files - upload files
     * @param {string} formKey - formData key
     * @returns {FormData} html5 form data
     */
    buildFilesFormData(files, formKey) {
      const formData = new FormData();

      if (files.length > 1) {
        for (let i = 0; i < files.length; i += 1) {
          formData.append(`${formKey}[]`, files[i]);
        }
      } else if (files.length === 1) {
        formData.append(formKey, files[0]);
      }
      return formData;
    },
  },
};
