//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import ArrowDown from "~/assets/images/svg/arrow-down.svg?inline"
import Pinned from "~/assets/images/svg/pinned.svg?inline"
import ThreeDotMenu from "~/components/menu/ThreeDotMenu"

export default {
  name: 'ContactActionNotesItem',
  components: {
    ThreeDotMenu,
    ArrowDown,
    Pinned,
  },
  props: {
    note: {
      type: Object,
      default: () => {
      }
    },
    busy: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      show: false,
      showMenu: true,
      controlItemsIds: {
        edit: 0,
        pin: 1,
        delete: 2,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    controlItems() {
      return [
        {
          id: 0,
          title: 'Edit',
          action: 'EDIT',
          active: false,
          checked: false,
        },
        {
          id: 1,
          title: this.note.pin ? 'Unpin' : 'Pin',
          action: 'PIN',
          active: false,
          checked: false,
        },
        {
          id: 2,
          title: 'Delete',
          action: 'DELETE',
          active: false,
          checked: false,
        }
      ]
    },
  },
  methods: {
    toggleShowNote() {
      this.show = !this.show
    },
    async handleClickMenuItem(id) {
      if (id === this.controlItemsIds.edit) {
        this.$emit('edit', this.note)
      }
      if (id === this.controlItemsIds.pin) {
        if (!this.note.pin) {
          this.$emit('pin', this.note)
        } else {
          this.$emit('unpin', this.note)
        }
      }
      if (id === this.controlItemsIds.unpin) {
        this.$emit('unpin', this.note)
      }
      if (id === this.controlItemsIds.delete) {
        if (await this.$confirm(
          `Are you sure?`,
          'Delete note',
          'info',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            animation: true
          }
        )) {
          this.$emit('delete', this.note)
        }
      }
    },
  }
}
