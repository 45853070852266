export default {
	methods:{
		getTextStyles(textStyles){
			const stylesArr = {
				fontFamily: textStyles.fontFamily,
				fontSize:textStyles.fontSize,
				color: textStyles.color,
			}

			if (textStyles.isBold)
				stylesArr.fontWeight = 'bold'

			if(textStyles.isItalic)
				stylesArr.fontStyle = 'italic'

			return stylesArr
		},
    getButtonStyles(buttonStyles){
      return{
        backgroundColor:buttonStyles.backgroundColor,
        color: buttonStyles.color,
      }
    },
    headerStyles(buttonStyles){
      return `
        <style>
        .landing-header-btn {
            color: ${buttonStyles.color} !important;
            background: ${buttonStyles.backgroundColor} !important;
            border: 1px solid ${buttonStyles.color} !important;
        }
        .landing-header-btn:hover {
            color: ${buttonStyles.backgroundColor} !important;
            background: ${buttonStyles.color} !important;
            border: 1px solid ${buttonStyles.backgroundColor} !important;
        }
        </style>`;
    },
	}
}
