//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "StarIcon",
  props: {
    fillPercent: {
      type: Number,
      default: 0,
    },
    fillColor:{
      type: String,
      default: '#00a769',
    },
    defaultColor:{
      type: String,
      default: '#EDEEF3',
    }
  },
  computed:{
    gradientId(){
      return `gradient_${this._uid}`
    }
  }
}
