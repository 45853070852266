//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Deselect from '../icons/Deselect'

export default {
  components: { Deselect },
  model: {
    event: 'input',
    prop: 'value'
  },
  props: {
    placeholder: {
      type: String,
      default: () => 'Chose range'
    },
    value: {
      type: [Array, Object],
      default: () => []
    },
    bordered: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      active: false,
      step: [1000, 10000],
      title: '',
      localValue: [],
      maxMin: 0,
      minMin: 0
    }
  },
  watch: {
    value: {
      deep: true,
      handler (newValue) {
        if (!newValue.length && this.localValue.length) {
          this.clear()
        }
      }
    }
  },
  created () {
    if (this.value.length > 0) {
      this.localValue = this.value
    } else {
      // this.title = this.placeholder
    }

    if (process.client) {
      document.addEventListener('click', this.documentClick)
    }

    if (this.localValue.length) {
      this.title = `${this.value[0]} - ${this.value[1]}`
    }
  },
  destroyed () {
    if (process.client) {
      document.removeEventListener('click', this.documentClick)
    }
  },
  methods: {
    handleClick (e) {
      this.active = !this.active
    },
    handleInput (e, idx) {

      this.localValue[idx] = e.target.value
      this.maxMin = this.localValue[0]
      // if (this.localValue[0] > this.localValue[1]) {
      //   return false
      // }
      this.$emit('input', this.localValue)
      this.title = this.localValue.join(' - ')
    },
    documentClick (e) {
      let rangeSelect = this.$refs.rangeSelect
      let rangeMenu = this.$refs.rangeSelectMenu
      let target = e.target
      if (rangeSelect !== target && !rangeSelect.contains(target)) {
        try {
          if (rangeMenu !== target && !rangeMenu.contains(target)) {
            this.active = false
          }
        } catch (e) {
          // @TODO need fix
        }
      }
    },
    clear () {
      this.title = null
      this.localValue = []
      this.active = false
      this.$emit('input', this.localValue)
    }
  }
}
