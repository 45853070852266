//
//
//
//
//
//

export default {
name: "FilterSearch",
  props: {
    color: {
      type: String,
      default: '#212A4B'
    }
  }
}
