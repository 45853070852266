//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputCustom from "@/components/form/customField/InputCustom";
import Form from 'vform';
import {mapActions, mapGetters} from "vuex";
import VButton from "@/components/VButton";
import {MASK_PHONE} from "@/store/constants"

export default {
  name: "EditPhone",
  components: {VButton, InputCustom},
  data: () => ({
    MASK_PHONE,
    phone: '',
    showButton: false,
    form: new Form({
      first_name: '',
      last_name: '',
      phone: '',
    })
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.updateUser()
  },
  methods: {
    ...mapActions({
      fetchUser:'auth/fetchUser'
    }),
    updateUser() {
      this.form.first_name = this.user.first_name
      this.form.last_name = this.user.last_name
      this.phone = this.user.phone
    },
    getRawPhone(phone) {
      return phone.replace(/\D/g, '')
    },
    handlerInputPhone() {
      this.showButton = this.getRawPhone(this.phone) !== this.user.phone
    },
    async handlerSavePhone() {
      try {
        this.form.phone = this.getRawPhone(this.phone)

        await this.form.put('/api/v1/user')
        await this.fetchUser()
        this.showButton = false
      } catch (e) {
      }
    },
  }
}
