//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClearIcon from "@/components/icons/ClearIcon"

export default {
  name: "VFileInputMultipleDefault",
  components: {ClearIcon},
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Object, String],
      default: () => ({})
    },
    files: {
      type: Array,
      default: () => ([])
    },
    placeholder: {
      type: String,
      default: 'Select file'
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isEmpty() {
      return this.files.length === 0 && this.uploadFilesArr.length === 0
    },
  },
  data: () => ({
    inFocus: false,
    uploadFilesArr: [],
  }),
  methods: {
    getKeyField(itemNum) {
      return `input_field_${itemNum}`
    },
    showDeleteButton() {
      return typeof this.value !== "string"
    },
    handlerAddFiles(event) {
      const files = event.target.files || event.dataTransfer.files

      if (files.length === 0)
        return

      const file = files[0]
      this.uploadFilesArr.push(file)
      this.$emit('change', this.uploadFilesArr)
    },
    handlerDeleteUploadFiles(index) {
      this.uploadFilesArr.splice(index, 1)
      this.$emit('change', this.uploadFilesArr)
    },
    handlerDeleteUploadedFiles(id) {
      this.$emit('delete', id)
    }
  }
}
