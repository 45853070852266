//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapGetters} from "vuex";
import VSelect from '~/components/form/VSelect'

export default {
  components: {
    VSelect,
  },
  model: {
    prop: 'selectedValue',
    event: 'input',
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    selectedValue: {
      type: Object,
      default: () => ({
        question_id: null,
        answer_id: null,
        user_answer: '',
      }),
    },
  },
  data: () => ({
    industries: [],
    questionsAmount: 0,
    userAnswer: '',
    notSaved: {},
    checkedQuestions: [],
    edit: false,
    selected: null,
  }),
  computed: {
    ...mapGetters({
      answersAll: 'account/answers/answers'
    }),
    cardsMode() {
      return this.question.answers.length < 6
    },
    blockClasses() {
      return `amount-${Object.keys(this.question.answers).length}`
    },
  },
  methods: {
    handlerChangeAnswer(id) {
      if (id === this.selectedValue)
        return

      this.$emit('input', {
        question_id: this.question.id,
        answer_id: id,
        user_answer: '',
      })
    },
    selectFormat(list) {
      const formattedList = []
      Object.keys(list).forEach(objectKey => {
        const value = list[objectKey]
        formattedList.push({title: value.text, value: value.id})
      })
      return formattedList
    },
    cardsType() {
      const maxCardsItem = 6
      return this.question.answers.length > maxCardsItem ? 'dropdown' : 'cards'
    },
  }
}
