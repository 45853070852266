//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex'
import { APPROVE_CONTACT } from '@/store/mutation-types'
import StatusDot from "@/components/icons/StatusDot"
import BaseButton from "@/components/buttons/BaseButton"
import ChatContactAvatar from "@/components/chat/ChatContactAvatar"
import storeMixin from "@/components/mixins/storeMixin"
import SilentMode from "@/components/account-franchisor/SilentMode"

import {
  STATUS_TODO,
  STATUS_IN_PROGRESS,
  STATUS_DONE,
  MODAL_VIEW_FRANCHISEE_INFO,
} from '~/store/constants'

export default {
  components: {
    SilentMode,
    ChatContactAvatar,
    BaseButton,
    StatusDot,
  },
  mixins: [
    storeMixin,
  ],
  props: {
    contactList: {
      type: Array,
      default: () => [],
    },
    contactListLoading: {
      type: Boolean,
      default: false,
    },
    minimize: {
      type: Boolean,
      default: false,
    },
    activeContactIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      APPROVE_CONTACT,
      STATUS_TODO,
      STATUS_IN_PROGRESS,
      STATUS_DONE,
    };
  },
  computed: {
    ...mapGetters({
      paymentPermissions: 'payment/permissions',
      user: 'auth/user'
    }),
  },
  methods: {
    ...mapActions({
      approve: 'franchisor-steps/approveFranchisee',
      updateFranchiseeViewedId: 'account-franchisor/updateFranchiseeViewedId',
    }),
    isApproved(contact) {
      return contact.status === STATUS_DONE ? 'Approved':'Approve Next Step'
    },
    handleClickContact(index) {
      this.$emit('contact-chose', index);
    },
    handleApprove(contact) {
      if (this.user.isFranchisor && !this.paymentPermissions.approveStep.access) {
        this.fireModalMessage({ message: this.paymentPermissions.approveStep.message })
        return
      }

      this.approve(contact)
    },
    handleClickOpenModal(contact){
      if (!this.paymentPermissions.franchiseeProfile.access) {
        this.fireModalMessage({ message: this.paymentPermissions.franchiseeProfile.message })
        return
      }

      this.updateFranchiseeViewedId(contact)
      this.$bvModal.show(MODAL_VIEW_FRANCHISEE_INFO)
    },
  },
};
