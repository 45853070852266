//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import StringMask from "string-mask"
import Form from "vform"
import {CONTACT_STATUS_LIST, MASK_PHONE} from "@/store/constants"
import VSelectDefault from "@/components/form/defaultField/VSelectDefault"
import VInputDefault from "@/components/form/defaultField/VInputDefault"
import VButton from "@/components/VButton"

export default {
  name: "ContactInfoForm",
  components: {VButton, VInputDefault, VSelectDefault},
  props: {
    franchiseeId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    fieldsArr: [
      {
        name: 'Phone number',
        slug: 'phone',
        mask: MASK_PHONE,
      },
      {
        name: 'Status',
        slug: 'status',
        mask: '',
      },
      {
        name: 'Location',
        slug: 'location',
        mask: '',
      },
      {
        name: 'Deal Amount',
        slug: 'deal_amount',
        mask: '',
      },
      {
        name: 'Number of units',
        slug: 'number_of_units',
        mask: '',
      },
    ],
    form: new Form({
      phone: '',
      status: '',
      location: '',
      deal_amount: '',
      number_of_units: '',
    }),
    statusArr: CONTACT_STATUS_LIST,
    showFooter: false
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      contact: 'account-franchisor/contacts/selected',
      info: 'account-franchisor/contacts/info',
      franchiseId: 'account-franchisor/franchiseId',
    }),
    showForm() {
      return this.user?.isMasterAdmin ?? false
    },
  },
  watch: {
    contact: {
      deep: true,
      handler() {
        this.fillForm()
      },
    },
    info: {
      deep: true,
      handler() {
        this.fillForm()
      },
    },
  },
  mounted() {
    this.fillForm()
  },
  methods: {
    fillForm() {
      this.fieldsArr.forEach(item => {
        const key = item.slug
        let value = this.info[key] ?? ''
        if (value === '' && key === 'phone') {
          value = this.contact[key] ?? ''
        }

        if (key === 'phone' && typeof value !== "number") {
          value = value.replace(/[^0-9\.]+/g, "")
        }

        if (key === 'phone' && value !== ''){
          value = (new StringMask(MASK_PHONE)).apply(value)
        }

        if (!this.showForm && key === 'status') {
          this.statusArr.forEach(variant => {
            if (Number(value) === Number(variant.value))
              value = variant.text
          })
        }

        if (!this.showForm && (value === '' || value === null))
          value = '-'
        this.form[key] = value
      })
    },
    async sendForm() {
      try {
        const {data} = await this.form.submit(
          'post',
          `/api/v2/contact/${this.franchiseId}/${this.franchiseeId}/update`,
          {
            headers: {'Content-Type': 'multipart/form-data'},
            transformRequest: [(dataSend) => {
              const formData = new FormData()
              Object.keys(dataSend).forEach(key => {
                let value = dataSend[key]
                if (key === 'phone') {
                  value = value.replace(/[^0-9]/g, "")
                }
                formData.append(key, value)
              })
              return formData
            }],
          }
        )
        if (data) {
          this.showFooter = false
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
}
