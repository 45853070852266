//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VSwatches from "vue-swatches";
import {cloneDeep} from "lodash/fp";
import landingStyles from "@/components/mixins/landingStyles";
import EditPencil from "@/assets/icons/landing/editor/EditPencil.svg?inline";
import BlankBlock from "@/components/landing/blank/BlankBlock";
import VInputV3 from "@/components/form/VInputV3";
import {mapGetters} from "vuex";
import FileInputCustom from "@/components/form/customField/FileInputCustom";
import Form from "vform";

export default {
  name: "LandingButton",
  components: {
    FileInputCustom,
    VInputV3,
    EditPencil,
    VSwatches,
  },
  mixins: [
    landingStyles
  ],
  model: {
    prop: 'html',
    event: 'input',
  },
  props: {
    css: {
      default: '',
    },
    html: {
      type: Object,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      result: null,
      editing: false,
      toolbar: '',
      content: '',
      document: {},
      backup: '',
      backupState: true,
      formAttributes: new Form({
        document: null,
      }),
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      franchise: 'landing/franchise',
      modalIds: 'landing/modal_ids',
    }),
    switcherId() {
      return Math.floor(Math.random() * 999999);
    },
    linkEditable() {
      return this.user.isMasterAdmin;
    },
  },
  created() {
    this.content = cloneDeep(this.html);
  },
  methods: {
    start() {
      this.editing = true;
    },
    async save() {
      if (this.formAttributes.document) {
        await this.submitDocument();
      }
      const output = cloneDeep(this.content);
      this.$emit('input', output);
      this.editing = false;
    },
    cancel() {
      this.content = cloneDeep(this.html);
      this.editing = false;
    },
    addDocument(file) {
      if (file) {
        this.document = {filename: file.name};
        this.formAttributes.document = file;
      }
    },
    deleteDocument() {
      this.document = {};
    },
    async submitDocument() {
      try {
        const url = `api/v2/landing/${this.franchise.franchiseId}/${this.franchise.versionId}/document`;
        this.$toast.info('Upload file');
        const {data} = await this.formAttributes.submit('post', url, {
          headers: {'Content-Type': 'multipart/form-data'},
          transformRequest: [function (data) {
            const formData = new FormData;
            formData.append(`document`, data.document);
            return formData;
          }],
        });

        this.content.url = this.documentUrl(data.result.id);
        this.content.item = data.result.id;
      } catch (e) {
        console.log(e);
      }
    },
    documentUrl(id) {
      return `${process.env.BACKEND_BASE_URL}api/v2/landing/${id}/document`;
    },
    openUrl() {
      const url = this.content.url;
      if (url) {
        if (this.editable || this.content.type !== 'file') {
          window.open(url, '_blank');
        } else if (this.modalIds.contact) {
          this.$store.dispatch('landing/setModalFileUrl', url);
          this.$bvModal.show(this.modalIds.contact);
        }
      }
    }
  }
}
