export default {
  methods: {
    getSlide (id) {
      let slideTpl = `
      <div id="landing-slide-__ID__" class="landing-slide-__ID__ swiper-slide">
        <div class="row slide-row slide-row-x">
          <div class="pages-container pages-container-top">
            <div class="cell slide-cell slide-cell-x make-mobile">
              <h2>
                <div class="h2-slide-text h2-slide h2-slide-x">New Slide
                </div>
                <div class="h2-slide-text-tagline h2-slide-tagline h2-slide-x-tagline">YOUR TAGLINE HERE
                </div>
              </h2>
              <div class="btn blue-btn blue-btn-1">START NOW
              </div>
            </div>
            <div class="cell slide-cell-2 slide-cell-x-2">
              <img class="top-bg-img bg-slide-image bg-image-x" src="https://storage.googleapis.com/internal-shared-fdp-staging/business_files/grapesjs-user-assets/public/home-page-hero_1905x835.jpg" id="ib3j1u" class="top-bg-img"/>
              <div class="form-block make-mobile">
                <h3>contact us
                </h3>
                <form action="">
                  <input type="text" placeholder="first name*" required/>
                  <input type="text" placeholder="last name*" required/>
                  <input type="text" placeholder="phone*" required/>
                  <input type="text" placeholder="email*" required/>
                  <span><label for="header-terms">by clicking SEND I <span draggable="true" data-highlightable="1" class="gjs-selected">agree to the </span></label><a target="_blank" href="/terms" class="link link-blue term-of-use">terms and
                            conditions</a><span>*</span></span>
                  <button>send</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
     `;
      return slideTpl.replace(/__ID__/g, id);
    }
  }
}
