//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from "@/components/buttons/BaseButton";
import { mapGetters } from "vuex";

export default {
  components: {BaseButton},
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    goAccount() {
      this.$emit('account');
    },
    publish() {
      this.$emit('publish');
    }
  }
}
