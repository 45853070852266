//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StatusDot',
  props: {
    wrapperColor: {
      type: String,
      default: () => 'rgba(0, 167, 105, 0.2)',
    },
    backgroundColor: {
      type: String,
      default: () => '#00a769'
    },
    wrapperOpacity: {
      type: String,
      default: '1'
    }
  },
};
