//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import CheckMobileIcon from 'assets/icons/check/CheckMobileIcon.svg?inline'


export default {
  name: "FranchisorAccountProgress",
  components: {
    CheckMobileIcon
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      franchise: 'business-v2/franchise',
    }),
    calculateProgress() {
      let count = 0

      if (this.landingCheck)
        count += 1

      if (this.mainCheck)
        count += 1

      if (this.quickFactsCheck)
        count += 1

      if (this.filesCheck)
        count += 1

      if (this.seoCheck)
        count += 1

      return count
    },
    landingCheck() {
      if (
        this.franchise.public_website_business_permalink === '' ||
        this.franchise.public_website_business_permalink === null
      )
        return false

      return true
    },
    mainCheck() {
      if (this.franchise.tags.length === 0)
        return false

      if (
        this.franchise.company_twitter_username === '' ||
        this.franchise.company_twitter_username === null
      )
        return false

      if (
        this.franchise.location === '' ||
        this.franchise.location === null
      )
        return false

      if (
        this.franchise.company_short_description === '' ||
        this.franchise.company_short_description === null
      )
        return false

      if (
        this.franchise.company_description === '' ||
        this.franchise.company_description === null
      )
        return false

      if (
        this.franchise.company_stage === '' ||
        this.franchise.company_stage === null
      )
        return false

      if (this.franchise.founders.length === 0)
        return false


      const checkFounders = this.franchise.founders.map(founder => {
        if (typeof founder?.avatar?.id === "undefined")
          return false

        if (
          founder.first_name === '' ||
          founder.first_name === null
        )
          return false

        if (
          founder.last_name === '' ||
          founder.last_name === null
        )
          return false

        if (
          founder.description === '' ||
          founder.description === null
        )
          return false

        return true
      })

      if (checkFounders.filter(item => item === true).length === 0)
        return false

      return true
    },
    quickFactsCheck() {
      if (
        this.franchise.business_name === '' ||
        this.franchise.business_name === null
      )
        return false

      if (
        this.franchise.established === '' ||
        this.franchise.established === null
      )
        return false

      if (
        this.franchise.investment.low === '' ||
        this.franchise.investment.low === null ||
        this.franchise.investment.low === 0
      )
        return false

      if (
        this.franchise.investment.high === '' ||
        this.franchise.investment.high === null ||
        this.franchise.investment.high === 0
      )
        return false

      if (
        this.franchise.franchise_fee.low === '' ||
        this.franchise.franchise_fee.low === null ||
        this.franchise.franchise_fee.low === 0
      )
        return false

      if (
        this.franchise.franchise_fee.high === '' ||
        this.franchise.franchise_fee.high === null ||
        this.franchise.franchise_fee.high === 0
      )
        return false

      if (
        this.franchise.minimum_credit_score === '' ||
        this.franchise.minimum_credit_score === null
      )
        return false

      if (
        this.franchise.franchising_since === '' ||
        this.franchise.franchising_since === null
      )
        return false

      if (
        this.franchise.minimum_liquid_assets === '' ||
        this.franchise.minimum_liquid_assets === null
      )
        return false

      if (
        this.franchise.naics === '' ||
        this.franchise.naics === null
      )
        return false

      if (
        this.franchise.total_net_worth === '' ||
        this.franchise.total_net_worth === null
      )
        return false

      if (
        this.franchise.average_revenue === '' ||
        this.franchise.average_revenue === null
      )
        return false

      if (
        this.franchise.gross_profit === '' ||
        this.franchise.gross_profit === null
      )
        return false

      if (
        this.franchise.number_of_locations === '' ||
        this.franchise.number_of_locations === null
      )
        return false

      if (
        this.franchise.main_royalty_fee === '' ||
        this.franchise.main_royalty_fee === null
      )
        return false

      if (
        this.franchise.main_royalty_fee_percent === '' ||
        this.franchise.main_royalty_fee_percent === null
      )
        return false

      if (
        this.franchise.main_royalty_fee_percent === '' ||
        this.franchise.main_royalty_fee_percent === null
      )
        return false

      if (
        this.franchise.industry === '' ||
        this.franchise.industry === null
      )
        return false

      if (this.franchise.states.length === 0)
        return false


      return true
    },
    filesCheck() {
      if(typeof this.franchise.files.fdd?.id === "undefined")
        return false

      return true
    },
    seoCheck() {
      if (
        this.franchise.seo_meta_title === '' ||
        this.franchise.seo_meta_title === null
      )
        return false

      if (
        this.franchise.seo_meta_keywords === '' ||
        this.franchise.seo_meta_keywords === null
      )
        return false

      if (
        this.franchise.seo_meta_description === '' ||
        this.franchise.seo_meta_description === null
      )
        return false

      return true
    },
  }
}
