import { render, staticRenderFns } from "./LandingHeader.vue?vue&type=template&id=5cbc166e&scoped=true&"
import script from "./LandingHeader.vue?vue&type=script&lang=js&"
export * from "./LandingHeader.vue?vue&type=script&lang=js&"
import style0 from "./LandingHeader.vue?vue&type=style&index=0&id=5cbc166e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbc166e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingLogo: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingLogo.vue').default,LandingMenu: require('E:/all-projects/franchise-digital-platform-spa/components/landing/LandingMenu.vue').default})
