//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseSocialSignUpButton from './BaseSocialSignUpButton'

export default {
  components: {
    BaseSocialSignUpButton
  },
  props: {
    buttonText: {
      type: String,
      default: 'Sign Up With LinkedIn'
    },
    userType: {
      type: Number,
      default: null
    }
  }
}
