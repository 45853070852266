//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import {
  mapActions,
  mapGetters,
} from "vuex"
import LinkedInSignUpButton from "@/components/buttons/LinkedInSignUpButton"
import GoogleSignUpButton from "@/components/buttons/GoogleSignUpButton"
import VButton from "@/components/VButton"
import VInputDefault from "@/components/form/defaultField/VInputDefault"
import LogoWithTradeMark from "~/components/LogoWithTradeMark"

import hubspotMixin from "~/components/mixins/hubspotMixin"
import googleAnalyticsMixin from "~/components/mixins/googleAnalyticsMixin"

import {
  MODAL_SIGN_UP_FRANCHISE_ID,
  SIGN_UP_FRANCHISE_FAST_STEP_THREE,
  SIGN_UP_FRANCHISE_FAST_STEP_TWO,
  USER_TYPE_FRANCHISEE,
  REGISTRATION_DEFAULT_PASSWORD,
  SIGN_UP_FRANCHISE_FAST_STEP_FOUR,
  CLOSED_FORM_TYPES,
} from '@/store/constants'


export default {
  name: "SignUpFranchiseStepOne",
  components: {
    VInputDefault,
    LogoWithTradeMark,
    VButton,
    GoogleSignUpButton,
    LinkedInSignUpButton,
  },
  mixins: [
    googleAnalyticsMixin,
    hubspotMixin,
  ],
  data: () => {
    return {
      submitted: false,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: REGISTRATION_DEFAULT_PASSWORD,
        password_confirmation: REGISTRATION_DEFAULT_PASSWORD,
        busy: false,
        user_type: USER_TYPE_FRANCHISEE
      }),
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      enableFlowBeginToday: 'registration/enableFlowBeginToday',
      enableFlowPendingList: 'registration/enableFlowPendingList',
      pendingListEmail: 'registration/pendingListEmail',
      pendingListArr: 'account/pendingListArr',
    }),
    userType() {
      return USER_TYPE_FRANCHISEE
    },
  },
  mounted() {
    if (this.pendingListEmail !== '')
      this.form.email = this.pendingListEmail
  },
  async destroyed () {
    if (!this.submitted && this.form.email) {
      await this.hsClosedForm(this.form.email, CLOSED_FORM_TYPES.signUpFranchisee)
    }
  },
  methods: {
    ...mapActions({
      associateUserWithRating: 'rating/associateUserWithRating',
      updatePendingList: 'account/updatePendingList',
      pendingListSendArr: 'registration/pendingListSendArr',
      savePendingList: 'registration/savePendingList',
    }),
    async registerFranchisee() {
      try {
        const {data} = await this.form.post('/api/register')

        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires,
        })
        this.submitted = true
        await this.gaSendFranchiseSignUpForm()

        await this.$store.dispatch('auth/fetchUser')
        const step = this.enableFlowBeginToday ? SIGN_UP_FRANCHISE_FAST_STEP_FOUR : SIGN_UP_FRANCHISE_FAST_STEP_TWO
        await this.$emit('change-step', step)
        await this.associateUserWithRating()

        if (this.pendingListSendArr){
          this.savePendingList()
          this.updatePendingList([])
        }
      } catch (e) {
      }
    },
    async onAuthenticated(isNewUser = true) {

      if (isNewUser) {
        await this.$emit('change-step', this.enableFlowBeginToday ? SIGN_UP_FRANCHISE_FAST_STEP_FOUR : SIGN_UP_FRANCHISE_FAST_STEP_THREE)
        await this.associateUserWithRating()
        return
      }

      this.$bvModal.hide(MODAL_SIGN_UP_FRANCHISE_ID)
      await this.$router.push('/account/my-matches')
    },
    onError(message) {
      this.errorMessage = message

      if (!this.form.errors.any()) {
        this.form.errors.set({error: message})
      }
    }
  }
}
