import { render, staticRenderFns } from "./LandingFooter.vue?vue&type=template&id=81b9386e&scoped=true&"
import script from "./LandingFooter.vue?vue&type=script&lang=js&"
export * from "./LandingFooter.vue?vue&type=script&lang=js&"
import style0 from "./LandingFooter.vue?vue&type=style&index=0&id=81b9386e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81b9386e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingShare: require('E:/all-projects/franchise-digital-platform-spa/components/landing/LandingShare.vue').default,Footer: require('E:/all-projects/franchise-digital-platform-spa/components/Footer.vue').default})
