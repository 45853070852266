//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CHAT_CONTACT_STATUS_ONLINE } from '@/store/constants';

export default {
  props: {
    avatarSrc: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: CHAT_CONTACT_STATUS_ONLINE,
    },
  },
};
