//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from 'vuex'

import ArrowBack from "@/components/icons/ArrowBack"
import ContactInfoForm from "@/components/form/ContactInfoForm"
import SilentMode from "~/components/account-franchisor/SilentMode"
import ModalViewFranchisee from "~/components/modals/franchisor/ModalViewFranchisee"

import {
  MODAL_VIEW_FRANCHISEE_INFO,
} from '~/store/constants'

import storeMixin from "~/components/mixins/storeMixin"

export default {
  name: 'ContactInfo',
  components: {
    ContactInfoForm,
    SilentMode,
    ModalViewFranchisee,
    ArrowBack,
  },
  mixins: [
    storeMixin,
  ],
  props: {
    franchiseeId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      paymentPermissions: 'payment/permissions',
      contact: 'account-franchisor/contacts/selected',
      ownerData: 'account-franchisor/ownerData',
      busy: 'account-franchisor/contacts/busy',
      franchiseId: 'account-franchisor/franchiseId',
    }),
  },
  mounted() {
    this.fetchContact({franchisee_id: this.franchiseeId})

    this.fetchCurrentContact({
      franchiseId: this.franchiseId,
      userFranchiseeId: this.franchiseeId
    })

  },
  methods: {
    ...mapActions({
      updateFranchiseeViewedId: 'account-franchisor/updateFranchiseeViewedId',
      fetchContact: 'account-franchisor/contacts/fetchContactsSelected',
      fetchCurrentContact: 'account-franchisor/contacts/fetchCurrentContact',
      fetchVersionInfo: 'account-franchisor/fetchVersionInfo',
    }),
    getValue(value, defaultValue = '-') {
      return !value || value === '' ? defaultValue : value
    },
    handleClickOpenModal(contact) {
      if (!this.paymentPermissions.franchiseeProfile.access) {
        this.fireModalMessage({message: this.paymentPermissions.franchiseeProfile.message})
        return
      }

      this.updateFranchiseeViewedId({
        id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        full_name: contact.full_name,
        initials: contact.initials,
        silent_account: contact.silent_account,
        franchisee_id: contact.id,
      })
      this.$bvModal.show(MODAL_VIEW_FRANCHISEE_INFO)
    },
  }
}
