//
//
//
//
//
//

export default {
  name: 'AccountStrengthStar',
  props: {
    color: {
      type: String,
      default: '#00A769'
    }
  }
}
