//
//
//
//


export default {
  props: {
    title: {
      type: String,
      default: 'Industry name'
    },
    slug: {
      type: String,
      default: ''
    }
  },
  computed: {
    imgRaw() {
      try {
        return require(`~/assets/images/sign-in-icons/icon-${this.slug}.svg?raw`);
      } catch (e) {
        return require(`~/assets/images/sign-in-icons/icon-other-franchises.svg?raw`);
      }
    }
  },
}
