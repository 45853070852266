import { render, staticRenderFns } from "./ChatArea.vue?vue&type=template&id=6b0ced82&scoped=true&"
import script from "./ChatArea.vue?vue&type=script&lang=js&"
export * from "./ChatArea.vue?vue&type=script&lang=js&"
import style0 from "./ChatArea.vue?vue&type=style&index=0&id=6b0ced82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b0ced82",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatContactAvatar: require('E:/all-projects/franchise-digital-platform-spa/components/chat/ChatContactAvatar.vue').default,ChatInput: require('E:/all-projects/franchise-digital-platform-spa/components/chat/ChatInput.vue').default})
