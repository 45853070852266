//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DownloadIcon',
  props: {
    color: {
      type: String,
      default: () => '#00A769'
    }
  }
}
