//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import GoogleSignUpButton from '@/components/buttons/GoogleSignUpButton'
import LinkedInSignUpButton from '@/components/buttons/LinkedInSignUpButton'
import VInputV2 from '@/components/form/VInputV2'

import stringMixin from '@/components/mixins/stringMixin'
import {
  USER_TYPE_FRANCHISEE,
  USER_TYPE_FRANCHISOR,
  USER_TYPE_FRANCHISOR_TITLE,
  REGISTRATION_DEFAULT_PASSWORD,
  CLOSED_FORM_TYPES,
} from '~/store/constants'

import hubspotMixin from "~/components/mixins/hubspotMixin"
import {MASK_PHONE} from "@/store/constants"

export default {
  components: {
    GoogleSignUpButton,
    LinkedInSignUpButton,
    VInputV2,
  },
  mixins: [
    stringMixin,
    hubspotMixin,
  ],
  props: {
    nextStage: {
      type: String,
      required: true,
    },
    userType: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    submitted: false,
    USER_TYPE_FRANCHISEE,
    USER_TYPE_FRANCHISOR,
    MASK_PHONE,
    rawPhone: '',
    errorMessage: '',
    form: new Form({
      last_name: '',
      first_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      busy: false,
      user_type: '',
      onKeydown: () => {
        this.register()
      },
    })
  }),
  computed: {
    isRouteSignUp() {
      return this.$route.path === '/' && this.$route.query.signUp === USER_TYPE_FRANCHISOR_TITLE
    },
  },
  created() {
    this.fillForm()
    if (this.isRouteSignUp) {
      this.form.password = REGISTRATION_DEFAULT_PASSWORD
      this.form.password_confirmation = REGISTRATION_DEFAULT_PASSWORD
    }
  },
  async destroyed() {
    if (!this.submitted && this.form.email) {
      await this.hsClosedForm(
        this.form.email,
        CLOSED_FORM_TYPES.signUpFranchisor,
        this.form.first_name,
        this.form.last_name,
        this.rawPhone
      )
    }
  },
  methods: {
    fillForm() {
      const params = this.$route.query

      if (params.franchisee_first_name) {
        this.form.first_name = params.franchisee_first_name

        const newPass = this.generatePassword()
        this.form.password = newPass
        this.form.password_confirmation = newPass
      }
      if (params.franchisee_last_name) {
        this.form.last_name = params.franchisee_last_name
      }
      if (params.franchisee_email) {
        this.form.email = params.franchisee_email
      }
      if (params.franchisee_phone) {
        this.form.phone = params.franchisee_phone
        this.rawPhone = params.franchisee_phone
      }
    },
    generatePassword() {
      return Math.random().toString(36).substring(2)
    },
    async register() {
      try {
        let rawPhone = ''
        if (this.rawPhone) {
          rawPhone = this.rawPhone.replace(/[^0-9\.]+/g, '')
        }

        this.form.user_type = this.userType
        this.form.phone = rawPhone

        // save broker referall {
        const params = this.$route.query
        const utmCampaignList = [
          'Franchise123',
        ]
        if (utmCampaignList.includes(params.utm_campaign)) {
          const referral = new Form({
            'email': this.form.email,
            'utm_campaign': params.utm_campaign,
          })
          await referral.post('/api/v1/broker/add')
        }
        // save broker referall }

        const {data} = await this.form.post('/api/register')

        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires,
        })
        await this.$store.dispatch('auth/fetchUser')

        this.form.reset()
        this.$refs.registerForm.reset()
        this.submitted = true

        this.onAuthenticated()
      } catch (e) {
        if (e.hasOwnProperty('response') && e.response.status !== 422) {
          this.$emit('close')
        }
      }
    },
    onAuthenticated(isNewUser = true) {
      isNewUser
        ? this.setNextStage()
        : this.$store.dispatch('registration/closeModal')
    },
    onError(message) {
      this.errorMessage = message

      if (!this.form.errors.any()) {
        this.form.errors.set({error: message})
      }
    },
    setNextStage() {
      this.$router.push({path: this.$route.path, query: {step_register_franchisor: '2'}})
      this.$store.dispatch('registration/setStage', this.nextStage)
    },
  },
}
