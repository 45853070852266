//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep';
import {WORK_BOOK} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";
import ArrowCodeIcon from '~/assets/icons/arrows/ArrowCodeIcon.svg?inline'

const TAB_TYPES = {
  list: 'list',
  item: 'item',
  total: 'total',
}

export default {
  name: "WorkBookCandidates",
  components: {
    ArrowCodeIcon,
  },
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    totalCounter: [],
    currentTabIndex: 0,
    tabTypes: TAB_TYPES,
    listTabs: [
      {
        name: 'List of Candidates',
        type: TAB_TYPES.list
      },
    ],
    defaultListTabs:[
      {
        name: 'List of Candidates',
        type: TAB_TYPES.list
      },
    ]
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      topList: 'account/workbook/topList',
      candidates: 'account/workbook/candidates',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    currentTab() {
      return this.listTabs[this.currentTabIndex]
    },
    getTotal() {
      const answersList = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
      let counter = 0
      answersList.forEach((answer) => {
        counter += Number(answer.value[this.currentTabIndex - 1] ?? 0)
      })
      return counter
    },
    answersBlock() {
      return this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
    },
  },
  watch:{
    currentWorkBookIndex(){
      this.updatedListTabs()
    },
  },
  mounted() {
    this.updatedListTabs()
  },
  destroyed() {
    this.listTabs = this.defaultListTabs
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
      updateListCandidates: 'account/workbook/updateListCandidates',
    }),
    getValueAnswer(questionIndex,candidateIndex){
      return Number(this.answersBlock[questionIndex].value[candidateIndex]) ?? 0
    },
    getTotalCount(candidateIndex) {
      let count = 0
      this.answersBlock.forEach(question => {
        count += Number(question.value[candidateIndex] ?? 0)
      })
      return count
    },
    handlerUpdateCandidates(indexCandidate, fullNameCandidates) {
      this.updateListCandidates(
        {
          indexCandidate,
          fullNameCandidates
        }
      )
      this.updatedListTabs()
    },
    updatedListTabs() {
      this.listTabs = cloneDeep(this.defaultListTabs)

      this.candidates.forEach((candidate, candidateIndex) => {
        const candidateObj = {
          name: candidate,
          type: this.tabTypes.item,
          index: candidateIndex
        }
        if (typeof this.listTabs[candidateIndex + 1] === "undefined" && candidate !== '')
          this.listTabs.push(candidateObj)

        if (typeof this.listTabs[candidateIndex + 1] !== "undefined" && candidate !== '')
          this.listTabs[candidateIndex + 1] = candidateObj

        if (typeof this.listTabs[candidateIndex + 1] !== "undefined" && candidate === '')
          this.listTabs.splice(candidateIndex + 1, 1)
      })

      if (this.candidates.filter(candidate => candidate !== '').length === this.candidates.length) {
        this.listTabs.push({
          name: 'Total',
          type: this.tabTypes.total
        })
      }
    },
    getValue(questionId, answerIndex) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
      return savedValue.value[answerIndex]
    },
    handlerUpdateValue(questionId, answerIndex, value) {
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)
      savedValue.value[answerIndex] = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },
  }
}
