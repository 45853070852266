//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CardTopFranchise",
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getItemLink() {
      return `/top-franchises/${this.item.slug}`
    },
    getText() {
      return this.item?.preview_content ?? ''
    },
    getImageUrl() {
      return this.item.logo_long?.url ?? ''
    },
  }
}
