//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Swiper as SwiperClass, Lazy} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import BlogPostImage from "@/components/blog/BlogPostImage"
import 'swiper/swiper.min.css'

SwiperClass.use([Lazy])
getAwesomeSwiper(SwiperClass)
const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

export default {
  name: "BlogHome",
  components: {
    BlogPostImage,
    Swiper,
    SwiperSlide,
  },
  props: {
    posts: {
      default: null,
      type: [Array, Boolean]
    },
  },
  computed: {
    homeSlider() {
      return this.$refs.homeSlider.$swiper
    },
    swiperOption() {
      return {
        init: false,
        updateOnWindowResize: true,
        slidesPerView: 1.35,
        spaceBetween: 57,
        loop: true,
        lazy: true,
        preloadImages: false,
        watchSlidesVisibility: true,
        breakpoints: {
          '768': {
            slidesPerView: 'auto',
          },
          '1200': {
            slidesPerView: 2,
          },
        }
      }
    },
  },
  mounted() {
    this.homeSlider.init()
  },
  methods: {
    getAuthorName(post) {
      const externalLink = post.external_link
      const externalName = post.external_author
      const defaultName = post?.author?.full_name

      if (externalLink) {
        return externalName ?? ''
      }
      return defaultName ?? ''
    },
    changeSlide(prev = false) {
      if (prev)
        this.homeSlider.slidePrev()
      else
        this.homeSlider.slideNext()
    },
    async routeHandler(externalLink) {
      if (externalLink) {
        window.open(externalLink, '_blank')
      }
    },
    getPostEvent(externalLink) {
      return externalLink ? '' : 'click'
    },
  },
}
