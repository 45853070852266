//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Cropper, Preview} from 'vue-advanced-cropper';
import VSwatches from 'vue-swatches'
import ArrowIcon from "@/assets/icons/arrows/ArrowIcon.svg?inline";
import EyeNotIcon from "@/assets/icons/EyeNotIcon.svg?inline";
import EyeIcon from "@/assets/icons/EyeIcon.svg?inline";
import ImageIcon from "@/assets/icons/ImageIcon.svg?inline";
import ImageClearIcon from "@/assets/icons/ImageClearIcon.svg?inline";
import 'vue-swatches/dist/vue-swatches.css'
import {mapActions, mapGetters} from "vuex";
import landingActions from "@/components/mixins/landingActions";

export default {
  name: "SectionTools",
  components: {Cropper, Preview, ArrowIcon, EyeIcon, EyeNotIcon, ImageIcon, ImageClearIcon, VSwatches},
  mixins: [
    landingActions,
  ],
  model: {
    prop: 'section',
    event: 'input',
  },
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    active: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    containerSize: {
      type: Object,
      default: () => ({
        width: 0,
        height: 0,
      })
    }
  },
  data() {
    return {
      color: '',
      openTools: false,
      file: {
        coordinates: null,
        type: null,
        canvas: null,
        image: null,
        preview: null,
      },
      window: {
        width: 0
      },
    }
  },
  computed: {
    ...mapGetters({
      maxIndex: 'landing/max_index',
    }),
    widthPreview() {
      return this.containerSize.width + 200
    },
    paddingLeftPreview() {
      return `${(this.window.width - this.widthPreview) / 2}px`
    },
    imgUrl() {
      return `${process.env.BACKEND_BASE_URL}api/v2/landing/${this.content.url}/image`;
    },
  },
  created() {
    this.color = this.section.styles.backgroundColor ?? '';
  },
  mounted() {
    if (process.client)
      this.window.width = window.innerWidth
  },
  methods: {
    ...mapActions({
      updateSection: 'landing/updateSection'
    }),
    openColorPicker() {
      this.openTools = true
    },
    async closeColorPicker(color) {
      this.openTools = false
      this.section.styles.backgroundColor = color
      await this.$store.dispatch('landing/updateSection', {
        section: this.section,
        index: this.currentIndex
      })
    },
    onChangeCropper({coordinates, image, canvas}) {
      this.file.coordinates = coordinates
      this.file.preview = image
      this.file.canvas = canvas.toDataURL(this.file.type)
    },
    cancelCropper() {
      this.$refs.cropperBlock.reset()
      this.$refs.cropperInput.value = null
      this.file = {
        coordinates: null,
        type: null,
        canvas: null,
        image: null,
        preview: null,
      }
    },
    async saveCropper() {
      const resultSave = await this.saveFile(this.file.canvas, this.file.type)

      if (!resultSave)
        return

      this.cancelCropper()

      this.section.styles.backgroundImage = resultSave

      await this.$store.dispatch('landing/updateSection', {
        section: this.section,
        index: this.currentIndex
      })
    },
    clearBackgroundImage(){
      if (typeof this.section.styles.backgroundImage === 'undefined' || this.section.styles.backgroundImage === '')
        return
      this.$store.dispatch('landing/clearBackgroundImageSection',this.currentIndex)
    },
    changeVisibility() {
      this.$store.dispatch('landing/changeSectionVisibility', this.currentIndex)
    },
    changePosition(putDown = false) {
      const index = putDown ? this.currentIndex + 1 : this.currentIndex - 1
      if (index < 0 || index > this.maxIndex)
        return

      this.$store.dispatch('landing/changeSectionPosition', {fromIndex: this.currentIndex, toIndex: index})
    },
    loadFile(e) {
      if (!this.section.visible)
        return
      const files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        const file = files[0]
        this.file.type = file.type
        this.openTools = true
        const reader = new FileReader();

        reader.onload = (event) => {
          this.file.image = event.target.result;
        }
        reader.readAsDataURL(file)
      }
    },
  }
}
