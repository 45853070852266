//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CardIndustriesCobalt from "@/components/cards/CardIndustriesCobalt";
import CardInvestmentCobalt from "@/components/cards/CardInvestmentCobalt";
import CardUSMapCobalt from "@/components/cards/CardUSMapCobalt";
import ClearIcon from "@/components/icons/ClearIcon";
import {mapActions, mapGetters} from 'vuex'
import {MODAL_PREFERENCE_FRANCHISEE_ID, USER_PREFERENCES_TOTAL_AMOUNT_INVEST} from '~/store/constants'
import FilterIcon from '~/assets/icons/FilterIcon.svg?inline'

export default {
  components: {
    ClearIcon,
    CardIndustriesCobalt,
    CardUSMapCobalt,
    CardInvestmentCobalt,
    FilterIcon,
  },
  props: {
    tab: {
      type: Number,
      default: 0
    },
    isBlocked: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    modalId: MODAL_PREFERENCE_FRANCHISEE_ID,
    investmentDirectory: USER_PREFERENCES_TOTAL_AMOUNT_INVEST,
    timer: null,
  }),
  computed: {
    ...mapGetters({
      preferencesIndustries: 'account/preferencesIndustries',
      preferencesStates: 'account/preferencesStates',
      preferencesInvestment: 'account/preferencesInvestment',
      franchiseTotalCounter: 'account/franchiseTotalCounter',
    }),
    headerTitle() {
      if (this.tab === 0)
        return 'Edit Favorite Franchises'

      if (this.tab === 1)
        return 'Edit Favorite States'

      if (this.tab === 2)
        return 'Edit total amount you are willing to invest'

      return ''
    },
    headerCounter() {
      let selected = ''
      let canSelect = ''
      let label = ''

      if (this.tab === 0) {
        selected = this.preferencesIndustries.length
        canSelect = 5 - selected
        label = 'Industries'
      }

      if (this.tab === 1) {
        selected = this.preferencesStates.length
        canSelect = 3 - selected
        label = 'States'
      }

      if (this.tab === 2) {
        selected = this.investmentDirectory.filter(item => item.value === this.preferencesInvestment).length
        canSelect = 1 - selected
        label = 'Investment'
      }

      return `You selected <span>${selected}</span> ${label}. You can select <span>${canSelect}</span> more`
    },
  },
  watch: {
    preferencesIndustries() {
      this.updateData()
    },
    preferencesStates() {
      this.updateData()
    },
    preferencesInvestment() {
      this.updateData()
    },
  },
  methods: {
    ...mapActions({
      fetchStates: 'directories/fetchStates',
      updatePreferencesStates: 'account/updatePreferencesStates',
      savePreferences: 'account/savePreferences',
      fetchPreferences: 'account/fetchPreferences',
      fetchFranchises: 'account/fetchFranchises',
      updateCurrentPage: 'account/updateCurrentPage',
    }),
    updateData() {
      if (this.timer !== null)
        clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.fetchFranchises(true)
        this.timer = null
      }, 1000)
    },
    async handlerApply(){
      await this.savePreferences()
      await this.updateCurrentPage(1)
      await this.$router.push(this.$route.path)
      await this.fetchFranchises()
      this.$bvModal.hide(this.modalId)
    },
    handlerClose(){
      this.fetchPreferences()
    },
  }
}
