//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseIcon from '@/assets/icons/cross/CloseIcon.svg?inline';
import FileWithOverlayIcon from '~/assets/images/svg/file-with-overlay.svg?inline';


export default {
  components: {
    FileWithOverlayIcon,
    CloseIcon
  },
  props: {
    fileName: {
      type: String,
      default: '',
    },
  },
};
