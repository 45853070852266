//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import VuePassword from 'vue-password';
export default {
  components: {
    // VuePassword
  },
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    topLabel: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.id = this._uid;
  },
  data() {
    return {
      password: "",
      id: null
    };
  },
  methods: {
    changePassword(value) {
      this.$emit("input", value);
    }
  }
};
