//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapGetters,
} from 'vuex'
import Form from 'vform'
import Loader from '@/components/Loader'
import storeMixin from '@/components/mixins/storeMixin'
import infoIcon from '@/assets/images/svg/info-icon.svg?inline'

export default {
  components: {
    Loader,
    infoIcon,
  },
  mixins: [
    storeMixin,
  ],
  data() {
    return {
      busy: false,
      tplUrl: `https://admin.franchise123.com/api/v2/long-import/template/franchisees`,
      file: null,
      imported: false,
    }
  },
  computed: {
    ...mapGetters({
      franchiseId: 'account-franchisor/franchiseId',
      versionId: 'account-franchisor/versionId',
      paymentPermissions: 'payment/permissions',
      versionApproved: 'account-franchisor/versionApproved',
    }),
  },
  beforeDestroy() {
    this.imported = false
  },
  methods: {
    formatBackendResponce(message) {
      return message.replaceAll('\n','').replace(/\s+/g,' ').trim()
    },
    async loadFile(e) {
      this.busy = true

      const files = e.target.files || e.dataTransfer.files;
      const leadFile = files[0]
      const leadForm = new Form()
      const leadUrl = `/api/v2/long-import/import-franchisees/${this.franchiseId}/${this.versionId}`
      const headers = {'Content-Type': 'multipart/form-data'}

      try {
        const { data } = await leadForm.submit(
          'post',
          leadUrl, {
          headers,
          transformRequest: [() => {
            const formData = new FormData;
            formData.append(`file`, leadFile)
            return formData
          }],
        });
        const message = this.formatBackendResponce(data.message)
        this.$toast.success(message)
      } catch (e) {
        this.$toast.error(e.response.data.message)
        console.log(e)
      }

      this.resetFileInput()
      this.imported = true
      this.busy = false
    },
    async checkPermissions(e) {
      const permissionType = this.paymentPermissions.uploadLead
      let allowAction = true
      let message = ''

      if (!this.versionApproved) {
        allowAction = false
        message = this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')
      }

      if (!permissionType.access) {
        allowAction = false
        message = permissionType.message
      }

      if (!allowAction) {
        await this.fireModalMessage({ message })
        e.preventDefault()
        return false
      }

      return true
    },
    resetFileInput() {
      document.getElementById('lead-file-input').value=''
    },
  },
}
