//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {MASK_PHONE, MODAL_EDIT_FRANCHISEE_ID} from "@/store/constants"
import Form from 'vform'
import {mapActions, mapGetters} from 'vuex'
import VButton from "@/components/VButton"
import ClearIcon from "@/components/icons/ClearIcon"
import VAutoComplete from '../form/VAutoComplete'
import VInput from '../form/VInput'
import VMaskedInput from '../form/VMaskedInput'
import VTextArea from '../form/VTextArea'
import Avatar from '../nav/Avatar'

export default {
  components: {
    ClearIcon,
    VButton,
    Avatar,
    VAutoComplete,
    VInput,
    VMaskedInput,
    VTextArea
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    MASK_PHONE,
    MODAL_EDIT_FRANCHISEE_ID,
    status: '',
    message: '',
    contentClass: 'custom-content-class',
    location: {
      id: null,
      name: null
    },
    rawPhone: null,
    form: new Form({
      first_name: '',
      last_name: '',
      phone: '',
      location: '',
      location_place_id: '',
      linked_in_link: '',
      short_descr: '',
      busy: false
    }),
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      items: 'business/data',
      liked: 'business/liked'
    })
  },
  metaInfo() {
    return {title: this.title}
  },
  created() {
    this.setUserData()
  },
  methods: {
    ...mapActions({
      updateUser: 'account/updateUser',
    }),
    async save() {
      try {
        this.form.phone = this.rawPhone ? this.rawPhone.split('-').join('') : ''

        const {data} = await this.form.put('/api/v1/user')
        await this.$store.dispatch('auth/updateUser', {user: data.result})
        await this.updateUser(data.result)
        this.$bvModal.hide(MODAL_EDIT_FRANCHISEE_ID)
      } catch (e) {
        if (e.response.status === 400 && e.response.data.status === 'fail') {
          this.status = e.response.data.status
          this.message = e.response.data.message
        } else if (e.response.status !== 422) {
          this.$bvModal.hide(MODAL_EDIT_FRANCHISEE_ID)
        }
      }
    },
    setAlertFields(message = '', status = '') {
      this.message = message
      this.status = status
    },
    discard() {
      this.setUserData()
      this.$bvModal.hide(MODAL_EDIT_FRANCHISEE_ID)
    },
    setLocation(data) {
      this.form.location = data.name
      this.form.location_place_id = data.id
    },
    setUserData() {
      this.form.keys().forEach(key => {
        this.form[key] = this.user[key]
      })

      this.rawPhone = this.user.phone

      this.location = {
        id: this.user.location_place_id ? this.user.location_place_id : null,
        name: this.user.location ? this.user.location : null
      }
    },
    locationAutocompleteSource(search) {
      const params = `search=${search}&locationType=city`

      return `${this.$axios.defaults.baseURL}/api/v1/autocomplete/maps/geocoder?${params}`
    },
  }
}
