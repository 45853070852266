//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WorkBookInput from "@/components/workbook/elements/WorkBookInput";
import {WORK_BOOK, WORK_BOOK_TOP_LIST_KEYS, WORK_BOOK_FIELD_TYPES} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "WorkBookMarketingToolkit",
  components: {
    WorkBookInput,
  },
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    currentFranchiseIndex: 0,
    fieldTypes: WORK_BOOK_FIELD_TYPES,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      topList: 'account/workbook/topList',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    answersBlock() {
      return this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    getFranchiseName(franchiseIndex) {
      let listIndex = WORK_BOOK_TOP_LIST_KEYS.TEN

      if (this.numberTop === 5)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.FIVE

      if (this.numberTop === 3)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.THREE

      if (this.numberTop === 2)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.TWO

      if (typeof this.topList[listIndex] === "undefined")
        return ''

      return this.topList[listIndex][franchiseIndex] ? this.topList[listIndex][franchiseIndex].franchise : `Franchise #${franchiseIndex + 1}`
    },
    getValue(questionId, itemIndex, fieldIndex) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)

      return savedValue.value[itemIndex][slug]
    },
    handlerUpdateValue(questionId, itemIndex, fieldIndex, value) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)
      savedValue.value[itemIndex][slug] = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },
    getTotal() {
      let sum = 0
      const fieldWithScore = this.questionsBlock.fields.find((field) => {
        if (typeof field.isScore !== "undefined")
          return field.slug
        return false
      })


      this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.forEach(answer => {
        sum += Number(answer.value[this.currentFranchiseIndex][fieldWithScore.slug] ?? 0)
      })
      return sum
    },
    addVendor(questionIndex) {
      const currentValue = cloneDeep(this.answersBlock[questionIndex].value)

      const defaultObject = {}
      this.questionsBlock.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      currentValue.push(defaultObject)

      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: currentValue})
    },
    getStyles(field) {
      return field?.styleList
    }
  }
}
