//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function() {
    return {
      isOpen: undefined,
    }
  },
  props: ['title', 'search', 'content']
}
