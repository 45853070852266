//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RatingStars from '@/components/RatingStars';
import ArrowCodeIcon from '@/assets/icons/arrows/ArrowCodeIcon.svg?inline';
import { MODAL_RATING_FRANCHISE_ID, MODAL_SIGN_UP_FRANCHISE_ID } from '@/store/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FormEditRating',
  components: {
    RatingStars,
    ArrowCodeIcon,
  },
  data: () => ({
    modalId: MODAL_RATING_FRANCHISE_ID,
    currentStepIndex: 0,
    answers: [],
    busy: false,
    activeNextButton: false,
  }),
  computed: {
    ...mapGetters({
      ratingQuestions: 'rating/ratingQuestions',
      ratingToken: 'rating/ratingToken',
      ratingChannel: 'rating/ratingChannel',
      relatedFranchiseName: 'rating/relatedFranchiseName',
      check: 'auth/check',
    }),
    title() {
      return this.currentStepIndex === this.lastStepIndex ? 'Thank you!' : 'Rate franchise';
    },
    stepTitle() {
      return this.currentStepIndex < this.lastStepIndex ? this.currentStepIndex + 1 : false;
    },
    question() {
      return this.currentStepIndex < this.lastStepIndex
        ? this.ratingQuestions[this.currentStepIndex].text
        : false;
    },
    lastStepIndex() {
      return this.ratingQuestions.length;
    },
    disableConfirmButton() {
      if (this.busy) return true;

      return this.answers.filter((answer) => Number(answer) === 0).length !== 0;
    },
  },
  watch: {
    ratingQuestions(newVal) {
      this.fillDefaultAnswers(newVal);
    },
    answers: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.length !== oldVal.length) return;

        if (this.currentStepIndex + 1 === this.lastStepIndex) return;

        this.activeNextButton = true;
      },
    },
  },
  created() {
    this.fillDefaultAnswers(this.ratingQuestions);
  },
  methods: {
    ...mapActions({
      updateSurveyRatingUserData: 'rating/updateSurveyRatingUserData',
      updateStatusRateIt: 'rating/updateStatusRateIt',
      associateUserWithRating: 'rating/associateUserWithRating',
    }),
    fillDefaultAnswers(questions) {
      if (questions.length === 0) return;

      this.answers = new Array(questions.length).fill(0);
    },
    async handlerSend() {
      this.busy = true;
      const answers = [];

      this.ratingQuestions.forEach((question, questionIndex) => {
        answers.push({
          question_id: question.id,
          rating_value: this.answers[questionIndex],
        });
      });

      try {
        const url = `api/v2/business-survey-rating/${this.ratingToken}/answers?channel=${this.ratingChannel}`;

        await this.$axios.post(url, { answers });
        this.updateStatusRateIt(false);
        if (this.check) await this.associateUserWithRating();
      } catch (e) {}

      if (this.check) {
        this.$bvModal.hide(this.modalId);
        return;
      }

      this.busy = false;
      this.currentStepIndex = this.lastStepIndex;
    },
    async changeStep(next = false) {
      if (next) {
        setTimeout(() => {
          this.$refs.buttonNext.blur();
        }, 500);
        this.activeNextButton = false;
        this.currentStepIndex += 1;
      }

      if (!next) {
        setTimeout(() => {
          this.$refs.buttonPrev.blur();
        }, 500);
        this.currentStepIndex -= 1;
      }
      this.$forceUpdate();
    },
    handlerUpdateAnswer(value) {
      this.answers[this.currentStepIndex] = value;
    },
    handlerOpenSignUp() {
      this.$bvModal.hide(this.modalId);
      this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID);
    },
  },
};
