//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RatingStars from "@/components/RatingStars";
import {MODAL_RATING_FRANCHISE_ID} from "@/store/constants";
import {mapGetters} from "vuex";

export default {
  name: "CompanyRating",
  components: {
    RatingStars,
  },
  props: {
    rating: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    modalRatingId: MODAL_RATING_FRANCHISE_ID,
    modal: {
      message: 'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please use another email to sign up as a Franchisee.',
      isShow: false
    },
  }),
  computed: {
    ...mapGetters({
      enableRateIt: 'rating/enableRateIt',
      relatedFranchiseId: 'rating/relatedFranchiseId',
      user: 'auth/user',
      authenticated: 'auth/check',
      franchise: 'company/company',
    }),
    title() {
      return this.showRating ? "Rating" : 'Want to start cooperation with this company'
    },
    showRating(){
      return this.rating.length > 0
    },
    showButtonRateIt(){
      return this.enableRateIt && this.franchise.id === this.relatedFranchiseId
    },
  },
  methods: {
    arithmeticMeanRating() {
      if (!this.showRating)
        return 0

      const rating = this.rating;
      const meanRating = rating.reduce((total, item) => {
        return total + parseFloat(item.rating_value)
      }, 0) / rating.length;

       return Math.round(meanRating*10)/10
    },
  }
}
