//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform';
import { mapGetters } from 'vuex';
import hubspotMixin from '@/components/mixins/hubspotMixin';
import VButton from '@/components/VButton';
import storeMixin from '@/components/mixins/storeMixin';
import VInputV2 from '@/components/form/VInputV2';
import CloseIcon from '~/assets/icons/cross/CloseIcon.svg?inline';
import googleAnalyticsMixin from '~/components/mixins/googleAnalyticsMixin';

import {
  REGISTRATION_DEFAULT_PASSWORD,
  SIGN_UP_FRANCHISE_FAST_STEP_TWO,
  REGISTRATION_DEFAULT_FIRST_NAME,
  REGISTRATION_DEFAULT_LAST_NAME,
  USER_TYPE_FRANCHISEE,
  MODAL_SIGN_UP_FRANCHISE_ID,
} from '~/store/constants';

export default {
  name: 'PdfBook',
  components: {
    CloseIcon,
    VButton,
    VInputV2,
  },
  mixins: [hubspotMixin, storeMixin, googleAnalyticsMixin],
  props: {
    default: {
      type: Boolean,
      default: true,
    },
    directory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      view: {
        default: 0,
        directory: 1,
      },
      form: new Form({
        email: null,
        first_name: REGISTRATION_DEFAULT_FIRST_NAME,
        last_name: REGISTRATION_DEFAULT_LAST_NAME,
        password: REGISTRATION_DEFAULT_PASSWORD,
        password_confirmation: REGISTRATION_DEFAULT_PASSWORD,
        user_type: USER_TYPE_FRANCHISEE,
      }),
      activePart: 0,
      parts: {
        formPart: 0,
        congratsPart: 1,
      },
      showModal: false,
      congratsModal: false,
      USER_TYPE_FRANCHISEE,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuth: 'auth/check',
    }),
    currentView() {
      return this.directory ? this.view.directory : this.view.default;
    },
    enableModal() {
      return this.isAuth;
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.congratsModal = false;
      this.activePart = this.parts.formPart;
    },
    async registerFranchisee() {
      try {
        const { data } = await this.form.post('/api/register');
        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires,
        });
        this.submitted = true;
        await this.gaSendFranchiseSignUpForm();
        await this.$store.dispatch('auth/fetchUser');
        this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID);
        this.$store.commit(
          'registration/SET_REGISTRATION_FRANCHISEE_STAGE',
          SIGN_UP_FRANCHISE_FAST_STEP_TWO
        );
      } catch (e) {
        console.log(e);
      }
      this.congratsModal = true;
    },
    congratsConfPart() {
      if (this.isAuth) {
        this.activePart = this.parts.congratsPart;
        this.congratsModal = true;
      }
    },
    async submit() {
      try {
        const result = await this.hsEbookContactForm(this.form.email);
        if (result.ok) {
          this.formSent = true;
          if (!this.isAuth) {
            await this.registerFranchisee();
          }
          this.showModal = false;
          this.congratsConfPart();
        }
        this.form = result.form;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
