//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import Form from 'vform'
import VInputV2 from '~/components/form/VInputV2'
import VButton from '~/components/VButton'
import VCheckBoxV2 from '~/components/form/VCheckBoxV2'
import VTextAreaV2 from '~/components/form/VTextAreaV2'
import HubspotMixin from '~/components/mixins/hubspotMixin'

export default {
  name: 'ContactFormPcmania',
  components: {
    VTextAreaV2,
    VCheckBoxV2,
    VButton,
    VInputV2,
  },
  mixins: [
    HubspotMixin,
  ],
  props: {
    formClass: {
      type: String,
      default: '',
    },
    userType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: '',
      message: '',
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        agree: false,
        busy: false,
      }),
    }
  },
  methods: {
    async submit() {
      this.form.busy = true
      const result = await this.hsContactFormPcmania(this.form)
      this.setAlertFields(result.msg, result.status)
      this.form.busy = false
    },
    setAlertFields(message = '', status = '') {
      this.message = message
      this.status = status
    },
  },
}
