//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

import Form from 'vform'
import MultiSelect from 'vue-multiselect'
import BaseModal from './BaseModal'
import VInput from '~/components/form/VInput'
import VInputNumber from '~/components/form/VInputNumber'
import VSelect from '~/components/form/VSelect'
import BaseButton from "../buttons/BaseButton";
import { LANDING_FONT_LIST, FONT_STYLES, FONT_WEIGHTS } from "~/store/constants";

export default {
  components: {
    BaseButton,
    BaseModal,
    MultiSelect,
    VInput,
    VInputNumber,
    VSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    franchise: {
      type: Object,
      default: null
    },
    accountId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    updated: false,
    bodyClass: 'modal-franchise-edit',
    modalSize: 'xl',
    investmentTip: 'Investment numbers based on 1 unit',
    formData: new Form(),
    formAttributes: new Form({
      franchise_name: '',
      established: '',
      investment_min: '',
      investment_max: '',
      franchise_fee_min: '',
      franchise_fee_max: '',
      minimum_credit_score: '',
      franchising_since: '',
      minimum_liquid_assets: '',
      naics: '',
      total_net_worth: '',
      average_revenue: '',
      gross_profit: '',
      number_of_locations: '',
      royalty: '',
      royalty_percent: '',
      website_url: '',
      industry: '',
      short_descr: '',
      states: [],
      landing_phone: '',
      landing_navbar_font: '',
      landing_navbar_font_style: '',
      landing_navbar_font_weight: '',
      promo_video_link: '',
      fdd: '',
      item_7: '',
      item_19: '',
      franchisees: '',
      presentation: '',
      delete_file_ids: [],
    }),
    preselectedFiles: {
      logo: null,
      logo_landing: null,
      fdd: null,
      item_7: null,
      item_19: null,
      franchisees: null,
      presentation: null
    },
    options: {
      established: [],
      franchisingSince: [],
      industry: [],
      navbarFonts: [],
      navbarFontStyles: [],
      navbarFontWeights: [],
      states: []
    },
    selectId:'franchising-since',
    optionsGroup:[{
      allStates : 'All States',
      components: []
    }],
    statesGroup: [],
  }),
  computed: {
    ...mapState({
      industriesDirectory: state => state.directories.industries,
      statesDirectory: state => state.directories.states,
      franchiseId: state => state.registration.franchiseId,
    }),
  },
  watch: {
    show (value) {
      if (!value) {
        this.delete_file_ids = []
      }
    },
    statesGroup (value) {
      const states = [];

      if (value.length) {
        value.forEach(item => {
          states.push(item.value)
        })
      }

      this.formAttributes.states = states
    },
    async franchise () {
      this.updateAttributes()
    }
  },
  async beforeMount () {
    this.options.established = this.generateRangeDate()
    this.options.franchisingSince = this.generateRangeDate(1925)

    await this.fetchStates()
    this.statesDirectory.forEach(el => {
      this.options.states.push({
        title: `${el.name}, ${el.code}`,
        value: el.id
      })
    })

    LANDING_FONT_LIST.forEach(el => {
      this.options.navbarFonts.push({
        title: el,
        value: el
      })
    })
    FONT_STYLES.forEach(el => {
      this.options.navbarFontStyles.push({
        title: el,
        value: el
      })
    })
    FONT_WEIGHTS.forEach(el => {
      this.options.navbarFontWeights.push({
        title: el,
        value: el
      })
    })

    await this.fetchIndustries()
    this.options.industry = this.industriesDirectory
    this.updateAttributes()
    this.optionsGroup[0].components = [ ...this.options.states ]
  },
  methods: {
    ...mapActions({
      fetchIndustries: 'directories/fetchIndustries',
      fetchStates: 'directories/fetchStates',
    }),
    websiteEditAlert() {
      if (process.browser){
        alert('No Landing page was created. Please create a landing page. The link will be filled in here after you save and publish it.');
      }
    },
    updateAttributes() {
      this.updated = false
      const franchise = this.mapFranchise(this.franchise)
      this.statesGroup = []
      this.preselectedFiles = {
        logo: null,
        logo_landing: null,
        fdd: null,
        item_7: null,
        item_19: null,
        franchisees: null,
        presentation: null,
      }

      if (Object.keys(franchise).length) {
        Object.keys(franchise).forEach(key => {
          if (key === 'states') {
            franchise[key].forEach(state => {
              const item = this.options.states.find(item => item.value === state.id)
              this.statesGroup.push(item)
            })
          } else if (key === 'files') {
            Object.keys(franchise[key]).forEach(fileType => {
              this.preselectedFiles[fileType] = franchise[key][fileType]
            })
          } else if (key === 'logo') {
            this.preselectedFiles.logo = franchise[key]
          } else if (key === 'logo_landing') {
            this.preselectedFiles.logo_landing = franchise[key]
          } else {
            this.formAttributes[key] = franchise[key]
          }
        })
      }

      this.updated = true
    },
    mapFranchise (data) {
      const mapped = data.data.map(b => {
        return {
          franchise_name: b.business_name ? b.business_name.attribute_value_string :'',
          established: b.established.attribute_value_string
            ? b.established.attribute_value_string.substr(0, 4)
            : '',
          investment_min: b.investment.attribute_value_numeric_low,
          investment_max: b.investment.attribute_value_numeric_high,
          franchise_fee_min: b.franchise_fee.attribute_value_numeric_low,
          franchise_fee_max: b.franchise_fee.attribute_value_numeric_high,
          franchising_since: b.franchising_since.attribute_value_string
            ? b.franchising_since.attribute_value_string.substr(0, 4)
            : '',
          naics: b.NAICS ? b.NAICS.attribute_value_string : '',
          royalty: b.main_royalty_fee ? b.main_royalty_fee.attribute_value_string : '',
          royalty_percent: b.main_royalty_fee_percent ? b.main_royalty_fee_percent.attribute_value_string : '',
          website_url: b.public_website_business_permalink ? b.public_website_business_permalink.attribute_value_string : '',
          industry: b.industry ? b.industry.attribute_value_string : '',
          short_descr: b.short_descr ? b.short_descr.attribute_value_string : '',
          minimum_credit_score: b.minimum_credit_score ? b.minimum_credit_score.attribute_value_string : '',
          minimum_liquid_assets: b.minimum_liquid_assets.attribute_value_string ? b.minimum_liquid_assets.attribute_value_string : '',
          average_revenue: b.average_revenue ? b.average_revenue.attribute_value_string : '',
          gross_profit: b.gross_profit ? b.gross_profit.attribute_value_string : '',
          total_net_worth: b.total_net_worth ? b.total_net_worth.attribute_value_string : '',
          landing_phone: b.landing_phone ? b.landing_phone.attribute_value_string : '',
          landing_navbar_font: b.landing_navbar_font ? b.landing_navbar_font.attribute_value_string : '',
          landing_navbar_font_style: b.landing_navbar_font_style ? b.landing_navbar_font_style.attribute_value_string : '',
          landing_navbar_font_weight: b.landing_navbar_font_weight ? b.landing_navbar_font_weight.attribute_value_string : '',
          promo_video_link: b.video ? b.video.attribute_value_string : '',
          number_of_locations: b.number_of_locations ?  b.number_of_locations.attribute_value_string : '',
          files: b.files,
          logo: b.logo,
          logo_landing: b.logo_landing,
          states: b.states
        }
      })

      return mapped[0]
    },
    async submit () {
      try {
        let url = `/api/v1/business/edit/${this.franchise.data[0].franchise_id}/franchise`

        if (this.accountId) {
          url = `${url}?accountId=${this.accountId}`
        }
        const self = this

        this.formAttributes.established = this.formAttributes.established === null ?
          '' :this.formAttributes.established;
        this.formAttributes.franchising_since = this.formAttributes.franchising_since === null ?
          '' :this.formAttributes.franchising_since;

        await this.formAttributes.submit('post', url, {
          headers: { 'Content-Type': 'multipart/form-data'},
          transformRequest: [ function (data, headers) {
            const formData = new FormData
            for (const prop in data) {
              if (prop !== 'states') {
                formData.append(prop, data[prop])
              }
            }

            data.states.forEach((el, i) => formData.append(`states[${  i  }]`, el) );
            data.delete_file_ids.forEach(
              (el, i) => formData.append(`delete_file_ids[${  i  }]`, el)
            );
            return formData;
          }],
        })

        this.$emit('saved')
      } catch (e) {
        if (e.hasOwnProperty('response') && e.response.status !== 422) {
          this.$emit('close')
        }
      }
    },
    generateRangeDate (minYear = 1920) {
      const currentYear = new Date().getFullYear()
      const maxYear = currentYear + 1
      const ranges = maxYear - minYear

      return Array(ranges)
        .fill()
        .map((_, i) => {
          return {
            title: String(i + minYear),
            value: String(i + minYear)
          }
        })
    },
    changeFileInput (key, value) {
      if (
        this.preselectedFiles[key]
        && this.preselectedFiles[key].id
        && !this.formAttributes.delete_file_ids.includes(this.preselectedFiles[key].id)
      ) {
        this.formAttributes.delete_file_ids.push(this.preselectedFiles[key].id)
      }
      this.formAttributes[key] = value
    }
  }
}
