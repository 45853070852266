//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseBtn from '~/components/buttons/BaseButton.vue'
export default {
  components: {
    BaseBtn
  },
  data: () => ({
    cookieBlockShow: 0,
    checkUser:false
  }),
  mounted () {
     this.checkCookie();
  },
  methods: {
    agreeCookie (state) {
      this.setCookie('cookieBlockShow', state, '365')
      this.cookieBlockShow = state
    },
    getCookie (name) {
      let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
      if (match) return match[2]
    },
    setCookie (name, value, days) {
      let expires = ''
      if (days) {
        const date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/'
    },
    checkCookie() {
      this.cookieBlockShow = this.getCookie('cookieBlockShow');
      this.checkUser = !this.cookieBlockShow;
    }
  }
}
