//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from "lodash/cloneDeep"
import LandingSectionHero from "@/components/landing/sections/LandingSectionHero"
import LandingSectionVideo from "@/components/landing/sections/LandingSectionVideo"
import LandingSectionImage from "@/components/landing/sections/LandingSectionImage"
import LandingSectionCollapse from "@/components/landing/sections/LandingSectionCollapse"
import LandingSectionGallery from "@/components/landing/sections/LandingSectionGallery"
import LandingSectionComments from "@/components/landing/sections/LandingSectionComments"
import LandingSectionStats from "@/components/landing/sections/LandingSectionStats"
import LandingSectionFacts from "@/components/landing/sections/LandingSectionFacts"
import LandingSectionPlayList from "@/components/landing/sections/LandingSectionPlayList"

import LandingHeader from "@/components/landing/LandingHeader"
import LandingFooter from "@/components/landing/LandingFooter"
import LandingContactForm from "@/components/form/LandingContactForm"
import ClearIcon from "@/components/icons/ClearIcon"
import LandingMenu from "@/components/landing/LandingMenu"
import LandingControl from "@/components/landing/LandingControl"
import {mapActions, mapGetters} from "vuex"
import LandingStickyButton from "@/components/landing/elements/LandingStickyButton"
import googleAnalyticsMixin from "~/components/mixins/googleAnalyticsMixin"
import hubspotMixin from "~/components/mixins/hubspotMixin"
import styleImportMixin from "~/components/mixins/styleImportMixin"

export default {
  name: 'CompanyPageLandingNew',
  components: {
    LandingStickyButton,
    LandingMenu,
    ClearIcon,
    LandingContactForm,
    LandingFooter,
    LandingHeader,
    LandingSectionPlayList,
    LandingSectionFacts,
    LandingSectionStats,
    LandingSectionComments,
    LandingSectionGallery,
    LandingSectionCollapse,
    LandingSectionImage,
    LandingSectionVideo,
    LandingSectionHero,
    LandingControl,
  },
  mixins: [
    hubspotMixin,
    googleAnalyticsMixin,
    styleImportMixin,
  ],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: null,
      defaultMenu: [
        {
          name: "Concept",
          sectionIndex: "our_concept"
        },
        {
          name: "Investment",
          sectionIndex: "investment"
        },
        {
          name: "Earnings",
          sectionIndex: "earnings"
        },
        {
          name: "Next Steps",
          sectionIndex: "next_steps"
        },
        {
          name: "Insight",
          sectionIndex: "insight"
        },
        {
          name: "Videos",
          sectionIndex: "videos"
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      franchise:'landing/franchise',
      modalIds: 'landing/modal_ids',
      landing: 'landing/landing',
      mainStyles: 'landing/main_styles',
    }),
  },
  watch: {
    landing(newVal){
      this.content = cloneDeep(newVal)
    },
  },
  created() {
    this.content = cloneDeep(this.landing)
    this.importStyle(`/gjs/css/fonts.css`)
  },
  methods: {
    ...mapActions({
      fetchFranchise: 'landing/fetchFranchise'
    }),
    showSection(section) {
      if (this.editable)
        return true

      return section.visible
    },
    openAccount() {
      window.location = '/account-franchisor'
    },
    async publish() {
      await this.$store.dispatch('landing/saveLanding',1)
    },
    openModal() {
      this.$bvModal.hide(this.modalIds.mobileMenu)
      this.$bvModal.show(this.modalIds.contact)
    },
  },
  head() {
    const title = `${this.franchise.name} landing`
    const descr = `${this.franchise.name} landing page`
    const keywords = ''
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ],
    }
  },
}
