//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "CompanyInnovationCurve",
  model: {
    event: 'modified',
    props: 'stage'
  },
  props: {
    stage: {
      type: String,
      default: null
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      steps: [
        {
          value: '2.5%',
          title: 'Innovators'
        },
        {
          value: '13.5%',
          title: 'Early Adopters'
        },
        {
          value: '34%',
          title: 'Early Majority'
        },
        {
          value: '34%',
          title: 'Late Majority'
        },
        {
          value: '16%',
          title: 'Laggards'
        }
      ],
      currentStage: this.stage
    }
  },
  methods: {
    checkStepActivity(stepTitle) {
      return stepTitle
          && this.currentStage
          && (stepTitle.toLowerCase() === this.currentStage.toLowerCase());
    },
    selectStage(stage) {
      this.$emit('change')
      if (!this.editMode)
        return
      this.currentStage = stage;
      this.$emit("modified", stage);
    }
  }
}
