//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  WORK_BOOK,
  WORK_BOOK_COUNTABLE_CHAPTER,
  WORK_BOOK_FIELD_TYPES,
  WORK_BOOK_QUESTION_TYPES,
  WORK_BOOK_TOP_LIST_KEYS
} from "@/store/constants";
import {mapGetters} from "vuex";

export default {
  name: "WorkBookInput",
  props: {
    value: {
      type: [String, Number]
    },
    field: {
      type: Object,
      default: () => ({
        type: ''
      })
    },
    index: {
      type: Number,
      default: 0
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fieldTypes: WORK_BOOK_FIELD_TYPES,
  }),
  computed: {
    ...mapGetters({
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
      workBooks: 'account/workbook/workBooks',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
    }),
    selectLabel() {
      return this.field.selectLabel ?? `Select...`
    },
  },
  methods: {
    handlerUpdateValue(event) {
      let newValue = event.target.value

      if (
        this.field.type === this.fieldTypes.CHECKBOX ||
        this.field.type === this.fieldTypes.CHECKBOX_NOT_LABEL
      )
        newValue = event.target.checked ? event.target.value : ''

      this.$emit('change', newValue)
    },
    getRelationTitle(field) {
      if (typeof this.topList[field.relation.key] === "undefined")
        return ''

      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(this.topList[field.relation.key][this.index]))
      if (typeof currentFranchise === "undefined")
        return ''

      return currentFranchise[field.relation.index]
    },
    findBlockAnswers(sectionId, chapterId, blockId) {
      const findSection = this.workBooks[this.currentWorkBookIndex].answers.find(section => section.id === sectionId)
      const findChapter = findSection.chapters.find(chapter => chapter.id === chapterId)
      return findChapter.questionsBlocks.find(block => block.id === blockId)
    },
    findBlockQuestions(sectionId, chapterId, blockId) {
      const findSection = WORK_BOOK.find(section => section.id === sectionId)
      const findChapter = findSection.chapters.find(chapter => chapter.id === chapterId)
      return findChapter.questionsBlocks.find(block => block.id === blockId)
    },
    getRelationScore(){
      let counter = 0

      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(this.topList[WORK_BOOK_TOP_LIST_KEYS.THREE][this.index]))
      if (currentFranchise === "undefined")
        return counter

      const franchiseIndex = this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE].findIndex(item => Number(item) === Number(currentFranchise.id))
      if (franchiseIndex === null)
        return counter

      const countableChapters = WORK_BOOK_COUNTABLE_CHAPTER[WORK_BOOK_TOP_LIST_KEYS.FIVE]


      countableChapters.forEach(item => {
        const blockAnswers = this.findBlockAnswers(item.sectionId, item.chapterId, item.blockId)

        if (blockAnswers.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_TOP_TEN) {
          blockAnswers.answers.forEach(answer => {
            counter += Number(answer.value[franchiseIndex] ?? 0)
          })
        }

        if (blockAnswers.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY) {
          const questionsBlock = this.findBlockQuestions(item.sectionId, item.chapterId, item.blockId)

          const fieldWithScore = questionsBlock.fields.find((field) => {
            if (typeof field.isScore !== "undefined")
              return field.slug
            return false
          })

          counter += Number(blockAnswers.answers[franchiseIndex].value[fieldWithScore.slug] ?? 0)
        }

        if (blockAnswers.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ADDITIONAL) {
          const questionsBlock = this.findBlockQuestions(item.sectionId, item.chapterId, item.blockId)

          const fieldWithScore = questionsBlock.fields.find((field) => {
            if (typeof field.isScore !== "undefined")
              return field.slug
            return false
          })
          blockAnswers.answers.forEach(answer => {
            if (typeof answer.value[franchiseIndex] === "undefined")
              return

            counter += Number(answer.value[franchiseIndex][fieldWithScore.slug] ?? 0)
          })
        }
      })


      return counter
    },
  }
}
