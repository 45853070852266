export default {
	methods:{
		lostConnection(e){

			if(e.message === 'Network Error'){
				this.$toast.warning("Lost connection");
			}
		}
	}
}
