//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    percent: {
      type: Number,
      default: 51,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      circumstance: null,
    };
  },
  computed: {
    dashArray() {
      return `${this.circumstance} ${this.circumstance}`;
    },
    dashOffset() {
      return  this.circumstance - this.percent / 100 * this.circumstance;
    },
  },
  mounted() {
    const el = document.querySelector('.progress-percentage');
    const radius = el.r.baseVal.value;
    this.circumstance = 2 * Math.PI * radius;
  },
};
