//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DownloadButton from "@/components/buttons/DownloadButton";
import BaseModal from "./BaseModal";

export default {
  components: {
    BaseModal,
    DownloadButton
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    businessStepId: {
      type: Number,
      default: 0
    },
    stepName: {
      type: String,
      default: null
    },
    franchiseId: {
      type: Number,
      default : 0
    }
  },
  data() {
    return {
      documents: {},
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },

    /**
     *
     * @param document
     * @returns {Promise<void>}
     */
    async download(document) {
      const {data} = await this.$axios.get(
        `/api/v1/business-step-docs/download/${document.step_doc_id}`,
      { responseType: 'arraybuffer' }
      );
      const file = new Blob([data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL)
    },

    async getDocuments() {
      try {
        const url = `/api/v1/business-step-docs/${this.businessStepId}`;
        const { data } = await this.$axios.get(url);
        this.documents = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
