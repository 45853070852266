//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import storeMixin from "@/components/mixins/storeMixin"
import ChatInputFile from "@/components/chat/ChatInputFile"
import ScraperIcon from '~/assets/images/svg/scraper.svg?inline'
import BaseButton from "@/components/buttons/BaseButton"
import uploadFileMixin from '@/components/mixins/uploadFileMixin'
import ConfirmModal from '@/components/modals/ConfirmModal';

export default {
  components: {
    ConfirmModal,
    BaseButton,
    ChatInputFile,
    ScraperIcon,
  },
  mixins: [
    storeMixin,
    uploadFileMixin
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    silentAccount: {
      type: Boolean,
      default: false,
    },
    targetUserId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    showDisabledNotificationModal: false,
    disabledNotification: true,
    messageText: '',
    enableNotificationText: `Do you want this silent franchisee to become active? He will start notifications about new messages and other activities`,
    disabledText: `This step is completed. To talk to Franchisor, proceed to the active step.`,
    inputIsEditable: true,
    chosenFiles: [],
    filesFormData: null,
    validFileMimeTypes: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/octet-stream',
      'application/vnd.rar',
      'application/x-rar-compressed',
      'application/x-rar',
      'application/pdf',
      'application/zip',
      'image/jpeg',
      'image/png',
    ],
    validMaxFileSize: 20 * 1024 * 1024,
    fileType: [
      'pdf',
      'jpg',
      'jpeg',
      'png',
      'docx',
      'xlsx',
      'zip',
      'rar',
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      paymentPermissions: 'payment/permissions'
    }),
    sendMsgButtonEnable() {
      if (this.disabled)
        return false

      if(this.chosenFiles.length !== 0)
        return true

      return this.messageText.length !== 0 && this.messageText.length < 519
    },
    messageIsEmpty() {
      return !this.messageText.trim()
    },
    allowInput() {
      if (this.disabled)
        return false

      return this.inputIsEditable
    },
  },
  methods: {
    async confrimEnableNotification() {
      this.closeNotificationModal()
      try {
        await this.$axios.patch(`/api/v2/users/${this.targetUserId}/activate-silent-franchisee`)
        this.disabledNotification = false
      } catch (e) {
        console.error(e)
      }
    },
    cancelEnableNotification() {
      this.closeNotificationModal()
    },
    closeNotificationModal() {
      this.showDisabledNotificationModal = false
    },
    checkAccount(e) {
      if (this.silentAccount && this.disabledNotification) {
        e.preventDefault()
        this.showDisabledNotificationModal = true
      }
    },
    /**
     * Send message to API
     */
    sendMessage() {
      if (this.disabled || !this.sendMsgButtonEnable)
        return

      this.$emit('send-message', {
        text: this.messageText,
        files: this.chosenFiles,
      })
      this.inputIsEditable = true
    },
    /**
     * Message input handler
     *
     * @param {InputEvent} e - oninput event
     * @param {EventTarget} e.target - target input
     */
    handleInputMessage(e) {
      if (!this.allowInput)
        return

      if (this.user.isFranchisor && !this.paymentPermissions.sendChatMessage.access) {
        this.fireModalMessage(
          {message: this.paymentPermissions.sendChatMessage.message}
        )
        this.clearMessageInput()

        return
      }

      this.messageText = e.target.value
    },
    /**
     * Clear messages
     */
    clearMessageInput() {
      this.messageText = ''
      this.chosenFiles = []
      this.$refs.uploadFile.value = ''
      this.$refs.chatInputTyping.value = ''
      this.$forceUpdate()
    },
    /**
     * Show popup selection files
     */
    showChoseDialogFile() {
      if (this.user.isFranchisor && !this.paymentPermissions.sendChatFile.access) {
        this.fireModalMessage(
          {message: this.paymentPermissions.sendChatFile.message}
        )
        return
      }

      this.$refs.uploadFile.click()
    },
    /**
     * Chose file handler
     * @param {Event} e file change event
     * @param {EventTarget} e.target - target file input
     */
    handleFileChose(e) {
      this.chosenFiles = Array.from(e.target.files)
      this.inputIsEditable = false
      if (e.target.files[0]) {
        const fileName = this.fileType.includes(e.target.files[0].name.split('.').pop())
        const fileSize = e.target.files[0].size / (1000000) <= 20
        this.$emit('invalid', fileName, fileSize)
      }
    },
    /**
     * Remove file by index
     * @param index
     */
    handleRemoveFile(index) {
      this.$emit('remove-file', index)
      this.chosenFiles.splice(index, 1)
      this.inputIsEditable = true
      this.$refs.uploadFile.value = ''
    },
  },
}
