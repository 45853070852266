//
//
//
//
//
//
//

export default {
  props: {
    color: {
      type: String,
      default: '#212A4B'
    },
    title: {
      type: String,
      default: ''
    }
  }
}
