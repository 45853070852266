//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import landingStyles from "@/components/mixins/landingStyles"
import hubspotMixin from "@/components/mixins/hubspotMixin"
import googleAnalyticsMixin from "@/components/mixins/googleAnalyticsMixin"
import VInputV3 from "@/components/form/VInputV3"
import {mapGetters} from "vuex"
import BaseButton from "@/components/buttons/BaseButton"
import Loader from "@/components/Loader"

export default {
  name: "LandingContactForm",
  components: {
    Loader,
    BaseButton,
    VInputV3,
  },
  mixins: [
    landingStyles,
    hubspotMixin,
    googleAnalyticsMixin,
  ],
  props: {
    buttonStyles: {
      type: Object,
      default: () => ({
        fontFamily: 'Inter, sans-serif',
        backgroundColor: '#00a769',
        color: '#ffffff',
      })
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false,
    }
  },
  data: () => {
    return {
      formSent: false,
      form: new Form({
        franchise_id: null,
        business_name: null,
        page_uri: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        agree: null,
        hubspotutk: null
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      franchise: 'landing/franchise',
      modalFileUrl: 'landing/modalFileUrl',
    }),
  },
  mounted() {
    if (this.user && this.modal) {
      this.form.first_name = this.user.first_name;
      this.form.last_name = this.user.last_name;
      this.form.phone = this.user.phone;
      this.form.email = this.user.email;
    }
  },
  beforeDestroy() {
    this.$store.dispatch('landing/setModalFileUrl', '');
  },
  methods: {
    async submit() {

      this.form.page_uri = `${document.location.protocol}//${document.location.host}${this.$route.fullPath}`
      const result = await this.hsLandingForm(
          this.franchise.franchiseId,
          this.franchise.name,
          this.form.first_name,
          this.form.last_name,
          this.form.email,
          this.form.phone,
          this.franchise.industry,
      );
      if (result.ok) {
        await this.gaSendLandingForm();
        this.formSent = true;
        this.form.reset();
        this.$toast.success(result.msg);
      } else {
        this.$toast.error(result.msgShort)
      }
      this.form = result.form
    },
    download(url) {
      window.open(url, '_blank');
    },
  }
}
