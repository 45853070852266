//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from "vuex"

export default {
  name: "MenuListTopFranchiseTile",
  data: () => ({
    classList: {
      long: `menu__item--long`,
      short: `menu__item--short`,
      last_short: `menu__item--last_short`,
    }
  }),
  computed: {
    ...mapGetters({
      topListArr: 'top-franchise/topListArr',
    }),
    isEvenNumberFranchise() {
      return this.topListArr.length % 2 === 0
    },
    getMatrixStyleArr() {
      const arrayLength = this.topListArr.length
      const returnArr = []
      Array(arrayLength)
        .fill()
        .map((_, itemIndex) => {
          if (window.innerWidth <= 1024 && arrayLength % 2 !== 0 && itemIndex >= arrayLength - 1)
            return returnArr.push(this.classList.last_short)

          if (window.innerWidth <= 1024)
            return returnArr.push(this.classList.long)

          if (arrayLength <= 3)
            return returnArr.push(this.classList.short)

          if (arrayLength > 6 && arrayLength % 2 !== 0 && itemIndex >= arrayLength - 3)
            return returnArr.push(this.classList.last_short)

          if (arrayLength === 5 && itemIndex >= 2)
            return returnArr.push(this.classList.last_short)

          if (itemIndex === 0)
            return returnArr.push(this.classList.long)

          if (itemIndex === 1)
            return returnArr.push(this.classList.short)

          const prevValue = returnArr[itemIndex - 1]
          const prevSubValue = returnArr[itemIndex - 2]

          if (prevValue === this.classList.short && prevSubValue === this.classList.long)
            return returnArr.push(this.classList.short)

          if (prevValue === this.classList.short && prevSubValue === this.classList.short)
            return returnArr.push(this.classList.long)

          if (prevValue === this.classList.long && prevSubValue === this.classList.short)
            return returnArr.push(this.classList.long)

          if (prevValue === this.classList.long && prevSubValue === this.classList.long)
            return returnArr.push(this.classList.short)

          return returnArr.push(this.classList.short)
        })
      return returnArr
    },
  },
  mounted() {
    this.fetchTopListArr()
  },
  methods: {
    ...mapActions({
      fetchTopListArr: 'top-franchise/fetchTopListArr',
    }),
    getImageUrl(item, itemIndex) {
      let key = this.getMatrixStyleArr[itemIndex] === this.classList.long ? 'logo_long' : 'logo_short'
      if (window.innerWidth <= 1024)
        key = 'logo_long'
      return item[key]?.url ?? ''
    },
    isEven(itemIndex) {
      return (itemIndex + 1) % 2 === 0
    },
    handlerClickButton(item) {
      const url = `/top-franchises/${item.slug}`
      this.$router.push(url)
    },
    getClass(index) {
      return this.getMatrixStyleArr[index]
    },
  }
}
