import { render, staticRenderFns } from "./LandingSectionGallery.vue?vue&type=template&id=bf728bd4&scoped=true&"
import script from "./LandingSectionGallery.vue?vue&type=script&lang=js&"
export * from "./LandingSectionGallery.vue?vue&type=script&lang=js&"
import style0 from "./LandingSectionGallery.vue?vue&type=style&index=0&id=bf728bd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf728bd4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingText: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingText.vue').default,LandingImage: require('E:/all-projects/franchise-digital-platform-spa/components/landing/elements/LandingImage.vue').default})
