import Form from "vform"
import {
  CLOSED_FORM_TYPES,
} from '@/store/constants'

export default {
  methods: {
    async hsSignUpForm(franchiseId = null) {
      try {
        await this.$axios.post('/api/v1/other/sign-up-form', {
          page_uri: this.getPageUri(),
          hubspotutk: this.$cookies.get('hubspotutk'),
          franchiseId,
        })
      } catch (e) {
        console.log(e)
      }
    },
    async hsSignUpFormFranchisor(step) {
      try {
        await this.$axios.post('/api/v1/other/franchisor-sign-up-form', {
          modalstep: step,
          page_uri: this.getPageUri(),
          hubspotutk: this.$cookies.get('hubspotutk')
        })
      } catch (e) {
        console.log(e)
      }
    },
    async hsBrokerForm(referralBroker) {
      try {
        await this.$axios.post('/api/v1/other/franchisor-broker', {
          referral_broker: referralBroker,
          page_uri: this.getPageUri(),
          hubspotutk: this.$cookies.get('hubspotutk')
        })
      } catch (e) {
        console.log(e)
      }
    },
    async hsLandingForm(franchiseId, businessName, firstName, lastName, email, phone, industry = '') {
      const result = {
        ok: false,
        msg: null,
        msgShort: null,
        errors: null,
        form: null,
      }

      result.form = new Form({
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        franchise_id: franchiseId,
        business_name: businessName,
        franchise_industry: industry,
        page_uri: this.getPageUri(),
        hubspotutk: this.$cookies.get(`hubspotutk`)
      })

      if (franchiseId) {
        try {
          const {data} = await result.form.post(`/api/v1/other/contact-form-landing`)
          result.ok = true
          result.msg = data.message
        } catch (e) {
          result.msg = e.response.data.message
          result.msgShort = e.response.data.message
          result.errors = e.response.data.errors

          if (result.errors) {
            result.msg += `\n\n${this.getErrorsMsg(result.errors)}`
          }
          console.log(e)
        }
      }

      return result
    },
    async hsDownloadContactForm(firstName, lastName, email, phone) {
      const result = {
        ok: false,
        msg: null,
        msgShort: null,
        errors: null,
        form: null,
      }

      result.form = new Form({
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        page_uri: this.getPageUri(),
        page_title: this.getPageTitle(),
        hubspotutk: this.$cookies.get(`hubspotutk`)
      })

      try {
        const {data} = await result.form.post(`/api/v2/other/download-contact-form`)
        result.ok = true
        result.msg = data.message
      } catch (e) {
        result.msg = e.response.data.message
        result.msgShort = e.response.data.message
        result.errors = e.response.data.errors

        if (result.errors) {
          result.msg += `\n\n${this.getErrorsMsg(result.errors)}`
        }
        console.log(e)
      }

      return result
    },
    async hsEbookContactForm(email) {
      const result = {
        ok: false,
        msg: null,
        msgShort: null,
        errors: null,
        form: null,
      }

      result.form = new Form({
        email,
        page_uri: this.getPageUri(),
        page_title: this.getPageTitle(),
        hubspotutk: this.$cookies.get(`hubspotutk`)
      })

      try {
        const {data} = await result.form.post(`/api/v2/other/ebook-contact-form`)
        result.ok = true
        result.msg = data.message
      } catch (e) {
        result.msg = e.response.data.message
        result.msgShort = e.response.data.message
        result.errors = e.response.data.errors

        if (result.errors) {
          result.msg += `\n\n${this.getErrorsMsg(result.errors)}`
        }
        console.log(e)
      }

      return result
    },
    async hsCustomEvent(franchiseId, eventType) {
      try {
        await this.hsUpdateContactFranchise(franchiseId)

        await this.$axios.post('/api/v1/other/custom-event', {
          franchise_id: franchiseId,
          event_type: eventType,
          page_uri: this.getPageUri(),
          page_title: this.getPageTitle(),
          hubspotutk: this.$cookies.get('hubspotutk'),
        })
      } catch (e) {
        console.log(e)
      }
    },
    async hsUpdateContactFranchise(franchiseId) {
      try {
        await this.$axios.post('/api/v2/other/contact-franchise', {
          franchise_id: franchiseId,
        })
      } catch (e) {
        console.log(e)
      }
    },
    async hsClosedForm(email, type = CLOSED_FORM_TYPES.signUpFranchisee, firstName = '', lastName = '', phone) {
      try {
        await this.$axios.post('/api/v2/other/closed-form', {
          type,
          first_name: firstName,
          last_name: lastName,
          email,
          phone
        })
      } catch (e) {
        console.log(e)
      }
    },
    async hsContactFormRadio(form) {
      const result = {
        ok: false,
        msg: null,
        msgShort: null,
        errors: null,
        form: null,
      }

      result.form = form
      try {
        const {data} = await result.form.post(`/api/v2/other/contact-form-radio`)
        result.ok = true
        result.msg = data.message
        result.status = 'success'
      } catch (e) {
        result.msg = e.response.data.message
        result.msgShort = e.response.data.message
        result.errors = e.response.data.errors
        result.status = 'fail'
        console.log(e)
      }

      return result
    },
    async hsContactFormPcmania(form) {
      const result = {
        ok: false,
        msg: null,
        msgShort: null,
        errors: null,
        form: null,
      }

      result.form = form
      result.form.page_uri = this.getPageUri()

      try {
        const {data} = await result.form.post(`/api/v2/other/contact-form-pcmania-landing`)
        result.ok = true
        result.msg = data.message
        result.status = 'success'
      } catch (e) {
        result.msg = e.response.data.message
        result.msgShort = e.response.data.message
        result.errors = e.response.data.errors
        result.status = 'fail'
        console.log(e)
      }

      return result
    },
    getPageUri() {
      return `${document.location.protocol  }//${  document.location.host  }${this.$route.path}`
    },
    getPageTitle() {
      return document.getElementsByTagName("title")[0].innerHTML
    },
    getErrorsMsg(errors) {
      let errorsMsg = JSON.stringify(errors)
      errorsMsg = errorsMsg.replace(/,/g, '\n')
      errorsMsg = errorsMsg.replace(/[^\w\s!?:]/g,'')
      return errorsMsg
    }
  },
}
