//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import ThreeDotMenu from "@/components/menu/ThreeDotMenu"

import {
  DOCUMENT_SUBTYPE_DEFAULT,
  DOCUMENT_TYPE_DEFAULT,
  DOCUMENT_TYPE_PRESENTATION,
} from '~/store/constants'

export default {
  components: {
    ThreeDotMenu,
  },
  props: {
    documentList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedDocumentIdx: -1,
      selectedDocument: null,
      showMenu: false,
      DOCUMENT_TYPE_DEFAULT,
      DOCUMENT_TYPE_PRESENTATION,
      DOCUMENT_SUBTYPE_DEFAULT,
      menuListItems: [
        {
          title: 'rename',
          action: 'RENAME',
          active: false,
          checked: false,
        },
        {
          title: 'delete',
          action: 'DELETE',
          active: false,
          checked: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      subtypes: 'documents/subtypes',
      user: 'auth/user',
      paymentPermissions: 'payment/permissions',
    }),
  },
  async mounted() {
    await this.$store.dispatch('documents/fetchSubtypes')
    this.prepareMenuItems();
  },
  methods: {
    /**
     * Generate menu items
     */
    prepareMenuItems() {
      const subtypeItems = this.subtypes.map((value) => {
        return {
          title: value,
          action: 'SET-SUBTYPE',
          active: false,
          checked: false,
        };
      });
      this.menuListItems = [...subtypeItems, ...this.menuListItems]
    },
    /**
     * Set checked icon
     */
    handlerFocusOut(index){
      const menuItemSaveIndex = this.menuListItems.findIndex(item => item.action === 'SAVE')
      this.toggleFocusDocumentName(index)

      if(!menuItemSaveIndex)
        return

      const menuItem = this.menuListItems[menuItemSaveIndex]

      menuItem.title = 'rename'
      menuItem.action = 'RENAME'
      menuItem.active = false
    },
    setCheckedSubtype() {
      this.menuListItems.map((value) => {
        if (value.title === this.selectedDocument.doc_subtype) {
          return Object.assign(value, {checked: true})
        }
        return Object.assign(value, {checked: false})
      });
    },
    /**
     * Three dot button click handler
     * @param {number} index document index
     * @param {Object} document entity
     */
    handleClickShowMenu(index, document) {

      if (this.user.isFranchisor && !this.paymentPermissions.addDocumentToStep) {
        this.fireModalMessage({message: this.paymentPermissions.addDocumentToStep.message})
        return
      }
      this.showMenu = true
      this.selectedDocumentIdx = index
      this.selectedDocument = document
      this.setCheckedSubtype()
    },
    /**
     * Click menu item handler
     * @param {number} itemIdx index of menu item
     * @param {number} documentIndex
     */
    handleClickMenuItem(itemIdx, documentIndex) {
      this.callToAction(itemIdx, documentIndex)
    },
    /**
     * Focus document item name
     * @param {number} documentIndex
     * @param {boolean} toggleState
     */
    toggleFocusDocumentName(documentIndex, toggleState) {
      const documentNameEl = this.$refs['document-names'][documentIndex];
      if (toggleState) {
        documentNameEl.style.whiteSpace = 'normal';
        documentNameEl.setAttribute('contenteditable', true)
        documentNameEl.focus();
      } else {
        documentNameEl.style.whiteSpace = 'nowrap';
        documentNameEl.setAttribute('contenteditable', false)
        documentNameEl.blur()
      }
    },
    /**
     * Submit to endpoint new name
     * @param {number} documentIndex
     */
    async submitRenameDocument(documentIndex) {
      this.toggleFocusDocumentName(documentIndex, false)

      const documentNameEl = this.$refs['document-names'][documentIndex]
      const newName = documentNameEl.innerText;
      try {
        await this.$axios.post(
            `api/v1/business-step-docs/rename/${this.selectedDocument.step_doc_id}`,
            {
              name: newName,
            }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async callToAction(itemIdx, documentIndex) {

      const clickedMenuItem = this.menuListItems[itemIdx];

      if (clickedMenuItem.action === 'RENAME') {
        this.toggleFocusDocumentName(documentIndex, true)
        clickedMenuItem.active = true

        // set title and action as save after focus
        clickedMenuItem.title = 'save'
        clickedMenuItem.action = 'SAVE'
        this.showMenu = false
        return;
      }
      if (clickedMenuItem.action === 'SAVE') {
        await this.submitRenameDocument(documentIndex)
        clickedMenuItem.title = 'rename'
        clickedMenuItem.action = 'RENAME'
        clickedMenuItem.active = false
        return;
      }
      if (clickedMenuItem.action === 'DELETE') {
        await this.deleteDocument()
        this.showMenu = false;
        return;
      }
      if (clickedMenuItem.action === 'SET-SUBTYPE') {
        await this.setStepFileType(clickedMenuItem.title)
        this.setCheckedSubtype()
      }
    },
    /**
     * Download document by stepDocId
     * @param {Object} document
     */
    handleClickDocument(document, index) {
      const documentNameEl = this.$refs['document-names'][index]

      if (documentNameEl.getAttribute('contenteditable') === 'true')
        return

      if (this.selectedDocumentIdx !== -1) {
        this.selectedDocumentIdx = -1;
        return;
      }
      let redirectLink = '';
      if (document.link) {
        redirectLink = document.link;
      } else {
        redirectLink = `${process.env.BACKEND_BASE_URL}api/v1/business-step-docs/download/${document.step_doc_id}`
      }
      window.open(redirectLink)
    },
    /**
     * Delete step document
     */
    async deleteDocument() {
      try {
        await this.$axios.delete(`api/v1/business-step-docs/${this.selectedDocument.step_doc_id}`)
        this.documentList.splice(this.selectedDocumentIdx, 1)
      } catch (err) {
        console.log(err)
      }
    },
    /**
     * Set file type for step like CHECKBOX
     */
    async setStepFileType(subtype) {
      const stepDocId = this.selectedDocument.step_doc_id;
      try {
        await this.$axios.post(`api/v1/business-step-docs/set-as-subtype/${subtype}/${stepDocId}`)
        this.documentList.forEach((value, index) => {
          this.documentList[index].doc_subtype = this.DOCUMENT_SUBTYPE_DEFAULT
        });
        this.selectedDocument.doc_subtype = subtype
      } catch (err) {
        console.log(err)
      }
    },
  },
};
