//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapState} from 'vuex'
import BaseModal from '../BaseModal'
import BodyStageForm from './BodyStageForm'
import BodyStageUserType from './BodyStageUserType'
import BodyStagePreview from './BodyStagePreview'
import BodyStageCongratulations from './BodyStageCongratulations'
import BodyStageStepper from './BodyStageStepper'
import BodyStageStepperCongratulations from './BodyStageStepperCongratulations'
import {
  REGISTRATION_STAGE_CONGRATULATIONS,
  REGISTRATION_STAGE_FORM,
  REGISTRATION_STAGE_FRANCHISEE_PREVIEW,
  REGISTRATION_STAGE_FRANCHISOR_PREVIEW,
  REGISTRATION_STAGE_PREFERENCES,
  REGISTRATION_STAGE_SELECT_USER_TYPE,
  REGISTRATION_STAGE_STEPPER,
  REGISTRATION_STAGE_STEPPER_CONGRATULATIONS,
  USER_TYPE_FRANCHISEE,
  USER_TYPE_FRANCHISOR
} from '~/store/constants'
import hubspotMixin from "../../mixins/hubspotMixin";
import LogoWithTradeMark from "~/components/LogoWithTradeMark";

export default {
  components: {
    LogoWithTradeMark,
    BodyStageForm,
    BodyStageUserType,
    BodyStagePreview,
    BodyStageCongratulations,
    BodyStageStepper,
    BodyStageStepperCongratulations,
    BaseModal
  },
  mixins: [
    hubspotMixin
  ],
  data: () => ({
    currentBody: '',
    currentBodyProps: {},
    stages: {
      franchiseePreview: REGISTRATION_STAGE_FRANCHISEE_PREVIEW,
      franchisorPreview: REGISTRATION_STAGE_FRANCHISOR_PREVIEW,
      user: REGISTRATION_STAGE_SELECT_USER_TYPE,
      form: REGISTRATION_STAGE_FORM,
      preferences: REGISTRATION_STAGE_PREFERENCES,
      congratulations: REGISTRATION_STAGE_CONGRATULATIONS,
      stepper: REGISTRATION_STAGE_STEPPER,
      stepperCongratulations: REGISTRATION_STAGE_STEPPER_CONGRATULATIONS
    },
    userTypes: {
      franchisee: USER_TYPE_FRANCHISEE,
      franchisor: USER_TYPE_FRANCHISOR
    },
    bodyClass: 'py-0',
    dialogClass: '',
    modalClass: 'modal-wrapper',
    contentClass: 'py-40px',
    title: 'Sign Up for free now!',
    hideHeader: false,
    size: 'lg',
    headerClass: 'border-bottom-0',
    customModalTitle: 'custom-modal-title',
    industries: [],
    states: [],
    totalAmount: null,
    countFranchises: 0,
    countFranchisesByLocation: [],
    isParams: false,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      user_franchise: 'auth/user_franchise',
      authenticated: 'auth/check',
      utmCampaign: 'hubspot/utmCampaign',
      utmTerm: 'hubspot/utmTerm',
      selectedFranchise: 'account/selectedFranchise',
    }),
    ...mapState({
      stage: state => state.registration.stage,
      show: state => state.registration.show,
      userType: state => state.registration.userType,
    })
  },
  watch: {
    stage(value) {
      const params = this.$route.query;
      switch (value) {
        case this.stages.user:
          this.size = 'lg';
          this.modalClass = 'modal-wrapper body-stage-user-type-wrapper';
          this.bodyClass = 'p-0';
          this.headerClass = 'border-bottom-0 form-modal-header';
          this.hideHeader = false;
          this.contentClass = 'form-content modal-sign-up-content-user-type';
          this.dialogClass = 'modal-sign-up-dialog-user-type';
          this.customModalTitle = 'form-modal-title';
          this.title = 'Sign Up for';
          break;
        case this.stages.preferences:
          this.size = 'lg'
          this.bodyClass = 'py-0'
          this.headerClass = ''
          this.hideHeader = true
          this.contentClass = 'py-40px'
          this.dialogClass = ''
          this.customModalTitle = 'preferences-modal-title'
          this.title = ''
          break
        case this.stages.congratulations:
          this.size = 'xl'
          this.bodyClass = 'py-0'
          this.headerClass = 'border-bottom-0'
          this.hideHeader = true
          this.contentClass = 'congratulations-body p-50px'
          this.dialogClass = ''
          this.customModalTitle = 'cong-modal-title'
          this.title = ''
          break
        case this.stages.stepper:
          this.size = 'xl'
          this.bodyClass = 'p-0'
          this.headerClass = 'border-bottom-0'
          this.hideHeader = true
          this.contentClass = 'stepper-body'
          this.dialogClass = 'modal-dialog-fullscreen'
          this.customModalTitle = 'cong-modal-title'
          this.title = ''
          break
        case this.stages.stepperCongratulations:
          this.size = 'md'
          this.bodyClass = 'p-0'
          this.headerClass = 'border-bottom-0'
          this.hideHeader = true
          this.contentClass = 'stepper-congratulations-body'
          this.dialogClass = 'stepper-congratulations-dialog'
          this.customModalTitle = 'cong-modal-title'
          this.title = ''
          break
        case this.stages.franchiseePreview:
          this.size = 'lg'
          this.bodyClass = 'py-0'
          this.headerClass = 'border-bottom-0'
          this.hideHeader = false
          this.contentClass = 'py-40px'
          this.dialogClass = ''
          this.customModalTitle = 'preview-modal-title'
          this.title = 'Sign Up for free now!'
          break
        case this.stages.form :
          this.size = 'lg'
          this.bodyClass = 'p-0'
          this.headerClass = 'border-bottom-0 form-modal-header'
          this.hideHeader = false
          this.contentClass = 'form-content '
          this.dialogClass = ''
          this.customModalTitle = 'form-modal-title'
          if (params.utm_campaign === 'Broker Links') {
            this.title = 'Own Your Own Business'
            this.isParams = true
          } else {
            this.title = 'Sign Up for'
          }
          break
        default:
          this.size = 'lg'
          this.bodyClass = 'p-0'
          this.headerClass = 'border-bottom-0 form-modal-header'
          this.hideHeader = false
          this.contentClass = 'form-content modal-sign-up-content-user-type'
          this.dialogClass = 'modal-sign-up-dialog-user-type'
          this.customModalTitle = 'form-modal-title'
          this.title = 'Sign Up for'
      }

      this.updateCurrentBody(value, this.userType)
    },

    userType(value) {
      this.updateCurrentBody(this.stage, value)
    },
    show(value) {
      if (value) {
        window.addEventListener('beforeunload', this.sendHubSpotEvents)
      } else {
        window.removeEventListener('beforeunload', this.sendHubSpotEvents)
      }
    }
  },
  mounted() {
    this.$store.dispatch('registration/setStage', this.stages.stepper)
    this.$store.dispatch('registration/setUserType', this.userTypes.franchisor)
    this.updateCurrentBody()

    if (this.$route.query.utm_campaign && this.$route.query.utm_term) {
      this.$store.dispatch('hubspot/setHubspotFormField', {
        utmCampaign: this.$route.query.utm_campaign,
        utmTerm: this.$route.query.utm_term,
      });
    }
  },
  methods: {
    closeModal() {
      this.sendHubSpotEvents()
      this.$store.dispatch('registration/closeModal')
    },
    async showCongratulations(states, industries, totalAmount, countFranchises, countFranchisesByLocation) {
      this.states = states
      this.industries = industries
      this.totalAmount = totalAmount
      this.countFranchises = countFranchises
      this.countFranchisesByLocation = countFranchisesByLocation

      await this.$store.dispatch('registration/setStage', this.stages.congratulations)
    },
    updateCurrentBody(stage, userType) {
      if (stage === this.stages.franchiseePreview) {
        this.currentBody = 'body-stage-preview'
        this.currentBodyProps = {nextStage: userType === null ? this.stages.user : this.stages.form}
      } else if (stage === this.stages.user) {
        this.currentBody = 'body-stage-user-type'
        this.currentBodyProps = {nextStage: this.stages.form}
      } else if (stage === this.stages.form) {
        this.currentBody = 'body-stage-form'
        this.currentBodyProps = {
          nextStage: userType === this.userTypes.franchisee ? this.stages.preferences : this.stages.stepper,
          userType
        }
      } else if (userType === this.userTypes.franchisee) {
        if (stage === this.stages.preferences) {
          this.currentBody = 'body-stage-preferences'
        } else if (stage === this.stages.congratulations) {
          this.currentBody = 'body-stage-congratulations'
          this.currentBodyProps = {
            states: this.states,
            industries: this.industries,
            totalAmount: this.totalAmount,
            countFranchises: this.countFranchises,
            countFranchisesByLocation: this.countFranchisesByLocation
          }
        }
      } else if (userType === this.userTypes.franchisor) {
        if (stage === this.stages.stepper) {
          this.currentBody = 'body-stage-stepper'
        } else if (stage === this.stages.stepperCongratulations) {
          this.currentBody = 'body-stage-stepper-congratulations'
        }
      }
    },
    sendHubSpotEvents(e) {
      if (this.authenticated && this.user.isFranchisee) {
        this.hsSignUpForm(this.selectedFranchise?.id)

        if (this.utmCampaign === 'Franchise123' && this.utmTerm !== '') {
          this.hsBrokerForm(this.utmTerm)
        }
      }
    }
  }
}
