//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MenuListTopFranchise',
  computed: {
    ...mapGetters({
      topListArr: 'top-franchise/topListArr',
    }),
  },
  mounted() {
    this.fetchTopListArr();
  },
  methods: {
    ...mapActions({
      fetchTopListArr: 'top-franchise/fetchTopListArr',
    }),
    getLink(item) {
      return `/top-franchises/${item.slug}`;
    },
  },
};
