//
//
//
//


export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    }
  },
  computed: {
    imgSrc () {
      return require(`~/assets/images/associations-and-partnerships/${this.slug}.svg`)
    }
  }
}
