//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalStepDocumentsList from '@/components/modals/ModalStepDocumentsList';
import BurgerV2Icon from '@/assets/images/svg/burger-v2.svg?inline';
import ArrowRight from '@/assets/images/svg/arrow-right.svg?inline';
import CheckIcon from '@/assets/images/svg/check.svg?inline';

export default {
  name:'FranchiseeMatchesCard',
  components: {
    ModalStepDocumentsList,
    BurgerV2Icon,
    ArrowRight,
    CheckIcon,
  },
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    slug: {
      type: String,
      required: true,
    },
    franchiseId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showDocumentsModal: false,
    };
  },
  computed: {
    stepLink() {
      return `/franchisee-steps?slug=${this.slug}`;
    },
  },
  methods: {
    openDocumentsModal() {
      this.showDocumentsModal = true;
    },
    closeDocumentsModal() {
      this.showDocumentsModal = false;
    },
    goToSteps() {
      if (this.step.status === 'in_progress') {
        this.$router.push(this.stepLink);
      }
      return false;
    },
  },
};
