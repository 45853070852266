//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Form from 'vform';
import ClickOutside from 'vue-click-outside';
import Notifications from '@/components/nav/Notifications';
import NavLinks from '@/components/nav/NavLinks';
import Avatar from '@/components/nav/Avatar';
import AvatarMenu from '@/components/nav/AvatarMenu';
import BaseButton from '@/components/buttons/BaseButton';
import BurgerButton from '@/components/buttons/BurgerButton';
import CloseIcon from '@/components/icons/CloseIcon';
import WeUseCookie from '@/components/modals/General/WeUseCookie';
import ModalSignUp from '@/components/modals/ModalSignUp/ModalSignUp';
import ModalSignIn from '@/components/modals/ModalSignIn/ModalSignIn';
import ModalIframe from '@/components/modals/ModalIframe';
import ModalExpiredMembership from '@/components/modals/ModalExpiredMembership';
import ModalSearchFranchises from '@/components/modals/ModalSearchFranchises';
import StringMask from 'string-mask';
import { COMPANY_PHONE_NUMBER, MASK_PHONE } from '@/store/constants';
import SearchIcon from '~/components/nav-search/nav-search.svg?inline';
import SearchFranchises from '~/components/SearchFranchises';
import ModalMessage from '~/components/modals/ModalMessage';

export default {
  components: {
    ModalSearchFranchises,
    ModalExpiredMembership,
    ModalIframe,
    ModalSignIn,
    ModalSignUp,
    WeUseCookie,
    CloseIcon,
    BurgerButton,
    BaseButton,
    AvatarMenu,
    Avatar,
    NavLinks,
    Notifications,
    ModalMessage,
    SearchFranchises,
    SearchIcon,
  },
  directives: {
    ClickOutside,
  },
  data: () => ({
    form: new Form({
      first_name: '',
      email: '',
    }),
    showSearch: false,
    navMenuState: false,
    avatarMenuState: false,
    showExpiredMembership: false,
    showModal: false,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      paymentUser: 'payment/user',
      overlap: 'nav/overlap',
      closeSearch: 'business/search_modal',
    }),
    getUrlPhone() {
      return `tel:${COMPANY_PHONE_NUMBER}`;
    },
    getFormatedPhone() {
      return new StringMask(MASK_PHONE).apply(COMPANY_PHONE_NUMBER);
    },
  },
  watch: {
    navMenuState(newVal) {
      this.scrollBody(newVal, this.showModal);
    },
    showModal(newVal) {
      this.scrollBody(newVal, this.navMenuState);
    },
    // watch rout changes
    $route() {
      this.checkShowSearch();
      this.avatarMenuState = false;
      this.navMenuState = false;
    },
    user() {
      this.checkShowSearch();
    },
    closeSearch() {
      this.showModal = !this.closeSearch;
    },
  },
  async created() {
    if (this.user) {
      this.form.keys().forEach((key) => {
        this.form[key] = this.user[key];
      });
    }
    this.checkMembershipExpiredModal();
  },
  async updated() {
    if (this.user) {
      await this.checkMembershipExpired();
    }
  },
  mounted() {
    this.checkShowSearch();
  },
  methods: {
    scrollBody(newVal, modal) {
      if (newVal && !modal) {
        document.body.style.overflowY = 'hidden';
        document.body.style.position = 'fixed';
      } else if (!newVal && modal) {
        document.body.style.overflowY = 'hidden';
        document.body.style.position = 'fixed';
      } else {
        document.body.style.overflowY = 'scroll';
        document.body.style.position = 'initial';
      }
    },
    showFilter() {
      this.showModal = !this.showModal;
      this.$store.dispatch('nav/setNavOverlap', true);
      if (this.showModal) {
        this.$store.commit('business/SET_CLOSE_FRANCHISE_NAV_MODAL', false);
      }
    },
    hideFilter() {
      this.showModal = false;
      this.$store.dispatch('nav/setNavOverlap', false);
    },
    toggleMobMenu() {
      this.showModal = false;
      this.navMenuState = !this.navMenuState;
    },
    checkShowSearch() {
      const route = this.$route.path;
      const exceptionRoutes = [
        '/',
        '/auth*',
        '/email*',
        '/franchise-directory',
        '/account-franchisor*',
        '/membership',
        '/budget',
        '/DM',
        '/see-franchises',
        '/own-franchise-steps',
        '/franchisee-register',
        '/radio',
        '/pcmania',
      ];

      this.showSearch = true;
      const length = exceptionRoutes.length;
      for (let i = 0; i < length; i++) {
        if (
          exceptionRoutes[i] === route ||
          (exceptionRoutes[i].endsWith('*') && route.startsWith(exceptionRoutes[i].slice(0, -1)))
        ) {
          this.showSearch = false;
        }
      }
    },
    async logout() {
      this.avatarMenuState = false;
      this.navMenuState = false;

      window.location.replace('/auth/logout');
    },
    async checkMembershipExpired() {
      if (!this.user.isFranchisor) {
        return;
      }

      if (!this.paymentUser) {
        await this.$store.dispatch('payment/fetchPayment', this.user.id);
      }

      if (this.paymentUser.expired && !this.paymentUser.signed_contract) {
        await this.$store.dispatch('auth/logout');
        this.avatarMenuState = false;
        this.navMenuState = false;
        window.location.href = '/?membershipExpired=yes';
      }
    },
    checkMembershipExpiredModal() {
      if (this.$route.query.membershipExpired === 'yes') {
        this.showExpiredMembership = true;
      }
    },
    closeExpiredMembership() {
      this.showExpiredMembership = false;
      this.$router.push(this.$route.path);
    },
    showRegisterModal() {
      this.$store.dispatch('registration/openModal');
      this.$store.dispatch('nav/setNavOverlap', false);
    },
    showLoginModal() {
      this.$store.dispatch('login/openModal');
      this.$store.dispatch('nav/setNavOverlap', false);
    },
    clickOutsideAvatar() {
      this.avatarMenuState = false;
    },
    navOverlayClick(e) {
      if (e.target.classList.contains('nav__mobile')) {
        this.$emit('close');
      }
    },
  },
};
